import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import Joi from 'joi';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import usePartForm from '@components/hooks/usePartForm';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const UtstyrTak = ({ defaultValues, doOnSave }: BasePartComponentProps<PartUtstyrTakFormData>) => {
  const [formMethods] = usePartForm<PartUtstyrTakFormData>(
    UtstyrTakSchema,
    defaultValues,
    PartTypes.UtstyrTak.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const kravTilFeierStige = watch('kravTilFeierStige.value');
  useEffect(() => {
    if (!kravTilFeierStige) {
      setValue('manglendeAdkomstFeier.value', null, { shouldValidate: true });
    }
  }, [kravTilFeierStige, setValue]);

  const kravTilSnofanger = watch('kravTilSnofanger.value');
  useEffect(() => {
    if (!kravTilSnofanger) {
      setValue('manglerSnofanger.value', null, { shouldValidate: true });
    }
  }, [kravTilSnofanger, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.UtstyrTak.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckBoolean
          id="kravTilSnofanger"
          header={getCheckHeader(partVersion, PartTypes.UtstyrTak.id, 'kravTilSnofanger')}
        />

        {kravTilSnofanger && (
          <CheckText
            id="manglerSnofanger"
            header={getCheckHeader(partVersion, PartTypes.UtstyrTak.id, 'manglerSnofanger')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <CheckBoolean
          id="kravTilFeierStige"
          header={getCheckHeader(partVersion, PartTypes.UtstyrTak.id, 'kravTilFeierStige')}
        />

        {kravTilFeierStige && (
          <CheckText
            id="manglendeAdkomstFeier"
            header={getCheckHeader(partVersion, PartTypes.UtstyrTak.id, 'manglendeAdkomstFeier')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <KonklusjonTiltakKostnader
          partType="UtstyrTak"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.UtstyrTak.name}
      />
    </FormProvider>
  );
};

export const UtstyrTakSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  kravTilSnofanger: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  manglerSnofanger: Joi.object({
    value: Joi.when('/kravTilSnofanger.value', { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  kravTilFeierStige: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeAdkomstFeier: Joi.object({
    value: Joi.when('/kravTilFeierStige.value', { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartUtstyrTakFormData extends BasePartForm {
  kravTilSnofanger: CheckSingle<boolean>;
  manglerSnofanger: CheckSingle<string>;
  kravTilFeierStige: CheckSingle<boolean>;
  manglendeAdkomstFeier: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default UtstyrTak;
