import React from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const RennerNedlopForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartRennerNedlopFormData>) => {
  const [formMethods] = usePartForm<PartRennerNedlopFormData>(
    RennerNedlopSchema,
    defaultValues,
    PartTypes.RennerNedlop.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.RennerNedlop.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="_type"
          header={getCheckHeader(partVersion, PartTypes.RennerNedlop.id, '_type')}
          alternatives={[
            { value: 'metall', label: 'Metall' },
            { value: 'plast', label: 'Plast' },
            { value: 'aluminium', label: 'Aluminium' },
            { value: 'kobber', label: 'Kobber' },
            { value: 'tre', label: 'Tre' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.RennerNedlop.id, '_type'),
            id: '_type.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(partVersion, PartTypes.RennerNedlop.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.RennerNedlop.id, 'arbeidEtterByggeaar'),
            id: 'arbeidEtterByggeaar.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="avvik"
          header={getCheckHeader(partVersion, PartTypes.RennerNedlop.id, 'avvik')}
        />

        <KonklusjonTiltakKostnader
          partType="RennerNedlop"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.RennerNedlop.name}
      />
    </FormProvider>
  );
};

export const RennerNedlopSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  _type: Joi.object({
    values: Joi.array().min(1).required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  avvik: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartRennerNedlopFormData extends BasePartForm {
  _type: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  avvik: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default RennerNedlopForm;
