import React from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const VinduerDorerForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVinduerDorerFormData>) => {
  const [formMethods] = usePartForm<PartVinduerDorerFormData>(
    VinduerDorerSchema,
    defaultValues,
    PartTypes.VinduerDorer.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.VinduerDorer.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <SingleTextarea
          id="beskrivelse"
          header={getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'beskrivelse')}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'beskrivelse'),
            id: 'beskrivelse.value',
            tg: 'totalvurdering.tg',
          }}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'arbeidEtterByggeaar'),
            id: 'arbeidEtterByggeaar.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="punkterteGlass"
          header={getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'punkterteGlass')}
        />

        <CheckBoolean
          id="fuktskader"
          header={getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'fuktskader')}
        />

        <CheckBoolean
          id="avvikLukkemekanisme"
          header={getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'avvikLukkemekanisme')}
        />

        <CheckBoolean
          id="avvikMontering"
          header={getCheckHeader(partVersion, PartTypes.VinduerDorer.id, 'avvikMontering')}
        />

        <KonklusjonTiltakKostnader
          partType="VinduerDorer"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.VinduerDorer.name}
      />
    </FormProvider>
  );
};

export const VinduerDorerSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  beskrivelse: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  punkterteGlass: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktskader: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikLukkemekanisme: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikMontering: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVinduerDorerFormData extends BasePartForm {
  beskrivelse: CheckSingle<string>;
  punkterteGlass: CheckSingle<boolean>;
  fuktskader: CheckSingle<boolean>;
  avvikLukkemekanisme: CheckSingle<boolean>;
  avvikMontering: CheckSingle<boolean>;
  arbeidEtterByggeaar: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VinduerDorerForm;
