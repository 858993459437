import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import SingleInput from '@components/common/SingleInput';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import CheckSelect from '@components/common/CheckSelect';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const VarmtvannsberederForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVarmtvannsberederFormData>) => {
  const [formMethods] = usePartForm<PartVarmtvannsberederFormData>(
    VarmtvannsberederSchema,
    defaultValues,
    PartTypes.Varmtvannsbereder.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  const over20Ar = watch('over20Ar.value');
  useEffect(() => {
    if (
      over20Ar === 'ja|Ja' &&
      (totalvurderingTg === null || totalvurderingTg === 1 || totalvurderingTg === 0)
    ) {
      setValue('totalvurdering.tg', 2, { shouldValidate: true });
      trigger('totalvurdering.tg');
    }
  }, [over20Ar, totalvurderingTg, setValue]);

  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Varmtvannsbereder.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckSelect
          id="plassering"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'plassering')}
          alternatives={[
            { value: 'vaskerom', label: 'Vaskerom' },
            { value: 'bad', label: 'Bad' },
            { value: 'teknisk_rom', label: 'Teknisk rom' },
            { value: 'bod', label: 'Bod' },
            { value: 'kjokkenbenk', label: 'Kjøkkenbenk' },
            { value: 'loft', label: 'Loft' },
          ]}
          allowFreetext
        />

        <CheckSelect
          id="fundament"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'fundament')}
          alternatives={[
            { value: 'plassert_paa_gulv', label: 'Plassert på gulv' },
            { value: 'vegghengt', label: 'Vegghengt' },
            { value: 'kjokkenskap_uten_understottelse', label: 'Kjøkkenskap uten understøttelse' },
            { value: 'kjokkenskap_med_understottelse', label: 'Kjøkkenskap med understøttelse' },
            {
              value: 'kjokkenskap_med_ukjent_understottelse',
              label: 'Kjøkkenskap med ukjent understøttelse',
            },
          ]}
          allowFreetext
        />

        <SingleInput
          type="text"
          id="arstall"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'arstall')}
        />

        <CheckSelect
          id="storrelse"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'storrelse')}
          alternatives={[
            { value: '10_liter', label: '10 liter' },
            { value: '20_liter', label: '20 liter' },
            { value: '30_liter', label: '30 liter' },
            { value: '40_liter', label: '40 liter' },
            { value: '50_liter', label: '50 liter' },
            { value: '80_liter', label: '80 liter' },
            { value: '100_liter', label: '100 liter' },
            { value: '120_liter', label: '120 liter' },
            { value: '150_liter', label: '150 liter' },
            { value: '198_liter', label: '198 liter' },
            { value: '200_liter', label: '200 liter' },
            { value: '300_liter', label: '300 liter' },
          ]}
          allowFreetext
        />

        <CheckText
          id="lekkasjesikret"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'lekkasjesikret')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollertbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="pluggBrunsvidd"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'pluggBrunsvidd')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_relevant_fast_tilkobling', label: 'Ikke relevant, fast tilkobling' },
            { value: 'ikke_kontrollertbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="lekkasje"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'lekkasje')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollertbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="over20Ar"
          header={getCheckHeader(partVersion, PartTypes.Varmtvannsbereder.id, 'over20Ar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <KonklusjonTiltakKostnader
          partType="Varmtvannsbereder"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Varmtvannsbereder.name}
      />
    </FormProvider>
  );
};

export const VarmtvannsberederSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  plassering: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  fundament: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  arstall: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  storrelse: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjesikret: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  pluggBrunsvidd: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasje: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  over20Ar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVarmtvannsberederFormData extends BasePartForm {
  plassering: CheckSingle<string>;
  fundament: CheckSingle<string>;
  arstall: CheckSingle<string>;
  storrelse: CheckSingle<string>;
  lekkasjesikret: CheckSingle<string>;
  pluggBrunsvidd: CheckSingle<string>;
  lekkasje: CheckSingle<string>;
  over20Ar: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VarmtvannsberederForm;
