import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const TaktekkingForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartTaktekkingFormData>) => {
  const [formMethods] = usePartForm<PartTaktekkingFormData>(
    TaktekkingSchema,
    defaultValues,
    PartTypes.Taktekking.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  const alder = watch('alder.value');
  useEffect(() => {
    if (alder === 'ja|Ja' && (totalvurderingTg === undefined || totalvurderingTg === null)) {
      setValue('totalvurdering.tg', 2, { shouldValidate: true });
    }
  }, [alder, totalvurderingTg, setValue]);

  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Taktekking.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="typeTekking"
          header={getCheckHeader(partVersion, PartTypes.Taktekking.id, 'typeTekking')}
          alternatives={[
            { value: 'takstein', label: 'Takstein' },
            { value: 'lakkerte_staalplater', label: 'Lakkerte stålplater' },
            { value: 'papp', label: 'Papp' },
            { value: 'pappshingel', label: 'Pappshingel' },
            { value: 'betongstein', label: 'Betongstein' },
            { value: 'eternittplater', label: 'Eternittplater' },
            { value: 'skiferstein', label: 'Skiferstein' },
            { value: 'imiterte_staalplater', label: 'Metallplater' },
            { value: 'tegltakstein', label: 'Tegltakstein' },
            { value: 'bandtekking', label: 'Båndtekking' },
            { value: 'tretak', label: 'Tretak' },
            { value: 'torv', label: 'Torv' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Taktekking.id, 'typeTekking'),
            id: 'typeTekking.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckMultiText
          id="inspisertFra"
          header={getCheckHeader(partVersion, PartTypes.Taktekking.id, 'inspisertFra')}
          alternatives={[
            { value: 'stige', label: 'Via stige' },
            { value: 'tak', label: 'På tak' },
            { value: 'fra_bakken', label: 'Fra bakken' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(partVersion, PartTypes.Taktekking.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Taktekking.id, 'arbeidEtterByggeaar'),
            id: 'arbeidEtterByggeaar.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="skaderTakstein"
          header={getCheckHeader(partVersion, PartTypes.Taktekking.id, 'skaderTakstein')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckText
          id="avvikBeslagPipe"
          header={getCheckHeader(partVersion, PartTypes.Taktekking.id, 'avvikBeslagPipe')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckText
          id="alder"
          header={getCheckHeader(partVersion, PartTypes.Taktekking.id, 'alder')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <KonklusjonTiltakKostnader
          partType="Taktekking"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Taktekking.name}
      />
    </FormProvider>
  );
};

export const TaktekkingSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeTekking: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  inspisertFra: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  skaderTakstein: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikBeslagPipe: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartTaktekkingFormData extends BasePartForm {
  typeTekking: MultiCheck<string>;
  inspisertFra: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  skaderTakstein: CheckSingle<string>;
  avvikBeslagPipe: CheckSingle<string>;
  alder: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default TaktekkingForm;
