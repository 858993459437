import React from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const AvlopsrorForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartAvlopsrorFormData>) => {
  const [formMethods] = usePartForm<PartAvlopsrorFormData>(
    AvlopsrorSchema,
    defaultValues,
    PartTypes.Avlopsror.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5 pb-28"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Avlopsror.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="typeAnlegg"
          header={getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'typeAnlegg')}
          alternatives={[
            { value: 'plast', label: 'Plast' },
            { value: 'stopejern', label: 'Støpejern' },
            { value: 'keramikk', label: 'Keramikk' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'typeAnlegg'),
            id: 'typeAnlegg.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'arbeidEtterByggeaar'),
            id: 'arbeidEtterByggeaar.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="luftetOverTak"
          header={getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'luftetOverTak')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="avrenning"
          header={getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'avrenning')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="stakemulighet"
          header={getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'stakemulighet')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="alder"
          header={getCheckHeader(partVersion, PartTypes.Avlopsror.id, 'alder')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <KonklusjonTiltakKostnader
          partType="Avlopsror"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Avlopsror.name}
      />
    </FormProvider>
  );
};

export const AvlopsrorSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    values: Joi.array().min(1).required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  luftetOverTak: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  avrenning: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  stakemulighet: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.string().required(),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartAvlopsrorFormData extends BasePartForm {
  typeAnlegg: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  luftetOverTak: CheckSingle<string>;
  avrenning: CheckSingle<string>;
  stakemulighet: CheckSingle<string>;
  alder: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default AvlopsrorForm;
