import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import CheckMultiText from '@components/common/CheckMultiText/v1';

const DreneringForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartDreneringFormData>) => {
  const [formMethods] = usePartForm<PartDreneringFormData>(
    DreneringSchema,
    defaultValues,
    PartTypes.Drenering.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeGrunnmur = watch('typeGrunnmur.values');
  useEffect(() => {
    if (!typeGrunnmur || !typeGrunnmur?.includes('grunnmur_ringmur|Grunnmur/ringmur')) {
      setValue('arbeidEtterByggeaar.value', null);
      setValue('arbeidEtterByggeaar.comment', '');
      setValue('oppgradert.value', null);
      setValue('oppgradert.comment', '');
      setValue('synligGrunnmurplastOgTopplist.value', null);
      setValue('synligGrunnmurplastOgTopplist.comment', '');
      setValue('alder.value', null);
      setValue('alder.comment', '');
      setValue('funksjonsvikt.value', null);
      setValue('funksjonsvikt.comment', '');
    }
  }, [typeGrunnmur, setValue]);

  const arbeidEtterByggeaar = watch('arbeidEtterByggeaar.value');
  useEffect(() => {
    if (arbeidEtterByggeaar !== 'ja|Ja') {
      setValue('oppgradert.value', null);
      setValue('oppgradert.comment', '');
    }
  }, [arbeidEtterByggeaar, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  const synligGrunnmurplastOgTopplist = watch('synligGrunnmurplastOgTopplist.value');
  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      synligGrunnmurplastOgTopplist === 'ja|Ja' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', 2);
      trigger('totalvurdering.value');
    }
  }, [typeGrunnmur, synligGrunnmurplastOgTopplist, totalvurderingTg, setValue, trigger]);

  const terrengfallFraGrunnmur = watch('terrengfallFraGrunnmur.value');
  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      terrengfallFraGrunnmur === 'ja|Ja' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', 2);
      trigger('totalvurdering.value');
    }
  }, [typeGrunnmur, terrengfallFraGrunnmur, totalvurderingTg, setValue, trigger]);

  const alder = watch('alder.value');
  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      alder === 'ja|Ja' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', 2);
      trigger('totalvurdering.value');
    }
  }, [typeGrunnmur, alder, totalvurderingTg, setValue, trigger]);

  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      totalvurderingTg !== 3
    ) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', '');
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [typeGrunnmur, totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Drenering.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="typeGrunnmur"
          header={getCheckHeader(1, PartTypes.Drenering.id, 'typeGrunnmur')}
          alternatives={[
            {
              value: 'grunnmur_ringmur',
              label: 'Grunnmur/ringmur',
            },
            { value: 'plate', label: 'Støpt plate på mark' },
            { value: 'apen_fundamentering', label: 'Åpen fundamentering/pilarer' },
          ]}
        />

        {typeGrunnmur && typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') && (
          <>
            <CheckText
              id="arbeidEtterByggeaar"
              header={getCheckHeader(1, PartTypes.Drenering.id, 'arbeidEtterByggeaar')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            {arbeidEtterByggeaar === 'ja|Ja' && (
              <CheckText
                id="oppgradert"
                header={getCheckHeader(1, PartTypes.Drenering.id, 'oppgradert')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ukjent', label: 'Ukjent' },
                ]}
              />
            )}

            <CheckText
              id="synligGrunnmurplastOgTopplist"
              header={getCheckHeader(1, PartTypes.Drenering.id, 'synligGrunnmurplastOgTopplist')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="alder"
              header={getCheckHeader(1, PartTypes.Drenering.id, 'alder')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            <CheckText
              id="funksjonsvikt"
              header={getCheckHeader(1, PartTypes.Drenering.id, 'funksjonsvikt')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        <CheckText
          id="terrengfallFraGrunnmur"
          header={getCheckHeader(1, PartTypes.Drenering.id, 'terrengfallFraGrunnmur')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="vannledingFraHusMangelfull"
          header={getCheckHeader(1, PartTypes.Drenering.id, 'vannledingFraHusMangelfull')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Drenering.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Drenering.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Drenering.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Drenering.name}
      />
    </FormProvider>
  );
};

export const DreneringSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  etablertDrenering: Joi.any().optional(), // TODO remove this when testing is done. This validation is included only to support older tilstandsrapporter while testing.
  typeGrunnmur: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }).unknown(true),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  oppgradert: Joi.object({
    value: Joi.when(Joi.ref('/arbeidEtterByggeaar.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    comment: Joi.when('..value', { is: 'nei|Nei', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  synligGrunnmurplastOgTopplist: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), {
      is: 'ikke_kontrollerbart|Ikke kontrollerbart',
      then: Joi.string().required(),
    }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ja|Ja',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  funksjonsvikt: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  terrengfallFraGrunnmur: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ikke_kontrollerbart|Ikke kontrollerbart',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  vannledingFraHusMangelfull: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ikke_kontrollerbart|Ikke kontrollerbart',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }).presence('optional'),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartDreneringFormData extends BasePartForm {
  typeGrunnmur: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  oppgradert: CheckSingle<string>;
  synligGrunnmurplastOgTopplist: CheckSingle<string>;
  terrengfallFraGrunnmur: CheckSingle<string>;
  vannledingFraHusMangelfull: CheckSingle<string>;
  alder: CheckSingle<string>;
  funksjonsvikt: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default DreneringForm;
