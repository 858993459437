import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import FormSectionHeader from '@components/common/FormSectionHeader';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';

const KjokkenForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartKjokkenFormData>) => {
  const [formMethods] = usePartForm<PartKjokkenFormData>(
    KjokkenSchema,
    defaultValues,
    PartTypes.Kjokken.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAvtrekk = watch('typeAvtrekk.values');
  useEffect(() => {
    if (!typeAvtrekk || !typeAvtrekk.includes('mekanisk|Mekanisk')) {
      setValue('avvikAvtrekk.value', null);
    }
  }, [typeAvtrekk, setValue]);

  const overflateInnredningTotalvurderingTg = watch('overflateInnredningTotalvurdering.tg');
  const ventilasjonTotalvurderingTg = watch('ventilasjonTotalvurdering.tg');

  useTotalvurderingTgEffects({
    totalvurderingTg: overflateInnredningTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'overflateInnredningUtbedringskostnader',
    anbefalteTiltakName: 'overflateInnredningAnbefalteTiltak',
  });

  useTotalvurderingTgEffects({
    totalvurderingTg: ventilasjonTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'ventilasjonUtbedringskostnader',
    anbefalteTiltakName: 'ventilasjonAnbefalteTiltak',
  });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Kjokken.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <FormSectionHeader>Overflater og innredning</FormSectionHeader>
        <CheckBoolean
          id="fuktskade"
          header={getCheckHeader(partVersion, PartTypes.Kjokken.id, 'fuktskade')}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.Kjokken.id,
              'overflateInnredningTotalvurdering'
            ),
            id: 'overflateInnredningTotalvurdering.value',
            tg: 'overflateInnredningTotalvurdering.tg',
          }}
        />

        <CheckBoolean
          id="skaderKjokkeninnredning"
          header={getCheckHeader(partVersion, PartTypes.Kjokken.id, 'skaderKjokkeninnredning')}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.Kjokken.id,
              'overflateInnredningTotalvurdering'
            ),
            id: 'overflateInnredningTotalvurdering.value',
            tg: 'overflateInnredningTotalvurdering.tg',
          }}
        />

        <KonklusjonTiltakKostnader
          konklusjonId="overflateInnredningTotalvurdering"
          anbefalteTiltakId="overflateInnredningAnbefalteTiltak"
          utbedringskostnaderId="overflateInnredningUtbedringskostnader"
          partType="Kjokken"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(overflateInnredningTotalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(overflateInnredningTotalvurderingTg)}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.Kjokken.id,
              'overflateInnredningTotalvurdering'
            ),
            id: 'overflateInnredningTotalvurdering.value',
            tg: 'overflateInnredningTotalvurdering.tg',
          }}
        />

        <FormSectionHeader>Avtrekk</FormSectionHeader>
        <CheckMultiText
          id="typeAvtrekk"
          header={getCheckHeader(partVersion, PartTypes.Kjokken.id, 'typeAvtrekk')}
          alternatives={[
            { value: 'mekanisk', label: 'Mekanisk' },
            { value: 'naturlig', label: 'Naturlig' },
            { value: 'kullfilter', label: 'Omluftsvifte (kullfilter)' },
            { value: 'ingen', label: 'Ingen' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Kjokken.id, 'typeAvtrekk'),
            id: 'typeAvtrekk.comment',
            tg: 'ventilasjonTotalvurdering.tg',
          }}
          hideComment={false}
        />

        {typeAvtrekk && typeAvtrekk.includes('mekanisk|Mekanisk') && (
          <CheckBoolean
            id="avvikAvtrekk"
            header={getCheckHeader(partVersion, PartTypes.Kjokken.id, 'avvikAvtrekk')}
            phrasesTarget={{
              header: getCheckHeader(
                partVersion,
                PartTypes.Kjokken.id,
                'ventilasjonTotalvurdering'
              ),
              id: 'ventilasjonTotalvurdering.value',
              tg: 'ventilasjonTotalvurdering.tg',
            }}
          />
        )}

        <KonklusjonTiltakKostnader
          konklusjonId="ventilasjonTotalvurdering"
          anbefalteTiltakId="ventilasjonAnbefalteTiltak"
          utbedringskostnaderId="ventilasjonUtbedringskostnader"
          partType="Kjokken"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(ventilasjonTotalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(ventilasjonTotalvurderingTg)}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Kjokken.id, 'ventilasjonTotalvurdering'),
            id: 'ventilasjonTotalvurdering.value',
            tg: 'ventilasjonTotalvurdering.tg',
          }}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Kjokken.name}
      />
    </FormProvider>
  );
};

export const KjokkenSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktskade: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderKjokkeninnredning: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAvtrekk: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  avvikAvtrekk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        state.typeAvtrekk.values &&
        state.typeAvtrekk.values.includes('mekanisk|Mekanisk')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  overflateInnredningTotalvurdering: validateTotalvurdering,
  overflateInnredningUtbedringskostnader: validateUbedringskostnader(
    'overflateInnredningTotalvurdering'
  ),
  overflateInnredningAnbefalteTiltak: validateAnbefalteTiltak('overflateInnredningTotalvurdering'),
  ventilasjonTotalvurdering: validateTotalvurdering,
  ventilasjonUtbedringskostnader: validateUbedringskostnader('ventilasjonTotalvurdering'),
  ventilasjonAnbefalteTiltak: validateAnbefalteTiltak('ventilasjonTotalvurdering'),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartKjokkenFormData extends BasePartForm {
  fuktskade: CheckSingle<boolean>;
  skaderKjokkeninnredning: CheckSingle<boolean>;
  overflateInnredningTotalvurdering: CheckSingle<string>;
  overflateInnredningUtbedringskostnader: CheckSingle<string>;
  overflateInnredningAnbefalteTiltak: CheckSingle<boolean>;
  typeAvtrekk: MultiCheck<string>;
  avvikAvtrekk: CheckSingle<boolean>;
  ventilasjonTotalvurdering: CheckSingle<string>;
  ventilasjonUtbedringskostnader: CheckSingle<string>;
  ventilasjonAnbefalteTiltak: CheckSingle<boolean>;
}

export default KjokkenForm;
