import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const TrappForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartTrappFormData>) => {
  const [formMethods] = usePartForm<PartTrappFormData>(
    TrappSchema,
    defaultValues,
    PartTypes.Trapp.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const rekkverkMangler = watch('rekkverkMangler.value');
  useEffect(() => {
    if (rekkverkMangler === true) {
      setValue('rekkverkForLavt.value', null, { shouldValidate: true });
      setValue('rekkverkApningAvvik.value', null, { shouldValidate: true });
      setValue('rekkverkOpptrinnAvvik.value', null, { shouldValidate: true });
      setValue('handloperMangler.value', null, { shouldValidate: true });
    }
  }, [rekkverkMangler, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Trapp.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <SingleTextarea
          id="beskrivelse"
          header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'beskrivelse')}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Trapp.id, 'beskrivelse'),
            id: 'beskrivelse.value',
            tg: 'totalvurdering.tg',
          }}
        />

        <CheckBoolean
          id="rekkverkMangler"
          header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'rekkverkMangler')}
        />

        {rekkverkMangler === false && (
          <>
            <CheckBoolean
              id="rekkverkForLavt"
              header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'rekkverkForLavt')}
            />

            <CheckBoolean
              id="rekkverkApningAvvik"
              header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'rekkverkApningAvvik')}
            />

            <CheckBoolean
              id="rekkverkOpptrinnAvvik"
              header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'rekkverkOpptrinnAvvik')}
            />

            <CheckBoolean
              id="handloperMangler"
              header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'handloperMangler')}
            />
          </>
        )}

        <CheckBoolean
          id="andreAvvik"
          header={getCheckHeader(partVersion, PartTypes.Trapp.id, 'andreAvvik')}
        />

        <KonklusjonTiltakKostnader
          partType="Trapp"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Trapp.name}
      />
    </FormProvider>
  );
};

export const TrappSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  beskrivelse: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  rekkverkMangler: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  rekkverkForLavt: Joi.object({
    value: Joi.when('/rekkverkMangler.value', { is: false, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  rekkverkApningAvvik: Joi.object({
    value: Joi.when('/rekkverkMangler.value', { is: false, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  rekkverkOpptrinnAvvik: Joi.object({
    value: Joi.when('/rekkverkMangler.value', { is: false, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  handloperMangler: Joi.object({
    value: Joi.when('/rekkverkMangler.value', { is: false, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  andreAvvik: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartTrappFormData extends BasePartForm {
  beskrivelse: CheckSingle<string>;
  rekkverkForLavt: CheckSingle<boolean>;
  rekkverkApningAvvik: CheckSingle<boolean>;
  rekkverkOpptrinnAvvik: CheckSingle<boolean>;
  handloperMangler: CheckSingle<boolean>;
  rekkverkMangler: CheckSingle<boolean>;
  andreAvvik: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default TrappForm;
