// noinspection DuplicatedCode

import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import FormSectionHeader from '@components/common/FormSectionHeader';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';

const VaatromForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartVaatromFormData>) => {
  const [formMethods] = usePartForm<PartVaatromFormData>(
    VaatromSchema,
    defaultValues,
    PartTypes.Vaatrom.id,
    doOnSave
  );

  const {
    query: { version = '8' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;
  const slakt = watch('slakt.value');
  const [prevSlaktValue, setPrevSlaktValue] = useState<boolean | null | undefined>(slakt);
  const [confirmOverwrite, setConfirmOverwrite] = useState<boolean | null>(null);

  // Show confirmation dialog if user changes slakt value
  useEffect(() => {
    if (
      confirmOverwrite === null &&
      prevSlaktValue !== null &&
      prevSlaktValue !== undefined &&
      prevSlaktValue !== slakt
    ) {
      const didConfirm = confirm(
        'Er du sikker på at du vil endre verdi? Dette kan føre til tap av det du allerede har fylt ut under.'
      );
      if (!didConfirm) {
        setValue('slakt.value', prevSlaktValue, { shouldValidate: true });
      }
      setConfirmOverwrite(didConfirm);
    }
    setPrevSlaktValue(slakt);
  }, [slakt, setConfirmOverwrite]);

  useEffect(() => {
    if (confirmOverwrite === false) return;
    if (slakt) {
      setValue('arbeidEtterByggeaar.value', null, { shouldValidate: true });
      setValue('overflate.value', null, { shouldValidate: true });
      setValue('tilstrekkeligFallTilSluk.value', null, { shouldValidate: true });
      setValue('lekkasjevannTilSluk.value', null, { shouldValidate: true });
      setValue('vinduDorVatsone.value', null, { shouldValidate: true });
      setValue('vinduDorFuktbestandig.value', null, { shouldValidate: true });
      setValue('gjennomforingerKaldSone.value', null, { shouldValidate: true });
      setValue('punktertDiffusjonssperre.value', null, { shouldValidate: true });
      setValue('sprekkerFlis.value', null, { shouldValidate: true });
      setValue('biologiskeSkadegjorere.value', null, { shouldValidate: true });
      setValue('knirkGulv.value', null, { shouldValidate: true });
      setValue('overflateTotalvurdering.value', null, { shouldValidate: true });
      setValue('overflateTotalvurdering.tg', null, { shouldValidate: true });
      setValue('overflateUtbedringskostnader.value', null, { shouldValidate: true });
      setValue('overflateAnbefalteTiltak.value', null, { shouldValidate: true });
      setValue('slukTilgjengeligInspeksjon.value', null, { shouldValidate: true });
      setValue('slukType.values', [], { shouldValidate: true });
      setValue('ufagmessigUtforelse.value', null, { shouldValidate: true });
      setValue('rorgjennomforingerVatSone.value', null, { shouldValidate: true });
      setValue('membranFeilUtforelse.value', null, { shouldValidate: true });
      setValue('overHalvpartBrukstid.value', null, { shouldValidate: true });
      setValue('membranTotalvurdering.value', null, { shouldValidate: true });
      setValue('membranTotalvurdering.tg', null, { shouldValidate: true });
      setValue('membranUtbedringskostnader.value', null, { shouldValidate: true });
      setValue('membranAnbefalteTiltak.value', null, { shouldValidate: true });
      setValue('sanitarBeskrivelse.value', null, { shouldValidate: true });
      setValue('typeVentilering.value', null, { shouldValidate: true });
      setValue('ventilasjonFunksjonstestet.value', null, { shouldValidate: true });
      setValue('skaderSanitar.value', null, { shouldValidate: true });
      setValue('innebyggetSisterne.value', null, { shouldValidate: true });
      setValue('manglendeDreneringSisterne.value', null, { shouldValidate: true });
      setValue('sanitarTotalvurdering.value', null, { shouldValidate: true });
      setValue('sanitarTotalvurdering.tg', null, { shouldValidate: true });
      setValue('sanitarUtbedringskostnader.value', null, { shouldValidate: true });
      setValue('sanitarAnbefalteTiltak.value', null, { shouldValidate: true });
      setValue('ventilasjonTotalvurdering.value', null, { shouldValidate: true });
      setValue('ventilasjonTotalvurdering.tg', null, { shouldValidate: true });
      setValue('ventilasjonAnbefalteTiltak.value', null, { shouldValidate: true });
      setValue('fuktTotalvurdering.value', null, { shouldValidate: true });
      setValue('fuktTotalvurdering.tg', null, { shouldValidate: true });
      setValue('fuktUtbedringskostnader.value', null, { shouldValidate: true });
      setValue('fuktAnbefalteTiltak.value', null, { shouldValidate: true });
      setValue('dokumentasjon.value', null, { shouldValidate: true });
      setValue('slaktTotalvurdering.tg', 3, { shouldValidate: true });
    } else {
      setValue('slaktTotalvurdering.value', null, { shouldValidate: true });
      setValue('slaktTotalvurdering.tg', null, { shouldValidate: true });
      setValue('slaktUtbedringskostnader.value', null, { shouldValidate: true });
      setValue('slaktUtbedringskostnader.comment', null, { shouldValidate: true });
    }
  }, [slakt, setValue, confirmOverwrite]);

  useEffect(() => {
    setConfirmOverwrite(null);
  }, [confirmOverwrite]);

  const overflateTotalvurderingTg = watch('overflateTotalvurdering.tg');
  useTotalvurderingTgEffects({
    totalvurderingTg: overflateTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'overflateUtbedringskostnader',
    anbefalteTiltakName: 'overflateAnbefalteTiltak',
  });

  const vinduDorVatsone = watch('vinduDorVatsone.value');
  useEffect(() => {
    if (vinduDorVatsone !== true) {
      setValue('vinduDorFuktbestandig.value', null, { shouldValidate: true });
    }
  }, [vinduDorVatsone, setValue]);

  const gjennomforingerKaldSone = watch('gjennomforingerKaldSone.value');
  useEffect(() => {
    if (gjennomforingerKaldSone !== true) {
      setValue('punktertDiffusjonssperre.value', null, { shouldValidate: true });
    }
  }, [gjennomforingerKaldSone, setValue]);

  const slukTilgjengeligInspeksjon = watch('slukTilgjengeligInspeksjon.value');
  useEffect(() => {
    if (slukTilgjengeligInspeksjon !== false) {
      setValue('slukType.values', []);
      setValue('ufagmessigUtforelse.value', null, { shouldValidate: true });
    }
  }, [slukTilgjengeligInspeksjon, setValue]);

  const membranTotalvurderingTg = watch('membranTotalvurdering.tg');
  useTotalvurderingTgEffects({
    totalvurderingTg: membranTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'membranUtbedringskostnader',
    anbefalteTiltakName: 'membranAnbefalteTiltak',
  });

  const typeVentilering = watch('typeVentilering.value');
  useEffect(() => {
    if (typeVentilering !== 'mekanisk|Mekanisk avtrekk') {
      setValue('ventilasjonFunksjonstestet.value', null, { shouldValidate: true });
    }
  }, [typeVentilering, setValue]);

  const innebyggetSisterne = watch('innebyggetSisterne.value');
  useEffect(() => {
    if (innebyggetSisterne === false) {
      setValue('manglendeDreneringSisterne.value', null, { shouldValidate: true });
    }
  }, [innebyggetSisterne, setValue]);

  const sanitarTotalvurderingTg = watch('sanitarTotalvurdering.tg');
  useTotalvurderingTgEffects({
    totalvurderingTg: sanitarTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'sanitarUtbedringskostnader',
    anbefalteTiltakName: 'sanitarAnbefalteTiltak',
  });

  const ventilasjonTotalvurderingTg = watch('ventilasjonTotalvurdering.tg');
  useTotalvurderingTgEffects({
    totalvurderingTg: ventilasjonTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'ventilasjonUtbedringskostnader',
    anbefalteTiltakName: 'ventilasjonAnbefalteTiltak',
  });

  const hulltaking = watch('hulltaking.value');
  useEffect(() => {
    if (hulltaking !== true) {
      setValue('registrertFuktVedHulltaking.value', null, { shouldValidate: true });
    }
    if (hulltaking !== false) {
      setValue('fuktSok.value', null, { shouldValidate: true });
    }
  }, [hulltaking, setValue]);

  const fuktTotalvurderingTg = watch('fuktTotalvurdering.tg');
  useTotalvurderingTgEffects({
    totalvurderingTg: fuktTotalvurderingTg,
    setValue,
    utbedringskostnaderName: 'fuktUtbedringskostnader',
    anbefalteTiltakName: 'fuktAnbefalteTiltak',
  });

  // We must have this to make sure form is revalidated after confirm dialog.
  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [slakt, trigger]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Vaatrom.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckBoolean
          id="slakt"
          header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slakt')}
        />

        {!slakt && (
          <>
            <FormSectionHeader>Overflate</FormSectionHeader>

            {/*OVERFLATER*/}
            <SingleTextarea
              id="overflate"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'overflate')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'overflate'),
                id: 'overflate.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <CheckText
              id="arbeidEtterByggeaar"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'arbeidEtterByggeaar')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'arbeidEtterByggeaar'),
                id: 'arbeidEtterByggeaar.comment',
                tg: 'overflateTotalvurdering.tg',
              }}
              hideComment={false}
            />

            <CheckText
              id="tilstrekkeligFallTilSluk"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'tilstrekkeligFallTilSluk')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <CheckBoolean
              id="lekkasjevannTilSluk"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'lekkasjevannTilSluk')}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <CheckBoolean
              id="vinduDorVatsone"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'vinduDorVatsone')}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            {vinduDorVatsone && (
              <CheckText
                id="vinduDorFuktbestandig"
                header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'vinduDorFuktbestandig')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ukjent', label: 'Ukjent' },
                ]}
                phrasesTarget={{
                  header: getCheckHeader(
                    partVersion,
                    PartTypes.Vaatrom.id,
                    'overflateTotalvurdering'
                  ),
                  id: 'overflateTotalvurdering.value',
                  tg: 'overflateTotalvurdering.tg',
                }}
              />
            )}

            <CheckBoolean
              id="gjennomforingerKaldSone"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'gjennomforingerKaldSone')}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            {gjennomforingerKaldSone && (
              <CheckBoolean
                id="punktertDiffusjonssperre"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'punktertDiffusjonssperre'
                )}
                phrasesTarget={{
                  header: getCheckHeader(
                    partVersion,
                    PartTypes.Vaatrom.id,
                    'overflateTotalvurdering'
                  ),
                  id: 'overflateTotalvurdering.value',
                  tg: 'overflateTotalvurdering.tg',
                }}
              />
            )}
            <CheckBoolean
              id="sprekkerFlis"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'sprekkerFlis')}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <CheckBoolean
              id="biologiskeSkadegjorere"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'biologiskeSkadegjorere')}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <CheckText
              id="knirkGulv"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'knirkGulv')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_aktuelt', label: 'Ikke aktuelt (gjelder ikke flislagt gulv)' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <KonklusjonTiltakKostnader
              konklusjonId="overflateTotalvurdering"
              anbefalteTiltakId="overflateAnbefalteTiltak"
              utbedringskostnaderId="overflateUtbedringskostnader"
              partType="Vaatrom"
              partVersion={partVersion}
              showUtbedringskostnader={showUtbedringskostnader(overflateTotalvurderingTg)}
              showAnbefalteTiltak={showAnbefalteTiltak(overflateTotalvurderingTg)}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'overflateTotalvurdering'
                ),
                id: 'overflateTotalvurdering.value',
                tg: 'overflateTotalvurdering.tg',
              }}
            />

            <FormSectionHeader>Membran, tettesjikt og sluk</FormSectionHeader>
            {/*MEMBRAN*/}
            <CheckBoolean
              id="slukTilgjengeligInspeksjon"
              header={getCheckHeader(
                partVersion,
                PartTypes.Vaatrom.id,
                'slukTilgjengeligInspeksjon'
              )}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'membranTotalvurdering'),
                id: 'membranTotalvurdering.value',
                tg: 'membranTotalvurdering.tg',
              }}
            />

            {slukTilgjengeligInspeksjon === false && (
              <>
                <CheckMultiText
                  id="slukType"
                  header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slukType')}
                  alternatives={[
                    { value: 'plast', label: 'Plast' },
                    { value: 'stopejern', label: 'Støpejern' },
                    { value: 'rustfritt_stal', label: 'Rustfritt stål' },
                    { value: 'annet', label: 'Annet' },
                  ]}
                  phrasesTarget={{
                    header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slukType'),
                    id: 'slukType.comment',
                    tg: 'membranTotalvurdering.tg',
                  }}
                  hideComment={false}
                />

                <CheckText
                  id="ufagmessigUtforelse"
                  header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'ufagmessigUtforelse')}
                  alternatives={[
                    { value: 'ja', label: 'Ja' },
                    { value: 'nei', label: 'Nei' },
                    { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
                  ]}
                  phrasesTarget={{
                    header: getCheckHeader(
                      partVersion,
                      PartTypes.Vaatrom.id,
                      'membranTotalvurdering'
                    ),
                    id: 'membranTotalvurdering.value',
                    tg: 'membranTotalvurdering.tg',
                  }}
                />
              </>
            )}

            <CheckBoolean
              id="rorgjennomforingerVatSone"
              header={getCheckHeader(
                partVersion,
                PartTypes.Vaatrom.id,
                'rorgjennomforingerVatSone'
              )}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'membranTotalvurdering'),
                id: 'membranTotalvurdering.value',
                tg: 'membranTotalvurdering.tg',
              }}
            />

            <CheckBoolean
              id="membranFeilUtforelse"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'membranFeilUtforelse')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'membranTotalvurdering'),
                id: 'membranTotalvurdering.value',
                tg: 'membranTotalvurdering.tg',
              }}
            />

            <CheckText
              id="overHalvpartBrukstid"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'overHalvpartBrukstid')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'membranTotalvurdering'),
                id: 'membranTotalvurdering.value',
                tg: 'membranTotalvurdering.tg',
              }}
            />

            <KonklusjonTiltakKostnader
              konklusjonId="membranTotalvurdering"
              anbefalteTiltakId="membranAnbefalteTiltak"
              utbedringskostnaderId="membranUtbedringskostnader"
              partType="Vaatrom"
              partVersion={partVersion}
              showUtbedringskostnader={showUtbedringskostnader(membranTotalvurderingTg)}
              showAnbefalteTiltak={showAnbefalteTiltak(membranTotalvurderingTg)}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'membranTotalvurdering'),
                id: 'membranTotalvurdering.value',
                tg: 'membranTotalvurdering.tg',
              }}
            />

            <FormSectionHeader>Sanitærutstyr</FormSectionHeader>
            {/*SANITAR*/}

            <SingleTextarea
              id="sanitarBeskrivelse"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'sanitarBeskrivelse')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'sanitarBeskrivelse'),
                id: 'sanitarBeskrivelse.value',
                tg: 'sanitarTotalvurdering.tg',
              }}
            />

            <CheckBoolean
              id="skaderSanitar"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'skaderSanitar')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'sanitarTotalvurdering'),
                id: 'sanitarTotalvurdering.value',
                tg: 'sanitarTotalvurdering.tg',
              }}
            />

            <CheckBoolean
              id="innebyggetSisterne"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'innebyggetSisterne')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'sanitarTotalvurdering'),
                id: 'sanitarTotalvurdering.value',
                tg: 'sanitarTotalvurdering.tg',
              }}
            />

            {innebyggetSisterne && (
              <CheckText
                id="manglendeDreneringSisterne"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'manglendeDreneringSisterne'
                )}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'annen_godkjent', label: 'Annen godkjent løsning' },
                ]}
                phrasesTarget={{
                  header: getCheckHeader(
                    partVersion,
                    PartTypes.Vaatrom.id,
                    'sanitarTotalvurdering'
                  ),
                  id: 'sanitarTotalvurdering.value',
                  tg: 'sanitarTotalvurdering.tg',
                }}
              />
            )}

            <KonklusjonTiltakKostnader
              konklusjonId="sanitarTotalvurdering"
              anbefalteTiltakId="sanitarAnbefalteTiltak"
              utbedringskostnaderId="sanitarUtbedringskostnader"
              partType="Vaatrom"
              partVersion={partVersion}
              showUtbedringskostnader={showUtbedringskostnader(sanitarTotalvurderingTg)}
              showAnbefalteTiltak={showAnbefalteTiltak(sanitarTotalvurderingTg)}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'sanitarTotalvurdering'),
                id: 'sanitarTotalvurdering.value',
                tg: 'sanitarTotalvurdering.tg',
              }}
            />

            <FormSectionHeader>Ventilasjon</FormSectionHeader>
            <CheckText
              id="typeVentilering"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'typeVentilering')}
              alternatives={[
                { value: 'mekanisk', label: 'Mekanisk avtrekk' },
                { value: 'naturlig', label: 'Naturlig' },
                { value: 'ingen', label: 'Ingen ventilering' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'typeVentilering'),
                id: 'typeVentilering.comment',
                tg: 'ventilasjonTotalvurdering.tg',
              }}
              hideComment={false}
            />

            {typeVentilering === 'mekanisk|Mekanisk avtrekk' && (
              <CheckBoolean
                id="ventilasjonFunksjonstestet"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'ventilasjonFunksjonstestet'
                )}
                phrasesTarget={{
                  header: getCheckHeader(
                    partVersion,
                    PartTypes.Vaatrom.id,
                    'ventilasjonTotalvurdering'
                  ),
                  id: 'ventilasjonTotalvurdering.value',
                  tg: 'ventilasjonTotalvurdering.tg',
                }}
              />
            )}

            <KonklusjonTiltakKostnader
              konklusjonId="ventilasjonTotalvurdering"
              anbefalteTiltakId="ventilasjonAnbefalteTiltak"
              utbedringskostnaderId="ventilasjonUtbedringskostnader"
              partType="Vaatrom"
              partVersion={partVersion}
              showUtbedringskostnader={showUtbedringskostnader(ventilasjonTotalvurderingTg)}
              showAnbefalteTiltak={showAnbefalteTiltak(ventilasjonTotalvurderingTg)}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'ventilasjonTotalvurdering'
                ),
                id: 'ventilasjonTotalvurdering.value',
                tg: 'ventilasjonTotalvurdering.tg',
              }}
            />

            <FormSectionHeader>Fuktmåling</FormSectionHeader>
            {/*FUKT*/}
            <CheckBoolean
              id="hulltaking"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'hulltaking')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'fuktTotalvurdering'),
                id: 'fuktTotalvurdering.value',
                tg: 'fuktTotalvurdering.tg',
              }}
            />

            {hulltaking && (
              <CheckBoolean
                id="registrertFuktVedHulltaking"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'registrertFuktVedHulltaking'
                )}
                phrasesTarget={{
                  header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'fuktTotalvurdering'),
                  id: 'fuktTotalvurdering.value',
                  tg: 'fuktTotalvurdering.tg',
                }}
              />
            )}

            {hulltaking === false && (
              <CheckBoolean
                id="fuktSok"
                header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'fuktSok')}
                phrasesTarget={{
                  header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'fuktTotalvurdering'),
                  id: 'fuktTotalvurdering.value',
                  tg: 'fuktTotalvurdering.tg',
                }}
              />
            )}

            <KonklusjonTiltakKostnader
              konklusjonId="fuktTotalvurdering"
              anbefalteTiltakId="fuktAnbefalteTiltak"
              utbedringskostnaderId="fuktUtbedringskostnader"
              partType="Vaatrom"
              partVersion={partVersion}
              showUtbedringskostnader={showUtbedringskostnader(fuktTotalvurderingTg)}
              showAnbefalteTiltak={showAnbefalteTiltak(fuktTotalvurderingTg)}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'fuktTotalvurdering'),
                id: 'fuktTotalvurdering.value',
                tg: 'fuktTotalvurdering.tg',
              }}
            />

            <FormSectionHeader>Dokumentasjon</FormSectionHeader>
            <CheckBoolean
              id="dokumentasjon"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'dokumentasjon')}
              hideComment={false}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'dokumentasjon'),
                id: 'dokumentasjon.comment',
                tg: undefined,
              }}
            />
          </>
        )}
        {slakt === true && (
          <>
            <CheckBoolean
              id="hulltaking"
              header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'hulltaking')}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slaktTotalvurdering'),
                id: 'slaktTotalvurdering.value',
                tg: 'slaktTotalvurdering.tg',
              }}
            />

            {hulltaking && (
              <CheckBoolean
                id="registrertFuktVedHulltaking"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vaatrom.id,
                  'registrertFuktVedHulltaking'
                )}
                phrasesTarget={{
                  header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slaktTotalvurdering'),
                  id: 'slaktTotalvurdering.value',
                  tg: 'slaktTotalvurdering.tg',
                }}
              />
            )}

            {hulltaking === false && (
              <CheckBoolean
                id="fuktSok"
                header={getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'fuktSok')}
                phrasesTarget={{
                  header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slaktTotalvurdering'),
                  id: 'slaktTotalvurdering.value',
                  tg: 'slaktTotalvurdering.tg',
                }}
              />
            )}

            <KonklusjonTiltakKostnader
              konklusjonId="slaktTotalvurdering"
              anbefalteTiltakId="slaktAnbefalteTiltak"
              utbedringskostnaderId="slaktUtbedringskostnader"
              partType="Vaatrom"
              partVersion={partVersion}
              showUtbedringskostnader={true}
              tgReadonly={true}
              showAnbefalteTiltak={true}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Vaatrom.id, 'slaktTotalvurdering'),
                id: 'slaktTotalvurdering.value',
                tg: 'slaktTotalvurdering.tg',
              }}
            />
          </>
        )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Vaatrom.name}
      />
    </FormProvider>
  );
};

const CompleteSchema = Joi.object({
  availability: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  slakt: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  /* OVERFLATE */
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  overflate: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  tilstrekkeligFallTilSluk: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjevannTilSluk: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  vinduDorVatsone: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  vinduDorFuktbestandig: Joi.object({
    value: Joi.when(Joi.ref('/vinduDorVatsone.value'), {
      is: true,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  gjennomforingerKaldSone: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  punktertDiffusjonssperre: Joi.object({
    value: Joi.when(Joi.ref('/gjennomforingerKaldSone.value'), {
      is: true,
      then: Joi.boolean().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  sprekkerFlis: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  biologiskeSkadegjorere: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  knirkGulv: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  overflateTotalvurdering: validateTotalvurdering,
  overflateUtbedringskostnader: validateUbedringskostnader('overflateTotalvurdering'),
  overflateAnbefalteTiltak: validateAnbefalteTiltak('overflateTotalvurdering'),

  /* MEMBRAN */
  slukTilgjengeligInspeksjon: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  slukType: Joi.object({
    values: Joi.when(Joi.ref('/slukTilgjengeligInspeksjon.value'), {
      is: false,
      then: Joi.array().min(1).required(),
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  ufagmessigUtforelse: Joi.object({
    value: Joi.when(Joi.ref('/slukTilgjengeligInspeksjon.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  rorgjennomforingerVatSone: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  membranFeilUtforelse: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  overHalvpartBrukstid: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  membranTotalvurdering: validateTotalvurdering,
  membranUtbedringskostnader: validateUbedringskostnader('membranTotalvurdering'),
  membranAnbefalteTiltak: validateAnbefalteTiltak('membranTotalvurdering'),

  /* SANITAR */
  sanitarBeskrivelse: Joi.object({
    value: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  typeVentilering: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  ventilasjonFunksjonstestet: Joi.object({
    value: Joi.when(Joi.ref('/typeVentilering.value'), {
      is: 'mekanisk|Mekanisk avtrekk',
      then: Joi.boolean().required(),
    }).concat(
      Joi.when('/typeVentilering.value', {
        is: 'mekanisk_balansert|Mekanisk balansert ventilasjon',
        then: Joi.boolean().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderSanitar: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  innebyggetSisterne: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeDreneringSisterne: Joi.object({
    value: Joi.when(Joi.ref('/innebyggetSisterne.value'), {
      is: true,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  sanitarTotalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }).concat(
      Joi.when('..tg', {
        is: 2,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  sanitarUtbedringskostnader: validateUbedringskostnader('sanitarTotalvurdering'),
  sanitarAnbefalteTiltak: validateAnbefalteTiltak('sanitarTotalvurdering'),
  /* VENTILASJON */
  ventilasjonTotalvurdering: validateTotalvurdering,
  ventilasjonUtbedringskostnader: validateUbedringskostnader('ventilasjonTotalvurdering'),
  ventilasjonAnbefalteTiltak: validateAnbefalteTiltak('ventilasjonTotalvurdering'),

  /* FUKT */
  hulltaking: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  registrertFuktVedHulltaking: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: true, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktSok: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: false, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktTotalvurdering: validateTotalvurdering,
  fuktUtbedringskostnader: validateUbedringskostnader('fuktTotalvurdering'),
  fuktAnbefalteTiltak: validateAnbefalteTiltak('fuktTotalvurdering'),

  /* DOKUMENTASJON */
  dokumentasjon: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    tg: Joi.any().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

const SlaktSchema = Joi.object({
  availability: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  slakt: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  hulltaking: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  registrertFuktVedHulltaking: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: true, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktSok: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: false, then: Joi.boolean().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  slaktTotalvurdering: validateTotalvurdering,
  slaktUtbedringskostnader: validateUbedringskostnader('slaktTotalvurdering'),
  slaktAnbefalteTiltak: validateAnbefalteTiltak('slaktTotalvurdering'),
})
  .unknown(true)
  .messages({ '*': 'Dette feltet er påkrevet' });
export const VaatromSchema = Joi.when(
  Joi.object({
    slakt: Joi.object({
      value: true,
    }).unknown(true),
  }).unknown(true),
  { then: SlaktSchema, otherwise: CompleteSchema }
);

interface PartVaatromFormData extends BasePartForm {
  slakt: CheckSingle<boolean>;

  /* OVERFLATE */
  arbeidEtterByggeaar: CheckSingle<string>;
  overflate: CheckSingle<string>;
  tilstrekkeligFallTilSluk: CheckSingle<string>;
  lekkasjevannTilSluk: CheckSingle<boolean>;
  vinduDorVatsone: CheckSingle<boolean>;
  vinduDorFuktbestandig: CheckSingle<string>;
  gjennomforingerKaldSone: CheckSingle<boolean>;
  punktertDiffusjonssperre: CheckSingle<boolean>;
  sprekkerFlis: CheckSingle<boolean>;
  biologiskeSkadegjorere: CheckSingle<boolean>;
  knirkGulv: CheckSingle<string>;
  overflateTotalvurdering: CheckSingle<string>;
  overflateUtbedringskostnader: CheckSingle<string>;
  overflateAnbefalteTiltak: CheckSingle<boolean>;

  /* MEMBRAN */
  slukTilgjengeligInspeksjon: CheckSingle<boolean>;
  slukType: MultiCheck<string>;
  ufagmessigUtforelse: CheckSingle<string>;
  rorgjennomforingerVatSone: CheckSingle<boolean>;
  membranFeilUtforelse: CheckSingle<boolean>;
  overHalvpartBrukstid: CheckSingle<string>;
  membranTotalvurdering: CheckSingle<string>;
  membranUtbedringskostnader: CheckSingle<string>;
  membranAnbefalteTiltak: CheckSingle<boolean>;

  /* SANITAR */
  sanitarBeskrivelse: CheckSingle<string>;
  skaderSanitar: CheckSingle<boolean>;
  innebyggetSisterne: CheckSingle<boolean>;
  manglendeDreneringSisterne: CheckSingle<string>;
  sanitarTotalvurdering: CheckSingle<string>;
  sanitarUtbedringskostnader: CheckSingle<string>;
  sanitarAnbefalteTiltak: CheckSingle<boolean>;

  /* VENTILASJON */
  typeVentilering: CheckSingle<string>;
  ventilasjonFunksjonstestet: CheckSingle<boolean>;
  ventilasjonTotalvurdering: CheckSingle<string>;
  ventilasjonUtbedringskostnader: CheckSingle<string>;
  ventilasjonAnbefalteTiltak: CheckSingle<boolean>;

  /* FUKT */
  hulltaking: CheckSingle<boolean>;
  registrertFuktVedHulltaking: CheckSingle<boolean>;
  fuktSok: CheckSingle<boolean>;
  fuktTotalvurdering: CheckSingle<string>;
  fuktUtbedringskostnader: CheckSingle<string>;
  fuktAnbefalteTiltak: CheckSingle<boolean>;

  /* DOKUMENTASJON */
  dokumentasjon: CheckSingle<boolean>;

  /* SLAKT */
  slaktTotalvurdering: CheckSingle<string>;
  slaktUtbedringskostnader: CheckSingle<string>;
  slaktAnbefalteTiltak: CheckSingle<boolean>;
}

export default VaatromForm;
