import React from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const EtasjeskilleForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartEtasjeskilleFormData>) => {
  const [formMethods] = usePartForm<PartEtasjeskilleFormData>(
    EtasjeskilleSchema,
    defaultValues,
    PartTypes.Etasjeskille.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Etasjeskille.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="_type"
          header={getCheckHeader(partVersion, PartTypes.Etasjeskille.id, '_type')}
          alternatives={[
            { value: 'tre_bjelkelag', label: 'Trebjelkelag' },
            { value: 'betong', label: 'Betongdekke' },
            { value: 'hulldekke', label: 'Hulldekke' },
            { value: 'oppforet_tregult', label: 'Oppforet tregulv på betongdekke' },
            { value: 'stop_pa_grunn', label: 'Støpt gulv på grunn' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Etasjeskille.id, '_type'),
            id: '_type.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="skjevheterSvikt"
          header={getCheckHeader(partVersion, PartTypes.Etasjeskille.id, 'skjevheterSvikt')}
        />

        <KonklusjonTiltakKostnader
          partType="Etasjeskille"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Etasjeskille.name}
      />
    </FormProvider>
  );
};

export const EtasjeskilleSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  _type: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  skjevheterSvikt: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartEtasjeskilleFormData extends BasePartForm {
  _type: MultiCheck<string>;
  skjevheterSvikt: CheckSingle<boolean>;

  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default EtasjeskilleForm;
