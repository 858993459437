import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const KrypkjellerForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartKrypkjellerFormData>) => {
  const [formMethods] = usePartForm<PartKrypkjellerFormData>(
    KrypkjellerSchema,
    defaultValues,
    PartTypes.Krypkjeller.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Krypkjeller.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <SingleTextarea
          id="beskrivelse"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'beskrivelse')}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'beskrivelse'),
            id: 'beskrivelse.value',
            tg: 'totalvurdering.tg',
          }}
        />

        <CheckBoolean
          id="fuktsikringMotGrunnen"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'fuktsikringMotGrunnen')}
        />

        <CheckBoolean
          id="synligeFuktVannSkader"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'synligeFuktVannSkader')}
        />

        <CheckBoolean
          id="soppskader"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'soppskader')}
        />

        <CheckBoolean
          id="sviktGulv"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'sviktGulv')}
        />

        <CheckBoolean
          id="mangelfullVentilering"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'mangelfullVentilering')}
        />

        <SingleTextarea
          id="resultatFuktmaling"
          header={getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'resultatFuktmaling')}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Krypkjeller.id, 'resultatFuktmaling'),
            id: 'resultatFuktmaling.value',
            tg: 'totalvurdering.tg',
          }}
        />

        <KonklusjonTiltakKostnader
          partType="Krypkjeller"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Krypkjeller.name}
      />
    </FormProvider>
  );
};

export const KrypkjellerSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  beskrivelse: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktsikringMotGrunnen: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  synligeFuktVannSkader: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  soppskader: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  sviktGulv: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  mangelfullVentilering: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  resultatFuktmaling: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartKrypkjellerFormData extends BasePartForm {
  beskrivelse: CheckSingle<string>;
  fuktsikringMotGrunnen: CheckSingle<boolean>;
  synligeFuktVannSkader: CheckSingle<boolean>;
  soppskader: CheckSingle<boolean>;
  sviktGulv: CheckSingle<boolean>;
  mangelfullVentilering: CheckSingle<boolean>;
  resultatFuktmaling: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default KrypkjellerForm;
