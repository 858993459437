import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const LoftForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartLoftFormData>) => {
  const [formMethods] = usePartForm<PartLoftFormData>(
    LoftSchema,
    defaultValues,
    PartTypes.Loft.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const konstruksjonsPrinsipp = watch('konstruksjonsPrinsipp.value');
  useEffect(() => {
    if (konstruksjonsPrinsipp === 'kompakt_tak|Kompakt tak') {
      setValue('ventilering.value', null, { shouldValidate: true });
    }
  }, [konstruksjonsPrinsipp, setValue]);

  const typeLoft = watch('typeLoft.value');
  useEffect(() => {
    if (
      typeLoft !== 'innredet|Innredet loft (lukket konstruksjon)' &&
      typeLoft !== 'delvis_innredet|Delvis innredet / kaldtloft'
    ) {
      setValue('arbeidUtfortEtterByggeAar.value', null, { shouldValidate: true });
    }
    if (typeLoft !== 'flatt_tak|Flatt tak (konstruksjon uten loft)') {
      setValue('konstruksjonsPrinsipp.value', null, { shouldValidate: true });
    }
  }, [typeLoft, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Loft.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckText
          id="typeLoft"
          header={getCheckHeader(partVersion, PartTypes.Loft.id, 'typeLoft')}
          alternatives={[
            { value: 'innredet', label: 'Innredet loft (lukket konstruksjon)' },
            { value: 'kaldtloft', label: 'Kaldtloft' },
            { value: 'flatt_tak', label: 'Flatt tak (konstruksjon uten loft)' },
            { value: 'delvis_innredet', label: 'Delvis innredet / kaldtloft' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Loft.id, 'typeLoft'),
            id: 'typeLoft.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        {typeLoft === 'flatt_tak|Flatt tak (konstruksjon uten loft)' && (
          <>
            <CheckText
              id="konstruksjonsPrinsipp"
              header={getCheckHeader(partVersion, PartTypes.Loft.id, 'konstruksjonsPrinsipp')}
              alternatives={[
                { value: 'kompakt_tak', label: 'Kompakt tak' },
                { value: 'tretak', label: 'Tretak' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />
          </>
        )}

        {(typeLoft === 'innredet|Innredet loft (lukket konstruksjon)' ||
          typeLoft === 'delvis_innredet|Delvis innredet / kaldtloft') && (
          <CheckText
            id="arbeidUtfortEtterByggeAar"
            header={getCheckHeader(partVersion, PartTypes.Loft.id, 'arbeidUtfortEtterByggeAar')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ukjent', label: 'Ukjent' },
            ]}
          />
        )}

        <CheckBoolean id="fukt" header={getCheckHeader(partVersion, PartTypes.Loft.id, 'fukt')} />

        <CheckBoolean id="sopp" header={getCheckHeader(partVersion, PartTypes.Loft.id, 'sopp')} />

        {konstruksjonsPrinsipp !== 'kompakt_tak|Kompakt tak' && (
          <CheckText
            id="ventilering"
            header={getCheckHeader(partVersion, PartTypes.Loft.id, 'ventilering')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <CheckBoolean
          id="tetting"
          header={getCheckHeader(partVersion, PartTypes.Loft.id, 'tetting')}
        />
        <KonklusjonTiltakKostnader
          partType="Loft"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Loft.name}
      />
    </FormProvider>
  );
};

export const LoftSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeLoft: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  konstruksjonsPrinsipp: Joi.object({
    value: Joi.when(Joi.ref('/typeLoft.value'), {
      is: 'flatt_tak|Flatt tak (konstruksjon uten loft)',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidUtfortEtterByggeAar: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        (state.typeLoft.value === 'delvis_innredet|Delvis innredet / kaldtloft' ||
          state.typeLoft.value === 'innredet|Innredet loft (lukket konstruksjon)')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  fukt: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  sopp: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  ventilering: Joi.object({
    value: Joi.when('/konstruksjonsPrinsipp.value', {
      is: 'kompakt_tak|Kompakt tak',
      otherwise: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  tetting: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartLoftFormData extends BasePartForm {
  typeLoft: CheckSingle<string>;
  konstruksjonsPrinsipp: CheckSingle<string>;
  arbeidUtfortEtterByggeAar: CheckSingle<string>;
  fukt: CheckSingle<boolean>;
  sopp: CheckSingle<boolean>;
  ventilering: CheckSingle<string>;
  tetting: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default LoftForm;
