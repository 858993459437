import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';

const LovlighetForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartLovlighetFormData>) => {
  const [formMethods] = usePartForm<PartLovlighetFormData>(
    LovlighetSchema,
    defaultValues,
    PartTypes.Lovlighet.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  const avvikFraByggetegninger = watch('avvikFraByggetegninger.value');
  useEffect(() => {
    if (avvikFraByggetegninger !== 'ikke_kontrollert|Ikke kontrollert') {
      setValue('ulovligheter.value', null);
      setValue('ulovligheter.comment', null);
    }
  }, [avvikFraByggetegninger, setValue]);

  const manglendeBrannslukkingUtstyr = watch('manglendeBrannslukkingUtstyr.value');
  useEffect(() => {
    if (manglendeBrannslukkingUtstyr === 'ja|Ja') {
      setValue('utstyrSkader.value', null);
      setValue('utstyrSkader.comment', null);
      setValue('brannslukkingUtstyrSkader.value', null);
      setValue('brannslukkingUtstyrSkader.comment', null);
      setValue('roykvarslerSkader.value', null);
      setValue('roykvarslerSkader.comment', null);
    }
    if (manglendeBrannslukkingUtstyr === 'nei|Nei') {
      setValue('brannslukkingUtstyrSkader.value', null);
      setValue('brannslukkingUtstyrSkader.comment', null);
      setValue('roykvarslerSkader.value', null);
      setValue('roykvarslerSkader.comment', null);
    }
    if (
      manglendeBrannslukkingUtstyr === 'manglende_brannslukkerutstyr|Manglende brannslukkerutstyr'
    ) {
      setValue('utstyrSkader.value', null);
      setValue('utstyrSkader.comment', null);
      setValue('brannslukkingUtstyrSkader.value', null);
      setValue('brannslukkingUtstyrSkader.comment', null);
    }
    if (manglendeBrannslukkingUtstyr === 'manglende_roykvarsler|Manglende røykvarsler') {
      setValue('utstyrSkader.value', null);
      setValue('utstyrSkader.comment', null);
      setValue('roykvarslerSkader.value', null);
      setValue('roykvarslerSkader.comment', null);
    }
  }, [manglendeBrannslukkingUtstyr, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Lovlighet.name} />

        <CheckText
          id="avvikFraByggetegninger"
          header={getCheckHeader(4, PartTypes.Lovlighet.id, 'avvikFraByggetegninger')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        {avvikFraByggetegninger === 'ikke_kontrollert|Ikke kontrollert' && (
          <>
            <CheckBoolean
              id="ulovligheter"
              header={getCheckHeader(4, PartTypes.Lovlighet.id, 'ulovligheter')}
            />
          </>
        )}

        <CheckBoolean
          id="branncelleAvvik"
          header={getCheckHeader(4, PartTypes.Lovlighet.id, 'branncelleAvvik')}
        />

        <CheckText
          id="manglendeFerdigattest"
          header={getCheckHeader(4, PartTypes.Lovlighet.id, 'manglendeFerdigattest')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckBoolean
          id="romningsveierAvvik"
          header={getCheckHeader(4, PartTypes.Lovlighet.id, 'romningsveierAvvik')}
        />

        <CheckText
          id="manglendeBrannslukkingUtstyr"
          header={getCheckHeader(4, PartTypes.Lovlighet.id, 'manglendeBrannslukkingUtstyr')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'manglende_brannslukkerutstyr', label: 'Manglende brannslukkerutstyr' },
            { value: 'manglende_roykvarsler', label: 'Manglende røykvarsler' },
          ]}
        />

        {manglendeBrannslukkingUtstyr === 'nei|Nei' && (
          <CheckBoolean
            id="utstyrSkader"
            header={getCheckHeader(4, PartTypes.Lovlighet.id, 'utstyrSkader')}
          />
        )}
        {manglendeBrannslukkingUtstyr ===
          'manglende_brannslukkerutstyr|Manglende brannslukkerutstyr' && (
          <CheckBoolean
            id="roykvarslerSkader"
            header={getCheckHeader(4, PartTypes.Lovlighet.id, 'roykvarslerSkader')}
          />
        )}
        {manglendeBrannslukkingUtstyr === 'manglende_roykvarsler|Manglende røykvarsler' && (
          <CheckBoolean
            id="brannslukkingUtstyrSkader"
            header={getCheckHeader(4, PartTypes.Lovlighet.id, 'brannslukkingUtstyrSkader')}
          />
        )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Lovlighet.name}
      />
    </FormProvider>
  );
};

export const LovlighetSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikFraByggetegninger: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeFerdigattest: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  ulovligheter: Joi.object({
    value: Joi.when(Joi.ref('/avvikFraByggetegninger.value'), {
      is: 'ikke_kontrollert|Ikke kontrollert',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  branncelleAvvik: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  romningsveierAvvik: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeBrannslukkingUtstyr: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  utstyrSkader: Joi.object({
    value: Joi.when(Joi.ref('/manglendeBrannslukkingUtstyr.value'), {
      is: 'nei|Nei',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  brannslukkingUtstyrSkader: Joi.object({
    value: Joi.when(Joi.ref('/manglendeBrannslukkingUtstyr.value'), {
      is: 'manglende_roykvarsler|Manglende røykvarsler',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  roykvarslerSkader: Joi.object({
    value: Joi.when(Joi.ref('/manglendeBrannslukkingUtstyr.value'), {
      is: 'manglende_brannslukkerutstyr|Manglende brannslukkerutstyr',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartLovlighetFormData extends BasePartForm {
  avvikFraByggetegninger: CheckSingle<string>;
  manglendeFerdigattest: CheckSingle<string>;
  ulovligheter: CheckSingle<boolean>;
  branncelleAvvik: CheckSingle<boolean>;
  romningsveierAvvik: CheckSingle<boolean>;
  manglendeBrannslukkingUtstyr: CheckSingle<string>;
  utstyrSkader: CheckSingle<boolean>;
  brannslukkingUtstyrSkader: CheckSingle<boolean>;
  roykvarslerSkader: CheckSingle<boolean>;
}

export default LovlighetForm;
