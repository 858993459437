import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const GrunnFundamentForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartGrunnFundamentFormData>) => {
  const [formMethods] = usePartForm<PartGrunnFundamentFormData>(
    GrunnFundamentSchema,
    defaultValues,
    PartTypes.GrunnFundament.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const fundamentGrunnmurType = watch('fundamentGrunnmurType.values');
  useEffect(() => {
    if (
      !fundamentGrunnmurType ||
      (!fundamentGrunnmurType?.includes('grunnmur_med_kjeller|Grunnmur m/kjeller') &&
        !fundamentGrunnmurType?.includes('grunnmur_med_underetasje|Grunnmur m/underetasje'))
    ) {
      setValue('typeKjellerGrunnmur.values', [], { shouldValidate: true });
    }
  }, [fundamentGrunnmurType, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  const sprekkMur = watch('sprekkMur.value');
  useEffect(() => {
    if (sprekkMur === 'ja|Ja' && (totalvurderingTg === undefined || totalvurderingTg === null)) {
      setValue('totalvurdering.tg', 2);
    }
    if (
      sprekkMur === 'ikke_kontrollerbart|Ikke kontrollerbart' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', -1, { shouldValidate: true });
    }
  }, [sprekkMur, totalvurderingTg, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.GrunnFundament.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="fundamentGrunnmurType"
          header={getCheckHeader(partVersion, PartTypes.GrunnFundament.id, 'fundamentGrunnmurType')}
          alternatives={[
            { value: 'plate', label: 'Plate på mark' },
            { value: 'gulv_paa_grunn', label: 'Gulv på grunn' },
            { value: 'ringmur', label: 'Ringmur' },
            { value: 'grunnmur_med_kjeller', label: 'Grunnmur m/kjeller' },
            { value: 'grunnmur_med_underetasje', label: 'Grunnmur m/underetasje' },
            { value: 'soyler', label: 'Søyler/pilarer (åpen fundamentering)' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.GrunnFundament.id,
              'fundamentGrunnmurType'
            ),
            id: 'fundamentGrunnmurType.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckMultiText
          id="typeByggegrunn"
          header={getCheckHeader(partVersion, PartTypes.GrunnFundament.id, 'typeByggegrunn')}
          alternatives={[
            { value: 'losmasse', label: 'Byggegrunn av løsmasse' },
            { value: 'leire', label: 'Byggegrunn av leirholdige masser' },
            { value: 'fjell', label: 'Byggegrunn av fjell' },
            { value: 'pukk', label: 'Byggegrunn av pukk/stein' },
            { value: 'ukjent', label: 'Ukjent byggegrunn' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.GrunnFundament.id, 'typeByggegrunn'),
            id: 'typeByggegrunn.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        {fundamentGrunnmurType &&
          (fundamentGrunnmurType?.includes('grunnmur_med_kjeller|Grunnmur m/kjeller') ||
            fundamentGrunnmurType?.includes('grunnmur_med_underetasje|Grunnmur m/underetasje')) && (
            <CheckMultiText
              id="typeKjellerGrunnmur"
              header={getCheckHeader(
                partVersion,
                PartTypes.GrunnFundament.id,
                'typeKjellerGrunnmur'
              )}
              alternatives={[
                { value: 'betong', label: 'Betong' },
                { value: 'lettklinker', label: 'Lettklinker (lecastein eller lign)' },
                { value: 'multimur', label: 'Multimur (Støpt betong med multielement)' },
                { value: 'sparesteinsmur', label: 'Betong med sparestein' },
                { value: 'grastein', label: 'Gråsteinsmur' },
                { value: 'teglstein', label: 'Teglstein' },
                { value: 'betongstein', label: 'Betongstein' },
                { value: 'espelement', label: 'Esp-element' },
                { value: 'annet', label: 'Annet' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.GrunnFundament.id,
                  'typeKjellerGrunnmur'
                ),
                id: 'typeKjellerGrunnmur.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />
          )}

        <CheckText
          id="sprekkMur"
          header={getCheckHeader(partVersion, PartTypes.GrunnFundament.id, 'sprekkMur')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <KonklusjonTiltakKostnader
          partType="GrunnFundament"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.GrunnFundament.name}
      />
    </FormProvider>
  );
};

export const GrunnFundamentSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  fundamentGrunnmurType: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  typeByggegrunn: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  typeKjellerGrunnmur: Joi.object({
    values: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.fundamentGrunnmurType.values &&
        state.fundamentGrunnmurType.values.includes('grunnmur_med_kjeller|Grunnmur m/kjeller')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  sprekkMur: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartGrunnFundamentFormData extends BasePartForm {
  fundamentGrunnmurType: MultiCheck<string>;
  typeByggegrunn: MultiCheck<string>;
  typeKjellerGrunnmur: MultiCheck<string>;
  sprekkMur: CheckSingle<string>;

  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default GrunnFundamentForm;
