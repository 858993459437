import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const TaktekkingForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartTaktekkingFormData>) => {
  const [formMethods] = usePartForm<PartTaktekkingFormData>(
    TaktekkingSchema,
    defaultValues,
    PartTypes.Taktekking.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  const alder = watch('alder.value');
  useEffect(() => {
    if (alder === 'ja|Ja' && (totalvurderingTg === undefined || totalvurderingTg === null)) {
      setValue('totalvurdering.tg', 2);
    }
  }, [alder, totalvurderingTg, setValue]);

  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Taktekking.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="typeTekking"
          header={getCheckHeader(1, PartTypes.Taktekking.id, 'typeTekking')}
          alternatives={[
            { value: 'takstein', label: 'Takstein' },
            { value: 'lakkerte_staalplater', label: 'Lakkerte stålplater' },
            { value: 'papp', label: 'Papp' },
            { value: 'pappshingel', label: 'Pappshingel' },
            { value: 'betongstein', label: 'Betongstein' },
            { value: 'eternittplater', label: 'Eternittplater' },
            { value: 'skiferstein', label: 'Skiferstein' },
            { value: 'imiterte_staalplater', label: 'Metallplater' },
            { value: 'tegltakstein', label: 'Tegltakstein' },
            { value: 'bandtekking', label: 'Båndtekking' },
            { value: 'tretak', label: 'Tretak' },
            { value: 'torv', label: 'Torv' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckMultiText
          id="inspisertFra"
          header={getCheckHeader(3, PartTypes.Taktekking.id, 'inspisertFra')}
          alternatives={[
            { value: 'stige', label: 'Via stige' },
            { value: 'tak', label: 'På tak' },
            { value: 'fra_bakken', label: 'Fra bakken' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(3, PartTypes.Taktekking.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckText
          id="skaderTakstein"
          header={getCheckHeader(3, PartTypes.Taktekking.id, 'skaderTakstein')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckText
          id="avvikBeslagPipe"
          header={getCheckHeader(3, PartTypes.Taktekking.id, 'avvikBeslagPipe')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckText
          id="alder"
          header={getCheckHeader(3, PartTypes.Taktekking.id, 'alder')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(3, PartTypes.Taktekking.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(3, PartTypes.Taktekking.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(3, PartTypes.Taktekking.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Taktekking.name}
      />
    </FormProvider>
  );
};

export const TaktekkingSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeTekking: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  inspisertFra: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderTakstein: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikBeslagPipe: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartTaktekkingFormData extends BasePartForm {
  typeTekking: MultiCheck<string>;
  inspisertFra: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  skaderTakstein: CheckSingle<string>;
  avvikBeslagPipe: CheckSingle<string>;
  alder: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default TaktekkingForm;
