import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const VannbarenVarmeForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVannbarenVarmeFormData>) => {
  const [formMethods] = usePartForm<PartVannbarenVarmeFormData>(
    VannbarenVarmeSchema,
    defaultValues,
    PartTypes.VannbarenVarme.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAnlegg = watch('typeAnlegg.value');
  useEffect(() => {
    if (
      typeAnlegg !== 'gulvvarme|Gulvvarme' &&
      typeAnlegg !== 'kombinasjon|Kombinasjon med radiatorer og gulvvarme'
    ) {
      setValue('sprekkerSvellinger.value', null, { shouldValidate: true });
    }
  }, [typeAnlegg, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.VannbarenVarme.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckText
          id="typeAnlegg"
          header={getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'typeAnlegg')}
          alternatives={[
            { value: 'radiatorer', label: 'Radiatorer' },
            { value: 'gulvvarme', label: 'Gulvvarme' },
            { value: 'kombinasjon', label: 'Kombinasjon med radiatorer og gulvvarme' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'typeAnlegg'),
            id: 'typeAnlegg.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidEtterByggear"
          header={getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'arbeidEtterByggear')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'arbeidEtterByggear'),
            id: 'arbeidEtterByggear.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="alder"
          header={getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'alder')}
        />

        <CheckBoolean
          id="skaderGjennomforinger"
          header={getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'skaderGjennomforinger')}
        />

        {(typeAnlegg === 'gulvvarme|Gulvvarme' ||
          typeAnlegg === 'kombinasjon|Kombinasjon med radiatorer og gulvvarme') && (
          <CheckBoolean
            id="sprekkerSvellinger"
            header={getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'sprekkerSvellinger')}
          />
        )}

        <CheckBoolean
          id="lekkasjeKorrosjon"
          header={getCheckHeader(partVersion, PartTypes.VannbarenVarme.id, 'lekkasjeKorrosjon')}
        />

        <KonklusjonTiltakKostnader
          partType="VannbarenVarme"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.VannbarenVarme.name}
      />
    </FormProvider>
  );
};

export const VannbarenVarmeSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidEtterByggear: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderGjennomforinger: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  sprekkerSvellinger: Joi.object({
    value: Joi.when(Joi.ref('/typeAnlegg.value'), {
      is: 'gulvvarme|Gulvvarme',
      then: Joi.boolean().required(),
    }).concat(
      Joi.when(Joi.ref('/typeAnlegg.value'), {
        is: 'kombinasjon|Kombinasjon med radiatorer og gulvvarme',
        then: Joi.boolean().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjeKorrosjon: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVannbarenVarmeFormData extends BasePartForm {
  typeAnlegg: CheckSingle<string>;
  arbeidEtterByggear: CheckSingle<string>;
  alder: CheckSingle<boolean>;
  skaderGjennomforinger: CheckSingle<boolean>;
  sprekkerSvellinger: CheckSingle<boolean>;
  lekkasjeKorrosjon: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VannbarenVarmeForm;
