import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const TakkonstruksjonForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartTakkonstruksjonFormData>) => {
  const [formMethods] = usePartForm<PartTakkonstruksjonFormData>(
    TakkonstruksjonSchema,
    defaultValues,
    PartTypes.Takkonstruksjon.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeKonstruksjon = watch('typeKonstruksjon.values');
  useEffect(() => {
    if (!typeKonstruksjon || !typeKonstruksjon.includes('flatt|Flatt tak')) {
      setValue('fallTilTakSluk.value', null);
      setValue('fallTilTakSluk.comment', null);
      setValue('overlop.value', null);
      setValue('overlop.comment', null);
    }
    if (
      !typeKonstruksjon ||
      (!typeKonstruksjon.includes('saltak|Saltak') &&
        !typeKonstruksjon.includes('valmet|Valmet tak') &&
        !typeKonstruksjon.includes('pulttak|Pulttak'))
    ) {
      setValue('tilstrekkeligLufting.value', null);
      setValue('tilstrekkeligLufting.comment', null);
    }
  }, [typeKonstruksjon, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Takkonstruksjon.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="typeKonstruksjon"
          header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'typeKonstruksjon')}
          alternatives={[
            { value: 'saltak', label: 'Saltak' },
            { value: 'flatt', label: 'Flatt tak' },
            { value: 'valmet', label: 'Valmet tak' },
            { value: 'pulttak', label: 'Pulttak' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckMultiText
          id="inspisertFra"
          header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'inspisertFra')}
          alternatives={[
            { value: 'stige', label: 'Via stige' },
            { value: 'tak', label: 'På tak' },
            { value: 'fra_bakken', label: 'Fra bakken' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        {typeKonstruksjon && typeKonstruksjon.includes('flatt|Flatt tak') && (
          <>
            <CheckText
              id="fallTilTakSluk"
              header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'fallTilTakSluk')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="overlop"
              header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'overlop')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        <CheckText
          id="konstruksjonsvikt"
          header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'konstruksjonsvikt')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        {typeKonstruksjon &&
          (typeKonstruksjon.includes('saltak|Saltak') ||
            typeKonstruksjon.includes('valmet|Valmet tak') ||
            typeKonstruksjon.includes('pulttak|Pulttak')) && (
            <CheckText
              id="tilstrekkeligLufting"
              header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'tilstrekkeligLufting')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          )}

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Takkonstruksjon.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Takkonstruksjon.name}
      />
    </FormProvider>
  );
};

export const TakkonstruksjonSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeKonstruksjon: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  inspisertFra: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  fallTilTakSluk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        state.typeKonstruksjon.values &&
        state.typeKonstruksjon.values.includes('flatt|Flatt tak')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        (state.fallTilTakSluk.value === 'ja|Ja' ||
          state.fallTilTakSluk.value === 'ikke_kontrollerbart|Ikke kontrollerbart')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  overlop: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        state.typeKonstruksjon.values &&
        state.typeKonstruksjon.values.includes('flatt|Flatt tak')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        (state.overlop.value === 'ja|Ja' ||
          state.overlop.value === 'ikke_kontrollerbart|Ikke kontrollerbart')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  konstruksjonsvikt: Joi.object({
    value: Joi.string().required(),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        (state.konstruksjonsvikt.value === 'ja|Ja' ||
          state.konstruksjonsvikt.value === 'ikke_kontrollerbart|Ikke kontrollerbart')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  tilstrekkeligLufting: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        state.typeKonstruksjon.values &&
        (state.typeKonstruksjon.values.includes('saltak|Saltak') ||
          state.typeKonstruksjon.values.includes('valmet|Valmet tak') ||
          state.typeKonstruksjon.values.includes('pulttak|Pulttak'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        (state.tilstrekkeligLufting.value === 'ja|Ja' ||
          state.tilstrekkeligLufting.value === 'ikke_kontrollerbart|Ikke kontrollerbart')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartTakkonstruksjonFormData extends BasePartForm {
  typeKonstruksjon: MultiCheck<string>;
  inspisertFra: MultiCheck<string>;
  fallTilTakSluk: CheckSingle<string>;
  overlop: CheckSingle<string>;
  konstruksjonsvikt: CheckSingle<string>;
  tilstrekkeligLufting: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default TakkonstruksjonForm;
