import { default as Avlopsror, AvlopsrorSchema } from './Avlopsror/v6';
import {
  default as BalkongTerrassePlatting,
  BalkongTerrassePlattingSchema,
} from './BalkongTerrassePlatting/v6';
import { default as Drenering, DreneringSchema } from './Drenering/v6';
import { default as Elektrisk, ElektriskSchema } from '@components/Parts/Elektrisk/v6';
import { default as Etasjeskille, EtasjeskilleSchema } from '@components/Parts/Etasjeskille/v6';
import {
  default as GrunnFundament,
  GrunnFundamentSchema,
} from '@components/Parts/GrunnFundament/v6';
import {
  default as IldstedSkorstein,
  IldstedSkorsteinSchema,
} from '@components/Parts/IldstedSkorstein/v6';
import {
  default as IldstedSkorsteinInnvendig,
  IldstedSkorsteinInnvendigSchema,
} from '@components/Parts/IldstedSkorsteinInnvendig/v6';
import { default as Kjokken, KjokkenSchema } from '@components/Parts/Kjokken/v6';
import { default as Krypkjeller, KrypkjellerSchema } from '@components/Parts/Krypkjeller/v6';
import { default as Loft, LoftSchema } from '@components/Parts/Loft/v6';
import { default as Ovrig, OvrigSchema } from '@components/Parts/Ovrig/v6';
import { default as RennerNedlop, RennerNedlopSchema } from '@components/Parts/RennerNedlop/v6';
import { default as Stottemur, StottemurSchema } from '@components/Parts/Stottemur/v6';
import { default as Taktekking, TaktekkingSchema } from '@components/Parts/Taktekking/v6';
import { default as Toalettrom, ToalettromSchema } from '@components/Parts/Toalettrom/v6';
import { default as Trapp, TrappSchema } from '@components/Parts/Trapp/v6';
import { default as UnderTerreng, UnderTerrengSchema } from '@components/Parts/UnderTerreng/v6';
import { default as UtstyrTak, UtstyrTakSchema } from '@components/Parts/UtstyrTak/v6';
import { default as Vaatrom, VaatromSchema } from '@components/Parts/Vaatrom/v6';
import { default as Vannledninger, VannledningerSchema } from '@components/Parts/Vannledninger/v6';
import { default as Varmesentral, VarmesentralSchema } from '@components/Parts/Varmesentral/v6';
import { default as Ventilasjon, VentilasjonSchema } from '@components/Parts/Ventilasjon/v6';
import { default as VinduerDorer, VinduerDorerSchema } from '@components/Parts/VinduerDorer/v6';
import { default as Yttervegger, YtterveggerSchema } from '@components/Parts/Yttervegger/v6';
import {
  default as Varmtvannsbereder,
  VarmtvannsberederSchema,
} from '@components/Parts/Varmtvannsbereder/v6';
import {
  default as VannbarenVarme,
  VannbarenVarmeSchema,
} from '@components/Parts/VannbarenVarme/v6';
import {
  default as Takkonstruksjon,
  TakkonstruksjonSchema,
} from '@components/Parts/Takkonstruksjon/v6';
import { default as NotAvailable, NotAvailableSchema } from './NotAvailable/v6';
import { PartTypes } from '@supertakst/model-common';
import { withAvailabilitySchema } from '@utils/modelUtils';
import v5Parts from './v5';

const parts = {
  ...v5Parts,
  [PartTypes.Avlopsror.id]: {
    form: Avlopsror,
    schema: withAvailabilitySchema({ schema: AvlopsrorSchema, version: 6 }),
  },
  [PartTypes.BalkongTerrassePlatting.id]: {
    form: BalkongTerrassePlatting,
    schema: withAvailabilitySchema({ schema: BalkongTerrassePlattingSchema, version: 6 }),
  },
  [PartTypes.Drenering.id]: {
    form: Drenering,
    schema: withAvailabilitySchema({ schema: DreneringSchema, version: 6 }),
  },
  [PartTypes.Elektrisk.id]: {
    form: Elektrisk,
    schema: withAvailabilitySchema({ schema: ElektriskSchema, version: 6 }),
  },
  [PartTypes.Etasjeskille.id]: {
    form: Etasjeskille,
    schema: withAvailabilitySchema({ schema: EtasjeskilleSchema, version: 6 }),
  },
  [PartTypes.GrunnFundament.id]: {
    form: GrunnFundament,
    schema: withAvailabilitySchema({ schema: GrunnFundamentSchema, version: 6 }),
  },
  [PartTypes.IldstedSkorstein.id]: {
    form: IldstedSkorstein,
    schema: withAvailabilitySchema({ schema: IldstedSkorsteinSchema, version: 6 }),
  },
  [PartTypes.IldstedSkorsteinInnvendig.id]: {
    form: IldstedSkorsteinInnvendig,
    schema: withAvailabilitySchema({ schema: IldstedSkorsteinInnvendigSchema, version: 6 }),
  },
  [PartTypes.Kjokken.id]: {
    form: Kjokken,
    schema: withAvailabilitySchema({ schema: KjokkenSchema, version: 6 }),
  },
  [PartTypes.Krypkjeller.id]: {
    form: Krypkjeller,
    schema: withAvailabilitySchema({ schema: KrypkjellerSchema, version: 6 }),
  },
  [PartTypes.Loft.id]: {
    form: Loft,
    schema: withAvailabilitySchema({ schema: LoftSchema, version: 6 }),
  },
  [PartTypes.Ovrig.id]: {
    form: Ovrig,
    schema: withAvailabilitySchema({ schema: OvrigSchema, version: 6 }),
  },
  [PartTypes.RennerNedlop.id]: {
    form: RennerNedlop,
    schema: withAvailabilitySchema({ schema: RennerNedlopSchema, version: 6 }),
  },
  [PartTypes.Stottemur.id]: {
    form: Stottemur,
    schema: withAvailabilitySchema({ schema: StottemurSchema, version: 6 }),
  },
  [PartTypes.Takkonstruksjon.id]: {
    form: Takkonstruksjon,
    schema: withAvailabilitySchema({ schema: TakkonstruksjonSchema, version: 6 }),
  },
  [PartTypes.Taktekking.id]: {
    form: Taktekking,
    schema: withAvailabilitySchema({ schema: TaktekkingSchema, version: 6 }),
  },
  [PartTypes.Toalettrom.id]: {
    form: Toalettrom,
    schema: withAvailabilitySchema({ schema: ToalettromSchema, version: 6 }),
  },
  [PartTypes.Trapp.id]: {
    form: Trapp,
    schema: withAvailabilitySchema({ schema: TrappSchema, version: 6 }),
  },
  [PartTypes.UnderTerreng.id]: {
    form: UnderTerreng,
    schema: withAvailabilitySchema({ schema: UnderTerrengSchema, version: 6 }),
  },
  [PartTypes.UtstyrTak.id]: {
    form: UtstyrTak,
    schema: withAvailabilitySchema({ schema: UtstyrTakSchema, version: 6 }),
  },
  [PartTypes.Vaatrom.id]: {
    form: Vaatrom,
    schema: withAvailabilitySchema({ schema: VaatromSchema, version: 6 }),
  },
  [PartTypes.VannbarenVarme.id]: {
    form: VannbarenVarme,
    schema: withAvailabilitySchema({ schema: VannbarenVarmeSchema, version: 6 }),
  },
  [PartTypes.Vannledninger.id]: {
    form: Vannledninger,
    schema: withAvailabilitySchema({ schema: VannledningerSchema, version: 6 }),
  },
  [PartTypes.Varmesentral.id]: {
    form: Varmesentral,
    schema: withAvailabilitySchema({ schema: VarmesentralSchema, version: 6 }),
  },
  [PartTypes.Varmtvannsbereder.id]: {
    form: Varmtvannsbereder,
    schema: withAvailabilitySchema({ schema: VarmtvannsberederSchema, version: 6 }),
  },
  [PartTypes.Ventilasjon.id]: {
    form: Ventilasjon,
    schema: withAvailabilitySchema({ schema: VentilasjonSchema, version: 6 }),
  },
  [PartTypes.VinduerDorer.id]: {
    form: VinduerDorer,
    schema: withAvailabilitySchema({ schema: VinduerDorerSchema, version: 6 }),
  },
  [PartTypes.Yttervegger.id]: {
    form: Yttervegger,
    schema: withAvailabilitySchema({ schema: YtterveggerSchema, version: 6 }),
  },
  NotAvailable: {
    form: NotAvailable,
    schema: NotAvailableSchema,
  },
};

export default parts;
