import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const IldstedSkorsteinInnvendigForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartIldstedInnvendigFormData>) => {
  const [formMethods] = usePartForm<PartIldstedInnvendigFormData>(
    IldstedSkorsteinSchema,
    defaultValues,
    PartTypes.IldstedSkorsteinInnvendig.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const montertIldsted = watch('montertIldsted.value');
  const pipeType = watch('pipeType.values');
  useEffect(() => {
    if (montertIldsted !== true) {
      setValue('ildstedType.values', []);
      setValue('ildstedType.comment', null);
    }
  }, [montertIldsted, setValue]);

  useEffect(() => {
    if (!pipeType || (pipeType && !pipeType.includes('element|Element'))) {
      setValue('pipeToSiderSynlig.value', null);
      setValue('pipeToSiderSynlig.comment', null);
    }
    if (!pipeType || (pipeType && !pipeType.includes('tegl|Tegl'))) {
      setValue('pipeFireSiderSynlig.value', null);
      setValue('pipeFireSiderSynlig.comment', null);
    }
  }, [pipeType, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.IldstedSkorstein.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="pipeType"
          header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'pipeType')}
          alternatives={[
            { value: 'element', label: 'Element' },
            { value: 'tegl', label: 'Tegl' },
            { value: 'staal', label: 'Stål' },
            { value: 'avgassror', label: 'Avgassrør til gasspeis' },
          ]}
        />

        <CheckBoolean
          id="montertIldsted"
          header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'montertIldsted')}
        />

        {montertIldsted && (
          <>
            <CheckMultiText
              id="ildstedType"
              header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'ildstedType')}
              alternatives={[
                { value: 'vedovn', label: 'Vedovn' },
                { value: 'peis', label: 'Peis' },
                { value: 'kakkelovn', label: 'Kakkelovn' },
                { value: 'apen_peis', label: 'Åpen peis' },
                { value: 'gass', label: 'Gasspeis' },
              ]}
            />
          </>
        )}

        {pipeType && pipeType.includes('element|Element') && (
          <CheckBoolean
            id="pipeToSiderSynlig"
            header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'pipeToSiderSynlig')}
          />
        )}

        {pipeType && pipeType.includes('tegl|Tegl') && (
          <CheckBoolean
            id="pipeFireSiderSynlig"
            header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'pipeFireSiderSynlig')}
          />
        )}

        <CheckBoolean
          id="avstandBrennbart"
          header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'avstandBrennbart')}
        />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.IldstedSkorstein.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.IldstedSkorstein.name}
      />
    </FormProvider>
  );
};

export const IldstedSkorsteinSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  pipeType: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  montertIldsted: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  ildstedType: Joi.object({
    values: Joi.when(Joi.ref('/montertIldsted.value'), {
      is: true,
      then: Joi.array().required().min(1),
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  pipeToSiderSynlig: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.pipeType.values &&
        state.pipeType.values.includes('element|Element')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  pipeFireSiderSynlig: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.pipeType.values &&
        state.pipeType.values.includes('tegl|Tegl')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  avstandBrennbart: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartIldstedInnvendigFormData extends BasePartForm {
  pipeType: MultiCheck<string>;
  montertIldsted: CheckSingle<boolean>;
  ildstedType: MultiCheck<string>;
  pipeToSiderSynlig: CheckSingle<boolean>;
  pipeFireSiderSynlig: CheckSingle<boolean>;
  avstandBrennbart: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default IldstedSkorsteinInnvendigForm;
