import React from 'react';

type Props = {
  children: React.ReactNode;
};

const FormSectionHeader = ({ children }: Props) => {
  return (
    <div>
      <h2 className="mt-10 mb-5 text-[#AEB1CA] uppercase text-xs font-bold">{children}</h2>
    </div>
  );
};

export default FormSectionHeader;
