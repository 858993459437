import { default as Vaatrom, VaatromSchema } from './Vaatrom/v2';
import { PartTypes } from '@supertakst/model-common';
import { withAvailabilitySchema } from '@utils/modelUtils';
import v1Parts from './v1';

const parts = {
  ...v1Parts,
  [PartTypes.Vaatrom.id]: {
    form: Vaatrom,
    schema: withAvailabilitySchema({ schema: VaatromSchema, concatAvailability: false }),
  },
};

export default parts;
