import React from 'react';
import Textarea from '@components/common/atoms/Textarea';
import CheckHeader from '@components/common/CheckHeader';
import { Controller, useFormContext } from 'react-hook-form';

import MultiTextInput from '../MultiTextInput';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from '@components/common/CheckCard';
import Phrases from '@components/common/Phrases/v2';
import Tooltip from '@components/common/Tooltip';

export type Alternative = {
  value: string;
  label: string;
};

type PhraseTarget = {
  id: string;
  header: string;
  tg?: string;
};

type Props = {
  id: string;
  header: string;
  alternatives: Alternative[];
  hideComment?: boolean;
  phrasesTarget?: PhraseTarget;
};

const CheckMultiText = ({
  id,
  header,
  alternatives,
  hideComment = true,
  phrasesTarget = {
    header: 'Oppsummering',
    id: 'totalvurdering.value',
    tg: 'totalvurdering.tg',
  },
}: Props) => {
  const valuesName = `${id}.values`;
  const commentName = `${id}.comment`;
  const { control, getValues } = useFormContext();

  hideComment = hideComment === true || (getValues(valuesName)?.length ?? 0) === 0;

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <Controller
          name={valuesName}
          control={control}
          render={({ field: { onChange, onBlur, ref, name, value } }) => {
            return (
              <MultiTextInput
                onChange={onChange}
                onBlur={onBlur}
                id={name}
                inputRef={ref}
                values={value}
                alternatives={alternatives}
              />
            );
          }}
        />
        {!hideComment && (
          <>
            <Textarea className="mt-6" header="Vurdering og beskrivelse" id={commentName} />
          </>
        )}
      </CheckCardContent>
      <Phrases
        id={id}
        targetId={phrasesTarget.id}
        targetHeader={phrasesTarget.header}
        tgId={phrasesTarget.tg}
        checkHeader={header}
      />
    </CheckCardItem>
  );
};

export default CheckMultiText;
