import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const KjokkenForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartKjokkenFormData>) => {
  const [formMethods] = usePartForm<PartKjokkenFormData>(
    KjokkenSchema,
    defaultValues,
    PartTypes.Kjokken.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAvtrekk = watch('typeAvtrekk.values');
  useEffect(() => {
    if (!typeAvtrekk || !typeAvtrekk.includes('mekanisk|Mekanisk')) {
      setValue('avvikAvtrekk.value', null);
      setValue('avvikAvtrekk.comment', null);
    }
  }, [typeAvtrekk, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Kjokken.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckBoolean
          id="fuktskade"
          header={getCheckHeader(1, PartTypes.Kjokken.id, 'fuktskade')}
        />

        <CheckMultiText
          id="typeAvtrekk"
          header={getCheckHeader(1, PartTypes.Kjokken.id, 'typeAvtrekk')}
          alternatives={[
            { value: 'mekanisk', label: 'Mekanisk' },
            { value: 'naturlig', label: 'Naturlig' },
            { value: 'kullfilter', label: 'Omluftsvifte (kullfilter)' },
            { value: 'ingen', label: 'Ingen' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        {typeAvtrekk && typeAvtrekk.includes('mekanisk|Mekanisk') && (
          <CheckBoolean
            id="avvikAvtrekk"
            header={getCheckHeader(1, PartTypes.Kjokken.id, 'avvikAvtrekk')}
          />
        )}

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Kjokken.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Kjokken.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Kjokken.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Kjokken.name}
      />
    </FormProvider>
  );
};

export const KjokkenSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktskade: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAvtrekk: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikAvtrekk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        state.typeAvtrekk.values &&
        state.typeAvtrekk.values.includes('mekanisk|Mekanisk')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartKjokkenFormData extends BasePartForm {
  fuktskade: CheckSingle<boolean>;
  typeAvtrekk: MultiCheck<string>;
  avvikAvtrekk: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default KjokkenForm;
