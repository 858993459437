import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const AvlopsrorForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartAvlopsrorFormData>) => {
  const [formMethods] = usePartForm<PartAvlopsrorFormData>(
    AvlopsrorSchema,
    defaultValues,
    PartTypes.Avlopsror.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Avlopsror.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="typeAnlegg"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'typeAnlegg')}
          alternatives={[
            { value: 'plast', label: 'Plast' },
            { value: 'stopejern', label: 'Støpejern' },
            { value: 'keramikk', label: 'Keramikk' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="luftetOverTak"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'luftetOverTak')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="avrenning"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'avrenning')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="stakemulighet"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'stakemulighet')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckText
          id="alder"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'alder')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />
        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Avlopsror.id, 'totalvurdering')}
          hasTg
        />
        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
            header={getCheckHeader(1, PartTypes.Avlopsror.id, 'utbedringskostnader')}
          />
        )}
        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Avlopsror.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Avlopsror.name}
      />
    </FormProvider>
  );
};

export const AvlopsrorSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    values: Joi.array().min(1).required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  luftetOverTak: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), { is: 'ukjent|Ukjent', then: Joi.string().required() })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  avrenning: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), { is: 'ukjent|Ukjent', then: Joi.string().required() })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  stakemulighet: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), { is: 'ukjent|Ukjent', then: Joi.string().required() })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartAvlopsrorFormData extends BasePartForm {
  typeAnlegg: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  luftetOverTak: CheckSingle<string>;
  avrenning: CheckSingle<string>;
  stakemulighet: CheckSingle<string>;
  alder: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default AvlopsrorForm;
