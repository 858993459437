import React from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledButton from '@components/common/atoms/ControlledButton';
import { Alternative } from '../v1';
import FieldError from '@components/common/FieldError';
import useFieldState from '@components/hooks/useFieldState';

type Props = {
  onChange: (value: string[] | null) => void;
  onBlur: () => void;
  inputRef: any;
  values: string[] | null;
  id: string;
  alternatives: Alternative[];
};

const TextInput = ({ id, onChange, onBlur, inputRef, values = [], alternatives }: Props) => {
  const { trigger } = useFormContext();
  const [error, touched] = useFieldState(id);
  const doOnClick = (ev: React.MouseEvent<HTMLButtonElement>, newValue) => {
    ev.preventDefault();
    if (values && values.includes(newValue)) {
      onChange(values.filter((val) => val !== newValue));
    } else {
      if (values !== null) {
        onChange([...values, newValue]);
      } else {
        onChange([newValue]);
      }
    }
    trigger();
  };

  return (
    <>
      <div
        className="flex flex-wrap -ml-3 -mb-3 child:ml-3 child:mb-3"
        ref={inputRef}
        data-test-type="checkmultitext"
      >
        {alternatives.map(({ value: alternativeValue, label: alternativeLabel }) => {
          const combinedValue = `${alternativeValue}|${alternativeLabel}`;
          const alternativeKey = `${id}-${alternativeValue}|${alternativeLabel}`;
          return (
            <ControlledButton
              key={alternativeKey}
              renderAsCheckbox={true}
              onClick={(ev) => doOnClick(ev, combinedValue)}
              onBlur={onBlur}
              label={alternativeLabel}
              selected={(values && values.includes(combinedValue)) || false}
            />
          );
        })}
      </div>
      {touched && error && <FieldError error={error} />}
    </>
  );
};

export default TextInput;
