import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const GrunnFundamentForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartGrunnFundamentFormData>) => {
  const [formMethods] = usePartForm<PartGrunnFundamentFormData>(
    GrunnFundamentSchema,
    defaultValues,
    PartTypes.GrunnFundament.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const fundamentGrunnmurType = watch('fundamentGrunnmurType.values');
  useEffect(() => {
    if (
      !fundamentGrunnmurType ||
      (!fundamentGrunnmurType?.includes('grunnmur_med_kjeller|Grunnmur m/kjeller') &&
        !fundamentGrunnmurType?.includes('grunnmur_med_underetasje|Grunnmur m/underetasje'))
    ) {
      setValue('typeKjellerGrunnmur.values', []);
      setValue('typeKjellerGrunnmur.comment', null);
    }
  }, [fundamentGrunnmurType, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const sprekkMur = watch('sprekkMur.value');
  useEffect(() => {
    if (sprekkMur === 'ja|Ja' && (totalvurderingTg === undefined || totalvurderingTg === null)) {
      setValue('totalvurdering.tg', 2);
    }
    if (
      sprekkMur === 'ikke_kontrollerbart|Ikke kontrollerbart' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', -1);
    }
  }, [sprekkMur, totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.GrunnFundament.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="fundamentGrunnmurType"
          header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'fundamentGrunnmurType')}
          alternatives={[
            { value: 'plate', label: 'Plate på mark' },
            { value: 'gulv_paa_grunn', label: 'Gulv på grunn' },
            { value: 'ringmur', label: 'Ringmur' },
            { value: 'grunnmur_med_kjeller', label: 'Grunnmur m/kjeller' },
            { value: 'grunnmur_med_underetasje', label: 'Grunnmur m/underetasje' },
            { value: 'soyler', label: 'Søyler/pilarer (åpen fundamentering)' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckMultiText
          id="typeByggegrunn"
          header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'typeByggegrunn')}
          alternatives={[
            { value: 'losmasse', label: 'Byggegrunn av løsmasse' },
            { value: 'leire', label: 'Byggegrunn av leirholdige masser' },
            { value: 'fjell', label: 'Byggegrunn av fjell' },
            { value: 'pukk', label: 'Byggegrunn av pukk/stein' },
            { value: 'ukjent', label: 'Ukjent byggegrunn' },
          ]}
        />

        {fundamentGrunnmurType &&
          (fundamentGrunnmurType?.includes('grunnmur_med_kjeller|Grunnmur m/kjeller') ||
            fundamentGrunnmurType?.includes('grunnmur_med_underetasje|Grunnmur m/underetasje')) && (
            <CheckMultiText
              id="typeKjellerGrunnmur"
              header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'typeKjellerGrunnmur')}
              alternatives={[
                { value: 'betong', label: 'Betong' },
                { value: 'lettklinker', label: 'Lettklinker (lecastein eller lign)' },
                { value: 'multimur', label: 'Multimur (Støpt betong med multielement)' },
                { value: 'sparesteinsmur', label: 'Betong med sparestein' },
                { value: 'grastein', label: 'Gråsteinsmur' },
                { value: 'teglstein', label: 'Teglstein' },
                { value: 'betongstein', label: 'Betongstein' },
                { value: 'espelement', label: 'Esp-element' },
                { value: 'annet', label: 'Annet' },
              ]}
            />
          )}

        <CheckText
          id="sprekkMur"
          header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'sprekkMur')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.GrunnFundament.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.GrunnFundament.name}
      />
    </FormProvider>
  );
};

export const GrunnFundamentSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  fundamentGrunnmurType: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeByggegrunn: Joi.object({
    values: Joi.array().required().min(1),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeKjellerGrunnmur: Joi.object({
    values: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.fundamentGrunnmurType.values &&
        state.fundamentGrunnmurType.values.includes('grunnmur_med_kjeller|Grunnmur m/kjeller')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  sprekkMur: Joi.object({
    value: Joi.string().required(),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        (state.sprekkMur?.value === 'ikke_kontrollerbart|Ikke kontrollerbart' ||
          state.sprekkMur?.value === 'ja|Ja')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartGrunnFundamentFormData extends BasePartForm {
  fundamentGrunnmurType: MultiCheck<string>;
  typeByggegrunn: MultiCheck<string>;
  typeKjellerGrunnmur: MultiCheck<string>;
  sprekkMur: CheckSingle<string>;

  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default GrunnFundamentForm;
