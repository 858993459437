import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const UnderTerrengForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartUnderTerrengFormData>) => {
  const [formMethods] = usePartForm<PartUnderTerrengFormData>(
    UnderTerrengSchema,
    defaultValues,
    PartTypes.UnderTerreng.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const type = watch('type.value');
  useEffect(() => {
    if (type !== 'innredet|Innredet' && type !== 'delvis_innredet|Delvis innredet') {
      setValue('arbeidEtterByggear.value', null, { shouldValidate: true });
      setValue('hulltaking.value', null, { shouldValidate: true });
      setValue('fuktskade.value', null, { shouldValidate: true });
      setValue('ventilert.value', null, { shouldValidate: true });
    }
    if (type !== 'grovkjeller|Grovkjeller') {
      setValue('grovkjellerFuktskade.value', null, { shouldValidate: true });
    }
  }, [type, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.UnderTerreng.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckText
          id="type"
          header={getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'type')}
          alternatives={[
            { value: 'innredet', label: 'Innredet' },
            { value: 'delvis_innredet', label: 'Delvis innredet' },
            { value: 'grovkjeller', label: 'Grovkjeller' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'type'),
            id: 'type.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        {(type === 'innredet|Innredet' || type === 'delvis_innredet|Delvis innredet') && (
          <>
            <CheckText
              id="arbeidEtterByggear"
              header={getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'arbeidEtterByggear')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(
                  partVersion,
                  PartTypes.UnderTerreng.id,
                  'arbeidEtterByggear'
                ),
                id: 'arbeidEtterByggear.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />

            <CheckBoolean
              id="hulltaking"
              header={getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'hulltaking')}
            />

            <CheckText
              id="fuktskade"
              header={getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'fuktskade')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckBoolean
              id="ventilert"
              header={getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'ventilert')}
            />
          </>
        )}

        {type === 'grovkjeller|Grovkjeller' && (
          <CheckText
            id="grovkjellerFuktskade"
            header={getCheckHeader(partVersion, PartTypes.UnderTerreng.id, 'grovkjellerFuktskade')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <KonklusjonTiltakKostnader
          partType="UnderTerreng"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.UnderTerreng.name}
      />
    </FormProvider>
  );
};

export const UnderTerrengSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  type: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow(null, '').presence('optional'),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggear: Joi.object({
    value: Joi.when('/type.value', {
      is: 'innredet|Innredet',
      then: Joi.string().required(),
    }).concat(
      Joi.when('/type.value', {
        is: 'delvis_innredet|Delvis innredet',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow(null, '').presence('optional'),
  }),
  hulltaking: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        typeof value !== 'boolean' &&
        (state.type.value === 'innredet|Innredet' ||
          state.type.value === 'delvis_innredet|Delvis innredet')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktskade: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        (state.type.value === 'innredet|Innredet' ||
          state.type.value === 'delvis_innredet|Delvis innredet')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  ventilert: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        typeof value !== 'boolean' &&
        (state.type.value === 'innredet|Innredet' ||
          state.type.value === 'delvis_innredet|Delvis innredet')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  grovkjellerFuktskade: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (!value && state.type.value === 'grovkjeller|Grovkjeller') {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartUnderTerrengFormData extends BasePartForm {
  type: CheckSingle<string>;
  arbeidEtterByggear: CheckSingle<string>;
  hulltaking: CheckSingle<boolean>;
  fuktskade: CheckSingle<string>;
  ventilert: CheckSingle<boolean>;
  grovkjellerFuktskade: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default UnderTerrengForm;
