import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import SingleInput from '@components/common/SingleInput';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const VentilasjonForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVentilasjonFormData>) => {
  const [formMethods] = usePartForm<PartVentilasjonFormData>(
    VentilasjonSchema,
    defaultValues,
    PartTypes.Ventilasjon.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAnlegg = watch('typeAnlegg.value');
  useEffect(() => {
    if (typeAnlegg !== 'balansert_ventilasjon|Balansert ventilasjon') {
      setValue('sistService.value', null, { shouldValidate: true });
      setValue('fuktMuggFilter.value', null, { shouldValidate: true });
      setValue('manglendeTilluftAvtrekk.value', null, { shouldValidate: true });
      setValue('utilstrekkeligLuftutveksling.value', null, { shouldValidate: true });
    }
    if (typeAnlegg === 'naturlig_ventilasjon|Naturlig ventilasjon') {
      setValue('arbeidUtfortEtterByggear.value', null, { shouldValidate: true });
    }
  }, [typeAnlegg, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Ventilasjon.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckText
          id="typeAnlegg"
          header={getCheckHeader(partVersion, PartTypes.Ventilasjon.id, 'typeAnlegg')}
          alternatives={[
            { value: 'naturlig_ventilasjon', label: 'Naturlig ventilasjon' },
            {
              value: 'mekanisk_avtrekk',
              label: 'Mekanisk avtrekk',
            },
            { value: 'balansert_ventilasjon', label: 'Balansert ventilasjon' },
            { value: 'ingen_ventilasjon', label: 'Ingen ventilasjon' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Ventilasjon.id, 'typeAnlegg'),
            id: 'typeAnlegg.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        {typeAnlegg !== 'naturlig_ventilasjon|Naturlig ventilasjon' && (
          <CheckText
            id="arbeidUtfortEtterByggear"
            header={getCheckHeader(
              partVersion,
              PartTypes.Ventilasjon.id,
              'arbeidUtfortEtterByggear'
            )}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ukjent', label: 'Ukjent' },
            ]}
            phrasesTarget={{
              header: getCheckHeader(
                partVersion,
                PartTypes.Ventilasjon.id,
                'arbeidUtfortEtterByggear'
              ),
              id: 'arbeidUtfortEtterByggear.comment',
              tg: 'totalvurdering.tg',
            }}
            hideComment={false}
          />
        )}

        {typeAnlegg === 'balansert_ventilasjon|Balansert ventilasjon' && (
          <>
            <SingleInput
              type="text"
              id="sistService"
              header={getCheckHeader(partVersion, PartTypes.Ventilasjon.id, 'sistService')}
            />

            <CheckText
              id="fuktMuggFilter"
              header={getCheckHeader(partVersion, PartTypes.Ventilasjon.id, 'fuktMuggFilter')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="manglendeTilluftAvtrekk"
              header={getCheckHeader(
                partVersion,
                PartTypes.Ventilasjon.id,
                'manglendeTilluftAvtrekk'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="utilstrekkeligLuftutveksling"
              header={getCheckHeader(
                partVersion,
                PartTypes.Ventilasjon.id,
                'utilstrekkeligLuftutveksling'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        <KonklusjonTiltakKostnader
          partType="Ventilasjon"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Ventilasjon.name}
      />
    </FormProvider>
  );
};

export const VentilasjonSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidUtfortEtterByggear: Joi.object({
    value: Joi.when('/typeAnlegg.value', {
      not: 'naturlig_ventilasjon|Naturlig ventilasjon',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  sistService: Joi.object({
    value: Joi.when(Joi.ref('/typeAnlegg.value'), {
      is: 'balansert_ventilasjon|Balansert ventilasjon',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktMuggFilter: Joi.object({
    value: Joi.when(Joi.ref('/typeAnlegg.value'), {
      is: 'balansert_ventilasjon|Balansert ventilasjon',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeTilluftAvtrekk: Joi.object({
    value: Joi.when(Joi.ref('/typeAnlegg.value'), {
      is: 'balansert_ventilasjon|Balansert ventilasjon',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  utilstrekkeligLuftutveksling: Joi.object({
    value: Joi.when(Joi.ref('/typeAnlegg.value'), {
      is: 'balansert_ventilasjon|Balansert ventilasjon',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVentilasjonFormData extends BasePartForm {
  typeAnlegg: CheckSingle<string>;
  arbeidUtfortEtterByggear: CheckSingle<string>;
  sistService: CheckSingle<string>;
  fuktMuggFilter: CheckSingle<string>;
  manglendeTilluftAvtrekk: CheckSingle<string>;
  utilstrekkeligLuftutveksling: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VentilasjonForm;
