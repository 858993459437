import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import NameInput from '@components/common/NameInput';
import { PartImages } from '@components/common/Images';
import { validateUbedringskostnader } from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const OvrigForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartOvrigFormData>) => {
  const [formMethods] = usePartForm<PartOvrigFormData>(
    OvrigSchema,
    defaultValues,
    PartTypes.Ovrig.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  const enableTg = watch('enableTg.value');
  useEffect(() => {
    if (enableTg !== true) {
      setValue('totalvurdering.value', null, { shouldValidate: true });
      setValue('totalvurdering.tg', null, { shouldValidate: true });
      setValue('utbedringskostnader.value', null, { shouldValidate: true });
      setValue('utbedringskostnader.comment', null, { shouldValidate: true });
      setValue('anbefalteTiltak.value', null, { shouldValidate: true });
      setValue('anbefalteTiltak.comment', null, { shouldValidate: true });
    }
  }, [enableTg, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Ovrig.name} />

        <CheckBoolean
          id="enableTg"
          header={getCheckHeader(partVersion, PartTypes.Ovrig.id, 'enableTg')}
          hideComment={true}
        />

        <SingleTextarea
          id="description"
          header={getCheckHeader(partVersion, PartTypes.Ovrig.id, 'description')}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Ovrig.id, 'description'),
            id: 'description.value',
            tg: 'totalvurdering.tg',
          }}
        />

        {enableTg && (
          <KonklusjonTiltakKostnader
            konklusjonId="totalvurdering"
            anbefalteTiltakId="anbefalteTiltak"
            utbedringskostnaderId="utbedringskostnader"
            partType="Ovrig"
            partVersion={partVersion}
            showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
            showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
          />
        )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Ovrig.name}
      />
    </FormProvider>
  );
};

export const OvrigSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  enableTg: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  description: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if ((value === null || value === undefined) && state.enableTg.value === true) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.any().presence('optional'),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartOvrigFormData extends BasePartForm {
  enableTg: CheckSingle<boolean>;
  description: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default OvrigForm;
