import { PartTypes } from '@supertakst/model-common';
import { withAvailabilitySchema } from '@utils/modelUtils';

import v2Parts from './v2';
import { default as Taktekking, TaktekkingSchema } from './Taktekking/v3';
import { default as UtstyrTak, UtstyrTakSchema } from './UtstyrTak/v3';
import { default as Varmtvannsbereder, VarmtvannsberederSchema } from './Varmtvannsbereder/v3';
import {
  default as IldstedSkorsteinInnvendig,
  IldstedSkorsteinSchema,
} from './IldstedSkorsteinInnvendig/v3';

const parts = {
  ...v2Parts,
  [PartTypes.Taktekking.id]: {
    form: Taktekking,
    schema: withAvailabilitySchema({ schema: TaktekkingSchema }),
  },
  [PartTypes.UtstyrTak.id]: {
    form: UtstyrTak,
    schema: withAvailabilitySchema({ schema: UtstyrTakSchema }),
  },
  [PartTypes.Varmtvannsbereder.id]: {
    form: Varmtvannsbereder,
    schema: withAvailabilitySchema({ schema: VarmtvannsberederSchema }),
  },
  [PartTypes.IldstedSkorsteinInnvendig.id]: {
    form: IldstedSkorsteinInnvendig,
    schema: withAvailabilitySchema({ schema: IldstedSkorsteinSchema }),
  },
};

export default parts;
