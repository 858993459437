import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';

const DreneringForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartDreneringFormData>) => {
  const [formMethods] = usePartForm<PartDreneringFormData>(
    DreneringSchema,
    defaultValues,
    PartTypes.Drenering.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeGrunnmur = watch('typeGrunnmur.values');
  useEffect(() => {
    if (!typeGrunnmur || !typeGrunnmur?.includes('grunnmur_ringmur|Grunnmur/ringmur')) {
      setValue('arbeidEtterByggeaar.value', null, { shouldValidate: true });
      setValue('oppgradert.value', null, { shouldValidate: true });
      setValue('synligGrunnmurplastOgTopplist.value', null, { shouldValidate: true });
      setValue('alder.value', null, { shouldValidate: true });
      setValue('funksjonsvikt.value', null, { shouldValidate: true });
    }
  }, [typeGrunnmur, setValue]);

  const arbeidEtterByggeaar = watch('arbeidEtterByggeaar.value');
  useEffect(() => {
    if (arbeidEtterByggeaar !== 'ja|Ja') {
      setValue('oppgradert.value', null, { shouldValidate: true });
    }
  }, [arbeidEtterByggeaar, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  const synligGrunnmurplastOgTopplist = watch('synligGrunnmurplastOgTopplist.value');
  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      synligGrunnmurplastOgTopplist === 'ja|Ja' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', 2, { shouldValidate: true });
    }
  }, [typeGrunnmur, synligGrunnmurplastOgTopplist, totalvurderingTg, setValue]);

  const terrengfallFraGrunnmur = watch('terrengfallFraGrunnmur.value');
  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      terrengfallFraGrunnmur === 'ja|Ja' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', 2, { shouldValidate: true });
    }
  }, [typeGrunnmur, terrengfallFraGrunnmur, totalvurderingTg, setValue]);

  const alder = watch('alder.value');
  useEffect(() => {
    if (
      typeGrunnmur &&
      typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') &&
      alder === 'ja|Ja' &&
      (totalvurderingTg === undefined || totalvurderingTg === null)
    ) {
      setValue('totalvurdering.tg', 2, { shouldValidate: true });
    }
  }, [typeGrunnmur, alder, totalvurderingTg, setValue]);

  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Drenering.name} />
        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />
        <CheckMultiText
          id="typeGrunnmur"
          header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'typeGrunnmur')}
          alternatives={[
            {
              value: 'grunnmur_ringmur',
              label: 'Grunnmur/ringmur',
            },
            { value: 'plate', label: 'Støpt plate på mark' },
            { value: 'apen_fundamentering', label: 'Åpen fundamentering/pilarer' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Drenering.id, 'typeGrunnmur'),
            id: 'typeGrunnmur.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />
        {typeGrunnmur && typeGrunnmur.includes('grunnmur_ringmur|Grunnmur/ringmur') && (
          <>
            <CheckText
              id="arbeidEtterByggeaar"
              header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'arbeidEtterByggeaar')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Drenering.id, 'arbeidEtterByggeaar'),
                id: 'arbeidEtterByggeaar.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />

            {arbeidEtterByggeaar === 'ja|Ja' && (
              <CheckText
                id="oppgradert"
                header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'oppgradert')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ukjent', label: 'Ukjent' },
                ]}
              />
            )}

            <CheckText
              id="synligGrunnmurplastOgTopplist"
              header={getCheckHeader(
                partVersion,
                PartTypes.Drenering.id,
                'synligGrunnmurplastOgTopplist'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="alder"
              header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'alder')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            <CheckText
              id="funksjonsvikt"
              header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'funksjonsvikt')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}
        <CheckText
          id="terrengfallFraGrunnmur"
          header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'terrengfallFraGrunnmur')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />
        <CheckText
          id="vannledingFraHusMangelfull"
          header={getCheckHeader(partVersion, PartTypes.Drenering.id, 'vannledingFraHusMangelfull')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />
        <KonklusjonTiltakKostnader
          partType="Drenering"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Drenering.name}
      />
    </FormProvider>
  );
};

export const DreneringSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  etablertDrenering: Joi.any().optional(), // TODO remove this when testing is done. This validation is included only to support older tilstandsrapporter while testing.
  typeGrunnmur: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }).unknown(true),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.string().allow('', null).presence('optional'),
    touched: Joi.boolean().presence('optional'),
  }),
  oppgradert: Joi.object({
    value: Joi.when(Joi.ref('/arbeidEtterByggeaar.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  synligGrunnmurplastOgTopplist: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  funksjonsvikt: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.typeGrunnmur.values &&
        state.typeGrunnmur.values.includes('grunnmur_ringmur|Grunnmur/ringmur')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  terrengfallFraGrunnmur: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  vannledingFraHusMangelfull: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartDreneringFormData extends BasePartForm {
  typeGrunnmur: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  oppgradert: CheckSingle<string>;
  synligGrunnmurplastOgTopplist: CheckSingle<string>;
  terrengfallFraGrunnmur: CheckSingle<string>;
  vannledingFraHusMangelfull: CheckSingle<string>;
  alder: CheckSingle<string>;
  funksjonsvikt: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default DreneringForm;
