import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const TakkonstruksjonForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartTakkonstruksjonFormData>) => {
  const [formMethods] = usePartForm<PartTakkonstruksjonFormData>(
    TakkonstruksjonSchema,
    defaultValues,
    PartTypes.Takkonstruksjon.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeKonstruksjon = watch('typeKonstruksjon.values');
  useEffect(() => {
    if (!typeKonstruksjon || !typeKonstruksjon.includes('flatt|Flatt tak')) {
      setValue('fallTilTakSluk.value', null, { shouldValidate: true });
      setValue('overlop.value', null, { shouldValidate: true });
    }
    if (
      !typeKonstruksjon ||
      (!typeKonstruksjon.includes('saltak|Saltak') &&
        !typeKonstruksjon.includes('valmet|Valmet tak') &&
        !typeKonstruksjon.includes('pulttak|Pulttak'))
    ) {
      setValue('tilstrekkeligLufting.value', null, { shouldValidate: true });
    }
  }, [typeKonstruksjon, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Takkonstruksjon.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="typeKonstruksjon"
          header={getCheckHeader(partVersion, PartTypes.Takkonstruksjon.id, 'typeKonstruksjon')}
          alternatives={[
            { value: 'saltak', label: 'Saltak' },
            { value: 'flatt', label: 'Flatt tak' },
            { value: 'valmet', label: 'Valmet tak' },
            { value: 'pulttak', label: 'Pulttak' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Takkonstruksjon.id, 'typeKonstruksjon'),
            id: 'typeKonstruksjon.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckMultiText
          id="inspisertFra"
          header={getCheckHeader(partVersion, PartTypes.Takkonstruksjon.id, 'inspisertFra')}
          alternatives={[
            { value: 'stige', label: 'Via stige' },
            { value: 'tak', label: 'På tak' },
            { value: 'fra_bakken', label: 'Fra bakken' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        {typeKonstruksjon && typeKonstruksjon.includes('flatt|Flatt tak') && (
          <>
            <CheckText
              id="fallTilTakSluk"
              header={getCheckHeader(partVersion, PartTypes.Takkonstruksjon.id, 'fallTilTakSluk')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="overlop"
              header={getCheckHeader(partVersion, PartTypes.Takkonstruksjon.id, 'overlop')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        <CheckText
          id="konstruksjonsvikt"
          header={getCheckHeader(partVersion, PartTypes.Takkonstruksjon.id, 'konstruksjonsvikt')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        {typeKonstruksjon &&
          (typeKonstruksjon.includes('saltak|Saltak') ||
            typeKonstruksjon.includes('valmet|Valmet tak') ||
            typeKonstruksjon.includes('pulttak|Pulttak')) && (
            <CheckText
              id="tilstrekkeligLufting"
              header={getCheckHeader(
                partVersion,
                PartTypes.Takkonstruksjon.id,
                'tilstrekkeligLufting'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          )}

        <KonklusjonTiltakKostnader
          partType="Takkonstruksjon"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Takkonstruksjon.name}
      />
    </FormProvider>
  );
};

export const TakkonstruksjonSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeKonstruksjon: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  inspisertFra: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
  }),
  fallTilTakSluk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        state.typeKonstruksjon.values &&
        state.typeKonstruksjon.values.includes('flatt|Flatt tak')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  overlop: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        state.typeKonstruksjon.values &&
        state.typeKonstruksjon.values.includes('flatt|Flatt tak')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  konstruksjonsvikt: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  tilstrekkeligLufting: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        !value &&
        state.typeKonstruksjon.values &&
        (state.typeKonstruksjon.values.includes('saltak|Saltak') ||
          state.typeKonstruksjon.values.includes('valmet|Valmet tak') ||
          state.typeKonstruksjon.values.includes('pulttak|Pulttak'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartTakkonstruksjonFormData extends BasePartForm {
  typeKonstruksjon: MultiCheck<string>;
  inspisertFra: MultiCheck<string>;
  fallTilTakSluk: CheckSingle<string>;
  overlop: CheckSingle<string>;
  konstruksjonsvikt: CheckSingle<string>;
  tilstrekkeligLufting: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default TakkonstruksjonForm;
