import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const IldstedSkorsteinForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartIldstedFormData>) => {
  const [formMethods] = usePartForm<PartIldstedFormData>(
    IldstedSkorsteinSchema,
    defaultValues,
    PartTypes.IldstedSkorstein.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const {
    formState: { errors },
    trigger,
    watch,
    setValue,
    getValues,
  } = formMethods;

  console.log('errors', errors);

  const montertIldsted = watch('montertIldsted.value');
  const pipeType = watch('pipeType.values');
  useEffect(() => {
    if (montertIldsted !== true) {
      setValue('ildstedType.values', [], { shouldValidate: true });
    }
  }, [montertIldsted, setValue]);

  useEffect(() => {
    if (!pipeType || (pipeType && !pipeType.includes('element|Element'))) {
      setValue('pipeToSiderSynlig.value', null, { shouldValidate: true });
    }
    if (!pipeType || (pipeType && !pipeType.includes('tegl|Tegl'))) {
      setValue('pipeFireSiderSynlig.value', null, { shouldValidate: true });
    }
  }, [pipeType, setValue]);

  const skorsteinInspisertFra = watch('skorsteinInspisertFra.value');
  useEffect(() => {
    if (skorsteinInspisertFra === 'ikke_kontrollerbart|Ikke kontrollerbart') {
      setValue('skorsteinSynligSkader.value', null, { shouldValidate: true });
      setValue('skorsteinHoyde.value', null, { shouldValidate: true });
    }
  }, [skorsteinInspisertFra]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.IldstedSkorstein.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="pipeType"
          header={getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'pipeType')}
          alternatives={[
            { value: 'element', label: 'Element' },
            { value: 'tegl', label: 'Tegl' },
            { value: 'staal', label: 'Stål' },
            { value: 'avgassror', label: 'Avgassrør til gasspeis' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'pipeType'),
            id: 'pipeType.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="montertIldsted"
          header={getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'montertIldsted')}
        />

        {montertIldsted && (
          <>
            <CheckMultiText
              id="ildstedType"
              header={getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'ildstedType')}
              alternatives={[
                { value: 'vedovn', label: 'Vedovn' },
                { value: 'peis', label: 'Peis' },
                { value: 'kakkelovn', label: 'Kakkelovn' },
                { value: 'apen_peis', label: 'Åpen peis' },
                { value: 'gass', label: 'Gasspeis' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'ildstedType'),
                id: 'ildstedType.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />
          </>
        )}

        {pipeType && pipeType.includes('element|Element') && (
          <CheckBoolean
            id="pipeToSiderSynlig"
            header={getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'pipeToSiderSynlig')}
          />
        )}

        {pipeType && pipeType.includes('tegl|Tegl') && (
          <CheckBoolean
            id="pipeFireSiderSynlig"
            header={getCheckHeader(
              partVersion,
              PartTypes.IldstedSkorstein.id,
              'pipeFireSiderSynlig'
            )}
          />
        )}

        <CheckBoolean
          id="avstandBrennbart"
          header={getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'avstandBrennbart')}
        />

        <CheckText
          id="skorsteinInspisertFra"
          header={getCheckHeader(
            partVersion,
            PartTypes.IldstedSkorstein.id,
            'skorsteinInspisertFra'
          )}
          alternatives={[
            { value: 'fra_bakken', label: 'Fra bakken' },
            { value: 'fra_taket', label: 'Fra taket' },
            { value: 'drone', label: 'Drone' },
            { value: 'fra_stige', label: 'Fra stige' },
            { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        {skorsteinInspisertFra !== 'ikke_kontrollerbart|Ikke kontrollerbart' && (
          <>
            <CheckText
              id="skorsteinSynligSkader"
              header={getCheckHeader(
                partVersion,
                PartTypes.IldstedSkorstein.id,
                'skorsteinSynligSkader'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="skorsteinHoyde"
              header={getCheckHeader(partVersion, PartTypes.IldstedSkorstein.id, 'skorsteinHoyde')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        <KonklusjonTiltakKostnader
          partType="IldstedSkorstein"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.IldstedSkorstein.name}
      />
    </FormProvider>
  );
};

export const IldstedSkorsteinSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  pipeType: Joi.object({
    values: Joi.array().required().min(1),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  montertIldsted: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  ildstedType: Joi.object({
    values: Joi.when(Joi.ref('/montertIldsted.value'), {
      is: true,
      then: Joi.array().required().min(1),
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  pipeToSiderSynlig: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.pipeType.values &&
        state.pipeType.values.includes('element|Element')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  pipeFireSiderSynlig: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (value === null || value.length === 0) &&
        state.pipeType.values &&
        state.pipeType.values.includes('tegl|Tegl')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  avstandBrennbart: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  skorsteinInspisertFra: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  skorsteinSynligSkader: Joi.object({
    value: Joi.when('/skorsteinInspisertFra.value', {
      not: 'ikke_kontrollerbart|Ikke kontrollerbart',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  skorsteinHoyde: Joi.object({
    value: Joi.when('/skorsteinInspisertFra.value', {
      not: 'ikke_kontrollerbart|Ikke kontrollerbart',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartIldstedFormData extends BasePartForm {
  pipeType: MultiCheck<string>;
  montertIldsted: CheckSingle<boolean>;
  ildstedType: MultiCheck<string>;
  pipeToSiderSynlig: CheckSingle<boolean>;
  pipeFireSiderSynlig: CheckSingle<boolean>;
  avstandBrennbart: CheckSingle<boolean>;
  skorsteinInspisertFra: CheckSingle<string>;
  skorsteinSynligSkader: CheckSingle<string>;
  skorsteinHoyde: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default IldstedSkorsteinForm;
