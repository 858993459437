import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const KrypkjellerForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartKrypkjellerFormData>) => {
  const [formMethods] = usePartForm<PartKrypkjellerFormData>(
    KrypkjellerSchema,
    defaultValues,
    PartTypes.Krypkjeller.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Krypkjeller.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <SingleTextarea
          id="beskrivelse"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'beskrivelse')}
        />

        <CheckBoolean
          id="fuktsikringMotGrunnen"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'fuktsikringMotGrunnen')}
        />

        <CheckBoolean
          id="synligeFuktVannSkader"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'synligeFuktVannSkader')}
        />

        <CheckBoolean
          id="soppskader"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'soppskader')}
        />

        <CheckBoolean
          id="sviktGulv"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'sviktGulv')}
        />

        <CheckBoolean
          id="mangelfullVentilering"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'mangelfullVentilering')}
        />

        <SingleTextarea
          id="resultatFuktmaling"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'resultatFuktmaling')}
        />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Krypkjeller.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Krypkjeller.name}
      />
    </FormProvider>
  );
};

export const KrypkjellerSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  beskrivelse: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktsikringMotGrunnen: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  synligeFuktVannSkader: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  soppskader: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  sviktGulv: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  mangelfullVentilering: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  resultatFuktmaling: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartKrypkjellerFormData extends BasePartForm {
  beskrivelse: CheckSingle<string>;
  fuktsikringMotGrunnen: CheckSingle<boolean>;
  synligeFuktVannSkader: CheckSingle<boolean>;
  soppskader: CheckSingle<boolean>;
  sviktGulv: CheckSingle<boolean>;
  mangelfullVentilering: CheckSingle<boolean>;
  resultatFuktmaling: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default KrypkjellerForm;
