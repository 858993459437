import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import FormSectionHeader from '@components/common/FormSectionHeader';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const VaatromForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartVaatromFormData>) => {
  const [formMethods] = usePartForm<PartVaatromFormData>(
    VaatromSchema,
    defaultValues,
    PartTypes.Vaatrom.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const slakt = watch('slakt.value');
  useEffect(() => {
    if (slakt) {
      setValue('arbeidEtterByggeaar.value', null);
      setValue('arbeidEtterByggeaar.comment', null);
      setValue('overflate.value', null);
      setValue('tilstrekkeligFallTilSluk.value', null);
      setValue('tilstrekkeligFallTilSluk.comment', null);
      setValue('avvikHoydeforskjell.value', null);
      setValue('avvikHoydeforskjell.comment', null);
      setValue('lekkasjevannTilSluk.value', null);
      setValue('lekkasjevannTilSluk.comment', null);
      setValue('vinduDorVatsone.value', null);
      setValue('vinduDorVatsone.comment', null);
      setValue('vinduDorFuktbestandig.value', null);
      setValue('vinduDorFuktbestandig.comment', null);
      setValue('gjennomforingerKaldSone.value', null);
      setValue('gjennomforingerKaldSone.comment', null);
      setValue('punktertDiffusjonssperre.value', null);
      setValue('punktertDiffusjonssperre.comment', null);
      setValue('sprekkerFlis.value', null);
      setValue('sprekkerFlis.comment', null);
      setValue('biologiskeSkadegjorere.value', null);
      setValue('biologiskeSkadegjorere.comment', null);
      setValue('knirkGulv.value', null);
      setValue('knirkGulv.comment', null);
      setValue('overflateTotalvurdering.value', null);
      setValue('overflateTotalvurdering.tg', null);
      setValue('overflateUtbedringskostnader.value', null);
      setValue('overflateUtbedringskostnader.comment', null);
      setValue('overflateAnbefalteTiltak.value', null);
      setValue('overflateAnbefalteTiltak.comment', null);
      setValue('slukTilgjengeligInspeksjon.value', null);
      setValue('slukTilgjengeligInspeksjon.comment', null);
      setValue('slukType.values', []);
      setValue('slukType.comment', null);
      setValue('ufagmessigUtforelse.value', null);
      setValue('ufagmessigUtforelse.comment', null);
      setValue('rorgjennomforingerVatSone.value', null);
      setValue('rorgjennomforingerVatSone.comment', null);
      setValue('membranFeilUtforelse.value', null);
      setValue('membranFeilUtforelse.comment', null);
      setValue('overHalvpartBrukstid.value', null);
      setValue('overHalvpartBrukstid.comment', null);
      setValue('membranTotalvurdering.value', null);
      setValue('membranTotalvurdering.tg', null);
      setValue('membranUtbedringskostnader.value', null);
      setValue('membranUtbedringskostnader.comment', null);
      setValue('membranAnbefalteTiltak.value', null);
      setValue('membranAnbefalteTiltak.comment', null);
      setValue('sanitarBeskrivelse.value', null);
      setValue('typeVentilering.value', null);
      setValue('typeVentilering.comment', null);
      setValue('ventilasjonFunksjonstestet.value', null);
      setValue('ventilasjonFunksjonstestet.comment', null);
      setValue('skaderSanitar.value', null);
      setValue('skaderSanitar.comment', null);
      setValue('innebyggetSisterne.value', null);
      setValue('innebyggetSisterne.comment', null);
      setValue('manglendeDreneringSisterne.value', null);
      setValue('manglendeDreneringSisterne.comment', null);
      setValue('sanitarTotalvurdering.value', null);
      setValue('sanitarTotalvurdering.tg', null);
      setValue('sanitarUtbedringskostnader.value', null);
      setValue('sanitarUtbedringskostnader.comment', null);
      setValue('sanitarAnbefalteTiltak.value', null);
      setValue('sanitarAnbefalteTiltak.comment', null);
      setValue('dokumentasjon.value', null);
      setValue('dokumentasjon.comment', null);

      setValue('slaktTotalvurdering.tg', 3);
    } else {
      setValue('slaktTotalvurdering.value', null);
      setValue('slaktTotalvurdering.tg', null);
      setValue('slaktUtbedringskostnader.value', null);
      setValue('slaktUtbedringskostnader.comment', null);
      setValue('slaktAnbefalteTiltak.value', null);
      setValue('slaktAnbefalteTiltak.comment', null);
    }
  }, [slakt, setValue]);

  const overflateTotalvurderingTg = watch('overflateTotalvurdering.tg');
  useEffect(() => {
    if (overflateTotalvurderingTg !== 3) {
      setValue('overflateUtbedringskostnader.value', null);
      setValue('overflateUtbedringskostnader.comment', null);
    }
    if (
      overflateTotalvurderingTg === null ||
      overflateTotalvurderingTg === 0 ||
      overflateTotalvurderingTg === 1
    ) {
      setValue('overflateAnbefalteTiltak.value', null);
      setValue('overflateAnbefalteTiltak.comment', null);
    }
  }, [overflateTotalvurderingTg, setValue]);

  const vinduDorVatsone = watch('vinduDorVatsone.value');
  useEffect(() => {
    if (vinduDorVatsone !== true) {
      setValue('vinduDorFuktbestandig.value', null);
      setValue('vinduDorFuktbestandig.comment', null);
    }
  }, [vinduDorVatsone, setValue]);

  const gjennomforingerKaldSone = watch('gjennomforingerKaldSone.value');
  useEffect(() => {
    if (gjennomforingerKaldSone !== true) {
      setValue('punktertDiffusjonssperre.value', null);
      setValue('punktertDiffusjonssperre.comment', null);
    }
  }, [gjennomforingerKaldSone, setValue]);

  const slukTilgjengeligInspeksjon = watch('slukTilgjengeligInspeksjon.value');
  useEffect(() => {
    if (slukTilgjengeligInspeksjon !== false) {
      setValue('slukType.values', []);
      setValue('slukType.comment', null);
      setValue('ufagmessigUtforelse.value', null);
      setValue('ufagmessigUtforelse.comment', null);
    }
  }, [slukTilgjengeligInspeksjon, setValue]);

  const membranTotalvurderingTg = watch('membranTotalvurdering.tg');
  useEffect(() => {
    if (membranTotalvurderingTg !== 3) {
      setValue('membranUtbedringskostnader.value', null);
      setValue('membranUtbedringskostnader.comment', null);
    }
    if (
      membranTotalvurderingTg === null ||
      membranTotalvurderingTg === 0 ||
      membranTotalvurderingTg === 1
    ) {
      setValue('membranAnbefalteTiltak.value', null);
      setValue('membranAnbefalteTiltak.comment', null);
    }
  }, [membranTotalvurderingTg, setValue]);

  const typeVentilering = watch('typeVentilering.value');
  useEffect(() => {
    if (typeVentilering !== 'mekanisk|Mekanisk avtrekk') {
      setValue('ventilasjonFunksjonstestet.value', null);
      setValue('ventilasjonFunksjonstestet.comment', null);
    }
  }, [typeVentilering, setValue]);

  const innebyggetSisterne = watch('innebyggetSisterne.value');
  useEffect(() => {
    if (innebyggetSisterne === false) {
      setValue('manglendeDreneringSisterne.value', null);
      setValue('manglendeDreneringSisterne.comment', null);
    }
  }, [innebyggetSisterne, setValue]);

  const sanitarTotalvurderingTg = watch('sanitarTotalvurdering.tg');
  useEffect(() => {
    if (sanitarTotalvurderingTg !== 3) {
      setValue('sanitarUtbedringskostnader.value', null);
      setValue('sanitarUtbedringskostnader.comment', null);
    }
    if (
      sanitarTotalvurderingTg === null ||
      sanitarTotalvurderingTg === 0 ||
      sanitarTotalvurderingTg === 1
    ) {
      setValue('sanitarAnbefalteTiltak.value', null);
      setValue('sanitarAnbefalteTiltak.comment', null);
    }
  }, [sanitarTotalvurderingTg, setValue]);

  const hulltaking = watch('hulltaking.value');
  useEffect(() => {
    if (hulltaking !== true) {
      setValue('registrertFuktVedHulltaking.value', null);
      setValue('registrertFuktVedHulltaking.comment', null);
    }
    if (hulltaking !== false) {
      setValue('fuktSok.value', null);
      setValue('fuktSok.comment', null);
    }
  }, [hulltaking, setValue]);

  const fuktTotalvurderingTg = watch('fuktTotalvurdering.tg');
  useEffect(() => {
    if (fuktTotalvurderingTg !== 3) {
      setValue('fuktUtbedringskostnader.value', null);
      setValue('fuktUtbedringskostnader.comment', null);
    }
    if (fuktTotalvurderingTg === null || fuktTotalvurderingTg === 0 || fuktTotalvurderingTg === 1) {
      setValue('fuktAnbefalteTiltak.value', null);
      setValue('fuktAnbefalteTiltak.comment', null);
    }
  }, [fuktTotalvurderingTg, setValue]);

  const fuktAnbefalteTiltak = watch('fuktAnbefalteTiltak.value');
  const membranAnbefalteTiltak = watch('membranAnbefalteTiltak.value');
  const overflateAnbefalteTiltak = watch('overflateAnbefalteTiltak.value');
  const sanitarAnbefalteTiltak = watch('sanitarAnbefalteTiltak.value');
  const slaktAnbefalteTiltak = watch('slaktAnbefalteTiltak.value');

  useEffect(() => {
    if (fuktAnbefalteTiltak === false) {
      setValue('fuktAnbefalteTiltak.comment', null);
    }
    if (membranAnbefalteTiltak === false) {
      setValue('membranAnbefalteTiltak.comment', null);
    }
    if (overflateAnbefalteTiltak === false) {
      setValue('overflateAnbefalteTiltak.comment', null);
    }
    if (sanitarAnbefalteTiltak === false) {
      setValue('sanitarAnbefalteTiltak.comment', null);
    }
    if (slaktAnbefalteTiltak === false) {
      setValue('slaktAnbefalteTiltak.comment', null);
    }
  }, [
    setValue,
    fuktAnbefalteTiltak,
    membranAnbefalteTiltak,
    overflateAnbefalteTiltak,
    sanitarAnbefalteTiltak,
    slaktAnbefalteTiltak,
  ]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Vaatrom.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckBoolean
          id="slakt"
          header={getCheckHeader(1, PartTypes.Vaatrom.id, 'slakt')}
          hideComment={true}
        />

        {!slakt && (
          <>
            <FormSectionHeader>Overflate</FormSectionHeader>

            {/*OVERFLATER*/}
            <SingleTextarea
              id="overflate"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'overflate')}
            />

            <CheckText
              id="arbeidEtterByggeaar"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'arbeidEtterByggeaar')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            <CheckBoolean
              id="tilstrekkeligFallTilSluk"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'tilstrekkeligFallTilSluk')}
            />

            <CheckBoolean
              id="avvikHoydeforskjell"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'avvikHoydeforskjell')}
            />

            <CheckBoolean
              id="lekkasjevannTilSluk"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'lekkasjevannTilSluk')}
            />

            <CheckBoolean
              id="vinduDorVatsone"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'vinduDorVatsone')}
            />

            {vinduDorVatsone && (
              <CheckText
                id="vinduDorFuktbestandig"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'vinduDorFuktbestandig')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ukjent', label: 'Ukjent' },
                ]}
              />
            )}

            <CheckBoolean
              id="gjennomforingerKaldSone"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'gjennomforingerKaldSone')}
            />

            {gjennomforingerKaldSone && (
              <CheckBoolean
                id="punktertDiffusjonssperre"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'punktertDiffusjonssperre')}
              />
            )}
            <CheckBoolean
              id="sprekkerFlis"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'sprekkerFlis')}
            />

            <CheckBoolean
              id="biologiskeSkadegjorere"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'biologiskeSkadegjorere')}
            />

            <CheckText
              id="knirkGulv"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'knirkGulv')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_aktuelt', label: 'Ikke aktuelt (gjelder ikke flislagt gulv)' },
              ]}
            />

            <SingleTextarea
              id="overflateTotalvurdering"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'overflateTotalvurdering')}
              hasTg
            />

            {overflateTotalvurderingTg === 3 && (
              <CheckText
                id="overflateUtbedringskostnader"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'overflateUtbedringskostnader')}
                alternatives={[
                  { value: '0', label: 'Ingen umiddelbar kostnad' },
                  { value: '10000', label: 'Under 10 000' },
                  { value: '10000-50000', label: '10 000 - 50 000' },
                  { value: '50000-150000', label: '50 000 - 150 000' },
                  { value: '150000-300000', label: '150 000 - 300 000' },
                  { value: '300000', label: 'Over 300 000' },
                ]}
              />
            )}

            {overflateTotalvurderingTg !== undefined &&
              overflateTotalvurderingTg !== null &&
              overflateTotalvurderingTg !== 0 &&
              overflateTotalvurderingTg !== 1 && (
                <CheckBoolean
                  id="overflateAnbefalteTiltak"
                  header={getCheckHeader(1, PartTypes.Vaatrom.id, 'overflateAnbefalteTiltak')}
                  hideComment={getValues('overflateAnbefalteTiltak.value') === false}
                />
              )}

            <FormSectionHeader>Membran, tettesjikt og sluk</FormSectionHeader>
            {/*MEMBRAN*/}
            <CheckBoolean
              id="slukTilgjengeligInspeksjon"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'slukTilgjengeligInspeksjon')}
            />

            {slukTilgjengeligInspeksjon === false && (
              <>
                <CheckMultiText
                  id="slukType"
                  header={getCheckHeader(1, PartTypes.Vaatrom.id, 'slukType')}
                  alternatives={[
                    { value: 'plast', label: 'Plast' },
                    { value: 'stopejern', label: 'Støpejern' },
                    { value: 'rustfritt_stal', label: 'Rustfritt stål' },
                    { value: 'annet', label: 'Annet' },
                  ]}
                />

                <CheckText
                  id="ufagmessigUtforelse"
                  header={getCheckHeader(1, PartTypes.Vaatrom.id, 'ufagmessigUtforelse')}
                  alternatives={[
                    { value: 'ja', label: 'Ja' },
                    { value: 'nei', label: 'Nei' },
                    { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
                  ]}
                />
              </>
            )}

            <CheckBoolean
              id="rorgjennomforingerVatSone"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'rorgjennomforingerVatSone')}
            />

            <CheckBoolean
              id="membranFeilUtforelse"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'membranFeilUtforelse')}
            />

            <CheckText
              id="overHalvpartBrukstid"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'overHalvpartBrukstid')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            <SingleTextarea
              id="membranTotalvurdering"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'membranTotalvurdering')}
              hasTg
            />

            {membranTotalvurderingTg === 3 && (
              <CheckText
                id="membranUtbedringskostnader"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'membranUtbedringskostnader')}
                alternatives={[
                  { value: '0', label: 'Ingen umiddelbar kostnad' },
                  { value: '10000', label: 'Under 10 000' },
                  { value: '10000-50000', label: '10 000 - 50 000' },
                  { value: '50000-150000', label: '50 000 - 150 000' },
                  { value: '150000-300000', label: '150 000 - 300 000' },
                  { value: '300000', label: 'Over 300 000' },
                ]}
              />
            )}

            {membranTotalvurderingTg !== undefined &&
              membranTotalvurderingTg !== null &&
              membranTotalvurderingTg !== 0 &&
              membranTotalvurderingTg !== 1 && (
                <CheckBoolean
                  id="membranAnbefalteTiltak"
                  header={getCheckHeader(1, PartTypes.Vaatrom.id, 'membranAnbefalteTiltak')}
                  hideComment={getValues('membranAnbefalteTiltak.value') === false}
                />
              )}

            <FormSectionHeader>Sanitær og ventilasjon</FormSectionHeader>
            {/*SANITAR*/}

            <SingleTextarea
              id="sanitarBeskrivelse"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'sanitarBeskrivelse')}
            />

            <CheckText
              id="typeVentilering"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'typeVentilering')}
              alternatives={[
                { value: 'mekanisk', label: 'Mekanisk avtrekk' },
                { value: 'mekanisk_balansert', label: 'Mekanisk balansert ventilasjon' },
                { value: 'naturlig', label: 'Naturlig' },
                { value: 'ingen', label: 'Ingen ventilering' },
              ]}
            />

            {typeVentilering === 'mekanisk|Mekanisk avtrekk' && (
              <CheckBoolean
                id="ventilasjonFunksjonstestet"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'ventilasjonFunksjonstestet')}
              />
            )}

            <CheckBoolean
              id="skaderSanitar"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'skaderSanitar')}
            />

            <CheckBoolean
              id="innebyggetSisterne"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'innebyggetSisterne')}
            />

            {innebyggetSisterne && (
              <CheckText
                id="manglendeDreneringSisterne"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'manglendeDreneringSisterne')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'annen_godkjent', label: 'Annen godkjent løsning' },
                ]}
              />
            )}

            <SingleTextarea
              id="sanitarTotalvurdering"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'sanitarTotalvurdering')}
              hasTg
            />

            {sanitarTotalvurderingTg === 3 && (
              <CheckText
                id="sanitarUtbedringskostnader"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'sanitarUtbedringskostnader')}
                alternatives={[
                  { value: '0', label: 'Ingen umiddelbar kostnad' },
                  { value: '10000', label: 'Under 10 000' },
                  { value: '10000-50000', label: '10 000 - 50 000' },
                  { value: '50000-150000', label: '50 000 - 150 000' },
                  { value: '150000-300000', label: '150 000 - 300 000' },
                  { value: '300000', label: 'Over 300 000' },
                ]}
              />
            )}

            {sanitarTotalvurderingTg !== undefined &&
              sanitarTotalvurderingTg !== null &&
              sanitarTotalvurderingTg !== 0 &&
              sanitarTotalvurderingTg !== 1 && (
                <CheckBoolean
                  id="sanitarAnbefalteTiltak"
                  header={getCheckHeader(1, PartTypes.Vaatrom.id, 'sanitarAnbefalteTiltak')}
                  hideComment={getValues('sanitarAnbefalteTiltak.value') === false}
                />
              )}

            <FormSectionHeader>Fuktmåling</FormSectionHeader>
            {/*FUKT*/}
            <CheckBoolean
              id="hulltaking"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'hulltaking')}
            />

            {hulltaking && (
              <CheckBoolean
                id="registrertFuktVedHulltaking"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'registrertFuktVedHulltaking')}
              />
            )}

            {hulltaking === false && (
              <CheckBoolean
                id="fuktSok"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'fuktSok')}
              />
            )}

            <SingleTextarea
              id="fuktTotalvurdering"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'fuktTotalvurdering')}
              tilstandsgrader={[-1, 0, 2, 3]}
              hasTg
            />

            {fuktTotalvurderingTg === 3 && (
              <CheckText
                id="fuktUtbedringskostnader"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'fuktUtbedringskostnader')}
                alternatives={[
                  { value: '0', label: 'Ingen umiddelbar kostnad' },
                  { value: '10000', label: 'Under 10 000' },
                  { value: '10000-50000', label: '10 000 - 50 000' },
                  { value: '50000-150000', label: '50 000 - 150 000' },
                  { value: '150000-300000', label: '150 000 - 300 000' },
                  { value: '300000', label: 'Over 300 000' },
                ]}
              />
            )}

            {fuktTotalvurderingTg !== undefined &&
              fuktTotalvurderingTg !== null &&
              fuktTotalvurderingTg !== 0 &&
              fuktTotalvurderingTg !== 1 && (
                <CheckBoolean
                  id="fuktAnbefalteTiltak"
                  header={getCheckHeader(1, PartTypes.Vaatrom.id, 'fuktAnbefalteTiltak')}
                  hideComment={getValues('fuktAnbefalteTiltak.value') === false}
                />
              )}

            <FormSectionHeader>Dokumentasjon</FormSectionHeader>
            <CheckBoolean
              id="dokumentasjon"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'dokumentasjon')}
            />
          </>
        )}
        {slakt === true && (
          <>
            <CheckBoolean
              id="hulltaking"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'hulltaking')}
            />

            {hulltaking && (
              <CheckBoolean
                id="registrertFuktVedHulltaking"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'registrertFuktVedHulltaking')}
              />
            )}

            {hulltaking === false && (
              <CheckBoolean
                id="fuktSok"
                header={getCheckHeader(1, PartTypes.Vaatrom.id, 'fuktSok')}
              />
            )}

            <SingleTextarea
              id="slaktTotalvurdering"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'slaktTotalvurdering')}
              hasTg
              tgReadonly={true}
            />

            <CheckText
              id="slaktUtbedringskostnader"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'slaktUtbedringskostnader')}
              alternatives={[
                { value: '0', label: 'Ingen umiddelbar kostnad' },
                { value: '10000', label: 'Under 10 000' },
                { value: '10000-50000', label: '10 000 - 50 000' },
                { value: '50000-150000', label: '50 000 - 150 000' },
                { value: '150000-300000', label: '150 000 - 300 000' },
                { value: '300000', label: 'Over 300 000' },
              ]}
            />

            <CheckBoolean
              id="slaktAnbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Vaatrom.id, 'slaktAnbefalteTiltak')}
              hideComment={getValues('slaktAnbefalteTiltak.value') === false}
            />
          </>
        )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Vaatrom.name}
      />
    </FormProvider>
  );
};

const CompleteSchema = Joi.object({
  availability: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  slakt: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  /* OVERFLATE */
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  overflate: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  tilstrekkeligFallTilSluk: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikHoydeforskjell: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjevannTilSluk: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  vinduDorVatsone: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  vinduDorFuktbestandig: Joi.object({
    value: Joi.when(Joi.ref('/vinduDorVatsone.value'), {
      is: true,
      then: Joi.string().required(),
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        (state.vinduDorFuktbestandig.value === 'ja|Ja' ||
          state.vinduDorFuktbestandig.value === 'ukjent|Ukjent')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  gjennomforingerKaldSone: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  punktertDiffusjonssperre: Joi.object({
    value: Joi.when(Joi.ref('/gjennomforingerKaldSone.value'), {
      is: true,
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  sprekkerFlis: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  biologiskeSkadegjorere: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  knirkGulv: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  overflateTotalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }).concat(
      Joi.when('..tg', {
        is: 2,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  overflateUtbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/overflateTotalvurdering.tg'), {
      is: 3,
      then: Joi.string().required(),
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  overflateAnbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.overflateTotalvurdering.tg === -1 ||
          state.overflateTotalvurdering.tg === 2 ||
          state.overflateTotalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),

  /* MEMBRAN */
  slukTilgjengeligInspeksjon: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  slukType: Joi.object({
    values: Joi.when(Joi.ref('/slukTilgjengeligInspeksjon.value'), {
      is: false,
      then: Joi.array().min(1).required(),
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  ufagmessigUtforelse: Joi.object({
    value: Joi.when(Joi.ref('/slukTilgjengeligInspeksjon.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  rorgjennomforingerVatSone: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  membranFeilUtforelse: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  overHalvpartBrukstid: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), { is: 'ukjent|Ukjent', then: Joi.string().required() })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  membranTotalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }).concat(
      Joi.when('..tg', {
        is: 2,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  membranUtbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/membranTotalvurdering.tg'), {
      is: 3,
      then: Joi.string().required(),
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  membranAnbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.membranTotalvurdering.tg === -1 ||
          state.membranTotalvurdering.tg === 2 ||
          state.membranTotalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),

  /* SANITAR */
  sanitarBeskrivelse: Joi.object({
    value: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  typeVentilering: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  ventilasjonFunksjonstestet: Joi.object({
    value: Joi.when(Joi.ref('/typeVentilering.value'), {
      is: 'mekanisk|Mekanisk avtrekk',
      then: Joi.boolean().required(),
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderSanitar: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  innebyggetSisterne: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeDreneringSisterne: Joi.object({
    value: Joi.when(Joi.ref('/innebyggetSisterne.value'), {
      is: true,
      then: Joi.string().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  sanitarTotalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }).concat(
      Joi.when('..tg', {
        is: 2,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  sanitarUtbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/sanitarTotalvurdering.tg'), {
      is: 3,
      then: Joi.string().required(),
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  sanitarAnbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.sanitarTotalvurdering.tg === -1 ||
          state.sanitarTotalvurdering.tg === 2 ||
          state.sanitarTotalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),

  /* FUKT */
  hulltaking: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: false, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  registrertFuktVedHulltaking: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: true, then: Joi.boolean().required() }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktSok: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: false, then: Joi.boolean().required() }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktTotalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }).concat(
      Joi.when('..tg', {
        is: 2,
        then: Joi.string().required(),
        otherwise: Joi.any(),
      })
    ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktUtbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/fuktTotalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktAnbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.fuktTotalvurdering.tg === -1 ||
          state.fuktTotalvurdering.tg === 2 ||
          state.fuktTotalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),

  /* DOKUMENTASJON */
  dokumentasjon: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

const SlaktSchema = Joi.object({
  availability: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  slakt: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  hulltaking: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: false, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  registrertFuktVedHulltaking: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: true, then: Joi.boolean().required() }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktSok: Joi.object({
    value: Joi.when(Joi.ref('/hulltaking.value'), { is: false, then: Joi.boolean().required() }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  slaktTotalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  slaktUtbedringskostnader: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  slaktAnbefalteTiltak: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown(true)
  .messages({ '*': 'Dette feltet er påkrevet' });
export const VaatromSchema = Joi.when(
  Joi.object({
    slakt: Joi.object({
      value: true,
    }).unknown(true),
  }).unknown(true),
  { then: SlaktSchema, otherwise: CompleteSchema }
);

interface PartVaatromFormData extends BasePartForm {
  slakt: CheckSingle<boolean>;

  /* OVERFLATE */
  arbeidEtterByggeaar: CheckSingle<string>;
  overflate: CheckSingle<string>;
  tilstrekkeligFallTilSluk: CheckSingle<boolean>;
  avvikHoydeforskjell: CheckSingle<boolean>;
  lekkasjevannTilSluk: CheckSingle<boolean>;
  vinduDorVatsone: CheckSingle<boolean>;
  vinduDorFuktbestandig: CheckSingle<string>;
  gjennomforingerKaldSone: CheckSingle<boolean>;
  punktertDiffusjonssperre: CheckSingle<boolean>;
  sprekkerFlis: CheckSingle<boolean>;
  biologiskeSkadegjorere: CheckSingle<boolean>;
  knirkGulv: CheckSingle<string>;
  overflateTotalvurdering: CheckSingle<string>;
  overflateUtbedringskostnader: CheckSingle<string>;
  overflateAnbefalteTiltak: CheckSingle<boolean>;

  /* MEMBRAN */
  slukTilgjengeligInspeksjon: CheckSingle<boolean>;
  slukType: MultiCheck<string>;
  ufagmessigUtforelse: CheckSingle<boolean>;
  rorgjennomforingerVatSone: CheckSingle<boolean>;
  membranFeilUtforelse: CheckSingle<boolean>;
  overHalvpartBrukstid: CheckSingle<string>;
  membranTotalvurdering: CheckSingle<string>;
  membranUtbedringskostnader: CheckSingle<string>;
  membranAnbefalteTiltak: CheckSingle<boolean>;

  /* SANITAR */
  sanitarBeskrivelse: CheckSingle<string>;
  typeVentilering: CheckSingle<string>;
  ventilasjonFunksjonstestet: CheckSingle<boolean>;
  skaderSanitar: CheckSingle<boolean>;
  innebyggetSisterne: CheckSingle<boolean>;
  manglendeDreneringSisterne: CheckSingle<string>;
  sanitarTotalvurdering: CheckSingle<string>;
  sanitarUtbedringskostnader: CheckSingle<string>;
  sanitarAnbefalteTiltak: CheckSingle<boolean>;

  /* FUKT */
  hulltaking: CheckSingle<boolean>;
  registrertFuktVedHulltaking: CheckSingle<boolean>;
  fuktSok: CheckSingle<boolean>;
  fuktTotalvurdering: CheckSingle<string>;
  fuktUtbedringskostnader: CheckSingle<string>;
  fuktAnbefalteTiltak: CheckSingle<boolean>;

  /* DOKUMENTASJON */
  dokumentasjon: CheckSingle<boolean>;

  /* SLAKT */
  slaktTotalvurdering: CheckSingle<string>;
  slaktUtbedringskostnader: CheckSingle<string>;
  slaktAnbefalteTiltak: CheckSingle<boolean>;
}

export default VaatromForm;
