import React from 'react';
import { CheckCardContent, CheckCardHeader, CheckCardItem } from 'components/common/CheckCard';
import CheckHeader from '@components/common/CheckHeader';
import Tooltip from '@components/common/Tooltip';
import Select from '@components/common/atoms/Select';

export type Alternative = {
  value: string;
  label: string;
};

type Props = {
  id: string;
  header: string;
  alternatives: Alternative[];
  allowFreetext?: boolean;
};

const CheckSelect = ({ id, header, alternatives, allowFreetext = false }: Props) => {
  const selectValue = `${id}.value`;

  return (
    <CheckCardItem id={id}>
      <CheckCardHeader>
        <CheckHeader>{header}</CheckHeader>
        <Tooltip header={header} id={id} />
      </CheckCardHeader>
      <CheckCardContent>
        <Select
          className={allowFreetext ? 'w-full' : 'w-1/2'}
          id={selectValue}
          alternatives={alternatives}
          allowFreetext={allowFreetext}
        />
      </CheckCardContent>
    </CheckCardItem>
  );
};

export default CheckSelect;
