import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const VannledningerForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVannledningerFormData>) => {
  const [formMethods] = usePartForm<PartVannledningerFormData>(
    VannledningerSchema,
    defaultValues,
    PartTypes.Vannledninger.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAnlegg = watch('typeAnlegg.values');
  useEffect(() => {
    if (typeAnlegg && !typeAnlegg.includes('ror_i_ror_system|Rør i rør system')) {
      setValue('etablertFordelerskap.value', null, { shouldValidate: true });
      setValue('fordelerskapTilgjengelig.value', null, { shouldValidate: true });
      setValue('lekkasjevannLedesTilSluk.value', null, { shouldValidate: true });
    }
  }, [typeAnlegg, setValue]);

  const etablertFordelerskap = watch('etablertFordelerskap.value');
  useEffect(() => {
    if (etablertFordelerskap !== 'ja|Ja') {
      setValue('lekkasjevannLedesTilSluk.value', null, { shouldValidate: true });
    }
    if (etablertFordelerskap !== 'apen_rorfordeling|Åpen rørfordeling') {
      setValue('lekkasjeApenRorfordeling.value', null, { shouldValidate: true });
    }
  }, [etablertFordelerskap, setValue]);

  const stoppekranTilgjengelig = watch('stoppekranTilgjengelig.value');
  useEffect(() => {
    if (stoppekranTilgjengelig === true) {
      setValue('stoppekranRedusertFunksjon.value', null, { shouldValidate: true });
    }
  }, [stoppekranTilgjengelig, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Vannledninger.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="typeAnlegg"
          header={getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'typeAnlegg')}
          alternatives={[
            { value: 'kobber', label: 'Kobber' },
            { value: 'plast', label: 'Plast' },
            { value: 'ror_i_ror_system', label: 'Rør i rør system' },
            { value: 'jern', label: 'Jern' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'typeAnlegg'),
            id: 'typeAnlegg.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidUtfortEtterByggear"
          header={getCheckHeader(
            partVersion,
            PartTypes.Vannledninger.id,
            'arbeidUtfortEtterByggear'
          )}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.Vannledninger.id,
              'arbeidUtfortEtterByggear'
            ),
            id: 'arbeidUtfortEtterByggear.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        {typeAnlegg && typeAnlegg.includes('ror_i_ror_system|Rør i rør system') && (
          <>
            <CheckText
              id="etablertFordelerskap"
              header={getCheckHeader(
                partVersion,
                PartTypes.Vannledninger.id,
                'etablertFordelerskap'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'apen_rorfordeling', label: 'Åpen rørfordeling' },
              ]}
            />

            {etablertFordelerskap === 'ja|Ja' && (
              <CheckText
                id="lekkasjevannLedesTilSluk"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vannledninger.id,
                  'lekkasjevannLedesTilSluk'
                )}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
                ]}
              />
            )}

            {etablertFordelerskap === 'apen_rorfordeling|Åpen rørfordeling' && (
              <CheckBoolean
                id="lekkasjeApenRorfordeling"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Vannledninger.id,
                  'lekkasjeApenRorfordeling'
                )}
              />
            )}
          </>
        )}

        <CheckText
          id="alder"
          header={getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'alder')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckBoolean
          id="manglendeIsolering"
          header={getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'manglendeIsolering')}
        />

        <CheckText
          id="lekkasjeSkader"
          header={getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'lekkasjeSkader')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckText
          id="vanntrykk"
          header={getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'vanntrykk')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
        />

        <CheckBoolean
          id="stoppekranTilgjengelig"
          header={getCheckHeader(partVersion, PartTypes.Vannledninger.id, 'stoppekranTilgjengelig')}
        />

        {stoppekranTilgjengelig === false && (
          <CheckText
            id="stoppekranRedusertFunksjon"
            header={getCheckHeader(
              partVersion,
              PartTypes.Vannledninger.id,
              'stoppekranRedusertFunksjon'
            )}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
            ]}
          />
        )}

        <KonklusjonTiltakKostnader
          partType="Vannledninger"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Vannledninger.name}
      />
    </FormProvider>
  );
};

export const VannledningerSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    values: Joi.array().min(1).required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidUtfortEtterByggear: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  etablertFordelerskap: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        state.typeAnlegg &&
        state.typeAnlegg.values &&
        state.typeAnlegg.values.includes('ror_i_ror_system|Rør i rør system')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjevannLedesTilSluk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (!value && state.etablertFordelerskap && state.etablertFordelerskap.value === 'ja|Ja') {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjeApenRorfordeling: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.etablertFordelerskap &&
        state.etablertFordelerskap.value === 'apen_rorfordeling|Åpen rørfordeling'
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeIsolering: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjeSkader: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  vanntrykk: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  stoppekranTilgjengelig: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  stoppekranRedusertFunksjon: Joi.object({
    value: Joi.when(Joi.ref('/stoppekranTilgjengelig.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVannledningerFormData extends BasePartForm {
  typeAnlegg: MultiCheck<string>;
  arbeidUtfortEtterByggear: CheckSingle<string>;
  etablertFordelerskap: CheckSingle<string>;
  lekkasjevannLedesTilSluk: CheckSingle<string>;
  lekkasjeApenRorfordeling: CheckSingle<boolean>;
  alder: CheckSingle<string>;
  manglendeIsolering: CheckSingle<boolean>;
  lekkasjeSkader: CheckSingle<string>;
  vanntrykk: CheckSingle<string>;
  stoppekranTilgjengelig: CheckSingle<boolean>;

  stoppekranRedusertFunksjon: CheckSingle<string>;
  fordelerskapTilgjengelig: CheckSingle<boolean>;
  overHalvpartBrukstid: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VannledningerForm;
