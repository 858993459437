import { default as Toalettrom, ToalettromSchema } from './Toalettrom/v8';
import { default as Vaatrom, VaatromSchema } from './Vaatrom/v8';
import { default as Elektrisk, ElektriskSchema } from './Elektrisk/v8';
import { default as Varmtvannsbereder, VarmtvannsberederSchema } from './Varmtvannsbereder/v8';

import { PartTypes } from '@supertakst/model-common';
import { withAvailabilitySchema } from '@utils/modelUtils';

import v7Parts from './v7';

const parts = {
  ...v7Parts,
  [PartTypes.Toalettrom.id]: {
    form: Toalettrom,
    schema: withAvailabilitySchema({ schema: ToalettromSchema, version: 8 }),
  },
  [PartTypes.Vaatrom.id]: {
    form: Vaatrom,
    schema: withAvailabilitySchema({ schema: VaatromSchema, version: 8 }),
  },
  [PartTypes.Elektrisk.id]: {
    form: Elektrisk,
    schema: withAvailabilitySchema({ schema: ElektriskSchema, version: 8 }),
  },
  [PartTypes.Varmtvannsbereder.id]: {
    form: Varmtvannsbereder,
    schema: withAvailabilitySchema({ schema: VarmtvannsberederSchema, version: 8 }),
  },
};

export default parts;
