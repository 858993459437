import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import NameInput from '@components/common/NameInput';
import { PartImages } from '@components/common/Images';

const OvrigForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartOvrigFormData>) => {
  const [formMethods] = usePartForm<PartOvrigFormData>(
    OvrigSchema,
    defaultValues,
    PartTypes.Ovrig.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  const enableTg = watch('enableTg.value');
  useEffect(() => {
    if (enableTg !== true) {
      setValue('totalvurdering.value', null);
      setValue('totalvurdering.tg', null);
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
  }, [enableTg, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Ovrig.name} />

        <CheckBoolean
          id="enableTg"
          header={getCheckHeader(1, PartTypes.Ovrig.id, 'enableTg')}
          hideComment={true}
        />

        <SingleTextarea
          id="description"
          header={getCheckHeader(1, PartTypes.Ovrig.id, 'description')}
        />

        {enableTg && (
          <>
            <SingleTextarea
              id="totalvurdering"
              header={getCheckHeader(1, PartTypes.Ovrig.id, 'totalvurdering')}
              hasTg
            />

            {totalvurderingTg === 3 && (
              <CheckText
                id="utbedringskostnader"
                header={getCheckHeader(1, PartTypes.Ovrig.id, 'utbedringskostnader')}
                alternatives={[
                  { value: '0', label: 'Ingen umiddelbar kostnad' },
                  { value: '10000', label: 'Under 10 000' },
                  { value: '10000-50000', label: '10 000 - 50 000' },
                  { value: '50000-150000', label: '50 000 - 150 000' },
                  { value: '150000-300000', label: '150 000 - 300 000' },
                  { value: '300000', label: 'Over 300 000' },
                ]}
              />
            )}
          </>
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Ovrig.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Ovrig.name}
      />
    </FormProvider>
  );
};

export const OvrigSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  enableTg: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  description: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if ((value === null || value === undefined) && state.enableTg.value === true) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartOvrigFormData extends BasePartForm {
  enableTg: CheckSingle<boolean>;
  description: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default OvrigForm;
