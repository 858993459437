import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const LoftForm = ({ defaultValues, doOnSave }: BasePartComponentProps<PartLoftFormData>) => {
  const [formMethods] = usePartForm<PartLoftFormData>(
    LoftSchema,
    defaultValues,
    PartTypes.Loft.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const konstruksjonsPrinsipp = watch('konstruksjonsPrinsipp.value');
  useEffect(() => {
    if (konstruksjonsPrinsipp === 'kompakt_tak|Kompakt tak') {
      setValue('ventilering.value', null);
      setValue('ventilering.comment', null);
    }
  }, [konstruksjonsPrinsipp, setValue]);

  const typeLoft = watch('typeLoft.value');
  useEffect(() => {
    if (
      typeLoft !== 'innredet|Innredet loft (lukket konstruksjon)' &&
      typeLoft !== 'delvis_innredet|Delvis innredet / kaldtloft'
    ) {
      setValue('arbeidUtfortEtterByggeAar.value', null);
      setValue('arbeidUtfortEtterByggeAar.comment', null);
    }
    if (typeLoft !== 'flatt_tak|Flatt tak (konstruksjon uten loft)') {
      setValue('konstruksjonsPrinsipp.value', null);
      setValue('konstruksjonsPrinsipp.comment', null);
    }
  }, [typeLoft, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Loft.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckText
          id="typeLoft"
          header={getCheckHeader(1, PartTypes.Loft.id, 'typeLoft')}
          alternatives={[
            { value: 'innredet', label: 'Innredet loft (lukket konstruksjon)' },
            { value: 'kaldtloft', label: 'Kaldtloft' },
            { value: 'flatt_tak', label: 'Flatt tak (konstruksjon uten loft)' },
            { value: 'delvis_innredet', label: 'Delvis innredet / kaldtloft' },
          ]}
        />

        {typeLoft === 'flatt_tak|Flatt tak (konstruksjon uten loft)' && (
          <>
            <CheckText
              id="konstruksjonsPrinsipp"
              header={getCheckHeader(1, PartTypes.Loft.id, 'konstruksjonsPrinsipp')}
              alternatives={[
                { value: 'kompakt_tak', label: 'Kompakt tak' },
                { value: 'tretak', label: 'Tretak' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />
          </>
        )}

        {(typeLoft === 'innredet|Innredet loft (lukket konstruksjon)' ||
          typeLoft === 'delvis_innredet|Delvis innredet / kaldtloft') && (
          <CheckText
            id="arbeidUtfortEtterByggeAar"
            header={getCheckHeader(1, PartTypes.Loft.id, 'arbeidUtfortEtterByggeAar')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ukjent', label: 'Ukjent' },
            ]}
          />
        )}

        <CheckBoolean id="fukt" header={getCheckHeader(1, PartTypes.Loft.id, 'fukt')} />

        <CheckBoolean id="sopp" header={getCheckHeader(1, PartTypes.Loft.id, 'sopp')} />

        {konstruksjonsPrinsipp !== 'kompakt_tak|Kompakt tak' && (
          <CheckText
            id="ventilering"
            header={getCheckHeader(1, PartTypes.Loft.id, 'ventilering')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <CheckBoolean id="tetting" header={getCheckHeader(1, PartTypes.Loft.id, 'tetting')} />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Loft.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Loft.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Loft.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Loft.name}
      />
    </FormProvider>
  );
};

export const LoftSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeLoft: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  konstruksjonsPrinsipp: Joi.object({
    value: Joi.when(Joi.ref('/typeLoft.value'), {
      is: 'flatt_tak|Flatt tak (konstruksjon uten loft)',
      then: Joi.string().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidUtfortEtterByggeAar: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        (state.typeLoft.value === 'delvis_innredet|Delvis innredet / kaldtloft' ||
          state.typeLoft.value === 'innredet|Innredet loft (lukket konstruksjon)')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  fukt: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  sopp: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  ventilering: Joi.object({
    value: Joi.when('/konstruksjonsPrinsipp.value', {
      is: 'kompakt_tak|Kompakt tak',
      otherwise: Joi.string().required(),
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        (state.ventilering.value === 'ikke_kontrollerbart|Ikke kontrollerbart' ||
          state.ventilering.value === 'ja|Ja')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  tetting: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartLoftFormData extends BasePartForm {
  typeLoft: CheckSingle<string>;
  konstruksjonsPrinsipp: CheckSingle<string>;
  arbeidUtfortEtterByggeAar: CheckSingle<string>;
  fukt: CheckSingle<boolean>;
  sopp: CheckSingle<boolean>;
  ventilering: CheckSingle<string>;
  tetting: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default LoftForm;
