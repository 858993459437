import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const VannledningerForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVannledningerFormData>) => {
  const [formMethods] = usePartForm<PartVannledningerFormData>(
    VannledningerSchema,
    defaultValues,
    PartTypes.Vannledninger.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAnlegg = watch('typeAnlegg.values');
  useEffect(() => {
    if (typeAnlegg && !typeAnlegg.includes('ror_i_ror_system|Rør i rør system')) {
      setValue('etablertFordelerskap.value', null);
      setValue('etablertFordelerskap.comment', null);

      setValue('fordelerskapTilgjengelig.value', null);
      setValue('fordelerskapTilgjengelig.comment', null);
      setValue('lekkasjevannLedesTilSluk.value', null);
      setValue('lekkasjevannLedesTilSluk.comment', null);
    }
  }, [typeAnlegg, setValue]);

  const etablertFordelerskap = watch('etablertFordelerskap.value');
  useEffect(() => {
    if (etablertFordelerskap !== 'ja|Ja') {
      setValue('lekkasjevannLedesTilSluk.value', null);
      setValue('lekkasjevannLedesTilSluk.comment', null);
    }
    if (etablertFordelerskap !== 'apen_rorfordeling|Åpen rørfordeling') {
      setValue('lekkasjeApenRorfordeling.value', null);
      setValue('lekkasjeApenRorfordeling.comment', null);
    }
  }, [etablertFordelerskap, setValue]);

  const stoppekranTilgjengelig = watch('stoppekranTilgjengelig.value');
  useEffect(() => {
    if (stoppekranTilgjengelig === true) {
      setValue('stoppekranRedusertFunksjon.value', null);
      setValue('stoppekranRedusertFunksjon.comment', null);
    }
  }, [stoppekranTilgjengelig, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Vannledninger.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="typeAnlegg"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'typeAnlegg')}
          alternatives={[
            { value: 'kobber', label: 'Kobber' },
            { value: 'plast', label: 'Plast' },
            { value: 'ror_i_ror_system', label: 'Rør i rør system' },
            { value: 'jern', label: 'Jern' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckText
          id="arbeidUtfortEtterByggear"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'arbeidUtfortEtterByggear')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        {typeAnlegg && typeAnlegg.includes('ror_i_ror_system|Rør i rør system') && (
          <>
            <CheckText
              id="etablertFordelerskap"
              header={getCheckHeader(1, PartTypes.Vannledninger.id, 'etablertFordelerskap')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'apen_rorfordeling', label: 'Åpen rørfordeling' },
              ]}
            />

            {etablertFordelerskap === 'ja|Ja' && (
              <CheckText
                id="lekkasjevannLedesTilSluk"
                header={getCheckHeader(1, PartTypes.Vannledninger.id, 'lekkasjevannLedesTilSluk')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
                ]}
              />
            )}

            {etablertFordelerskap === 'apen_rorfordeling|Åpen rørfordeling' && (
              <CheckBoolean
                id="lekkasjeApenRorfordeling"
                header={getCheckHeader(1, PartTypes.Vannledninger.id, 'lekkasjeApenRorfordeling')}
              />
            )}
          </>
        )}

        <CheckText
          id="alder"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'alder')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckBoolean
          id="manglendeIsolering"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'manglendeIsolering')}
        />

        <CheckBoolean
          id="lekkasjeSkader"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'lekkasjeSkader')}
        />

        <CheckBoolean
          id="vanntrykk"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'vanntrykk')}
        />

        <CheckBoolean
          id="stoppekranTilgjengelig"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'stoppekranTilgjengelig')}
        />

        {stoppekranTilgjengelig === false && (
          <CheckText
            id="stoppekranRedusertFunksjon"
            header={getCheckHeader(1, PartTypes.Vannledninger.id, 'stoppekranRedusertFunksjon')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
            ]}
          />
        )}

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Vannledninger.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Vannledninger.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Vannledninger.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Vannledninger.name}
      />
    </FormProvider>
  );
};

export const VannledningerSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    values: Joi.array().min(1).required(),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidUtfortEtterByggear: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  etablertFordelerskap: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        state.typeAnlegg &&
        state.typeAnlegg.values &&
        state.typeAnlegg.values.includes('ror_i_ror_system|Rør i rør system')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: '', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjevannLedesTilSluk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (!value && state.etablertFordelerskap && state.etablertFordelerskap.value === 'ja|Ja') {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjeApenRorfordeling: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.etablertFordelerskap &&
        state.etablertFordelerskap.value === 'apen_rorfordeling|Åpen rørfordeling'
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeIsolering: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjeSkader: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  vanntrykk: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  stoppekranTilgjengelig: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  stoppekranRedusertFunksjon: Joi.object({
    value: Joi.when(Joi.ref('/stoppekranTilgjengelig.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ikke_kontrollert|Ikke kontrollert',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVannledningerFormData extends BasePartForm {
  typeAnlegg: MultiCheck<string>;
  arbeidUtfortEtterByggear: CheckSingle<string>;
  etablertFordelerskap: CheckSingle<string>;
  lekkasjevannLedesTilSluk: CheckSingle<string>;
  lekkasjeApenRorfordeling: CheckSingle<boolean>;
  alder: CheckSingle<string>;
  manglendeIsolering: CheckSingle<boolean>;
  lekkasjeSkader: CheckSingle<boolean>;
  vanntrykk: CheckSingle<boolean>;
  stoppekranTilgjengelig: CheckSingle<boolean>;

  stoppekranRedusertFunksjon: CheckSingle<string>;
  fordelerskapTilgjengelig: CheckSingle<boolean>;
  overHalvpartBrukstid: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VannledningerForm;
