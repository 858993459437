import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const ElektriskForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartElektriskFormData>) => {
  const [formMethods] = usePartForm<PartElektriskFormData>(
    ElektriskSchema,
    defaultValues,
    PartTypes.Elektrisk.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;
  const kursfortegnelse = watch('kursfortegnelse.value');
  useEffect(() => {
    if (kursfortegnelse !== 'nei|Nei') {
      setValue('kursfortegnelseMangler.value', null);
    }
  }, [kursfortegnelse, setValue]);

  const kontrollElTilsyn = watch('kontrollElTilsyn.value');
  const totalvurderingTg = watch('totalvurdering.tg');
  const typeAnlegg = watch('typeAnlegg.value');

  useEffect(() => {
    if (kontrollElTilsyn) {
      setValue('sikringType.values', [], { shouldValidate: true });
      setValue('typeAnlegg.value', null, { shouldValidate: true });
      setValue('arbeidEtterByggeaar.value', null, { shouldValidate: true });
      setValue('samsvarserklaering.value', null, { shouldValidate: true });
      setValue('kursfortegnelse.value', null, { shouldValidate: true });
      setValue('kursfortegnelseMangler.value', null, { shouldValidate: true });
      setValue('termiskSkade.value', null, { shouldValidate: true });
      setValue('kabelinnforinger.value', null, { shouldValidate: true });
      setValue('kablerTilstrekkeligFestet.value', null, { shouldValidate: true });
      setValue('utlosesSikringer.value', null, { shouldValidate: true });
      setValue('branntillop.value', null, { shouldValidate: true });
    }
  }, [kontrollElTilsyn, setValue]);

  useEffect(() => {
    if (typeAnlegg !== 'apent|Åpent' && typeAnlegg !== 'delvis_skjult|Delvis skjult') {
      setValue('kablerTilstrekkeligFestet.value', null, { shouldValidate: true });
    }
  }, [typeAnlegg, setValue]);

  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Elektrisk.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckBoolean
          id="kontrollElTilsyn"
          header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'kontrollElTilsyn')}
        />

        {kontrollElTilsyn === false && (
          <>
            <CheckMultiText
              id="sikringType"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'sikringType')}
              alternatives={[
                { value: 'skrusikring', label: 'Skrusikringer' },
                { value: 'automatsikring', label: 'Automatsikringer' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'sikringType'),
                id: 'sikringType.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />
            <CheckText
              id="typeAnlegg"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'typeAnlegg')}
              alternatives={[
                { value: 'apent', label: 'Åpent' },
                { value: 'skjult', label: 'Skjult' },
                { value: 'delvis_skjult', label: 'Delvis skjult' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'typeAnlegg'),
                id: 'typeAnlegg.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />
            <CheckText
              id="arbeidEtterByggeaar"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'arbeidEtterByggeaar')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'arbeidEtterByggeaar'),
                id: 'arbeidEtterByggeaar.comment',
                tg: 'totalvurdering.tg',
              }}
              hideComment={false}
            />
            <CheckBoolean
              id="samsvarserklaering"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'samsvarserklaering')}
            />
            <CheckText
              id="kursfortegnelse"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'kursfortegnelse')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_undersøkt', label: 'Ikke undersøkt' },
              ]}
            />
            {kursfortegnelse === 'nei|Nei' && (
              <CheckBoolean
                id="kursfortegnelseMangler"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Elektrisk.id,
                  'kursfortegnelseMangler'
                )}
              />
            )}
            <CheckBoolean
              id="termiskSkade"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'termiskSkade')}
            />
            {(typeAnlegg === 'apent|Åpent' || typeAnlegg === 'delvis_skjult|Delvis skjult') && (
              <CheckBoolean
                id="kablerTilstrekkeligFestet"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Elektrisk.id,
                  'kablerTilstrekkeligFestet'
                )}
              />
            )}
            <CheckBoolean
              id="kabelinnforinger"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'kabelinnforinger')}
            />
            <CheckText
              id="utlosesSikringer"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'utlosesSikringer')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_besvart', label: 'Ikke besvart' },
              ]}
            />
            <CheckText
              id="branntillop"
              header={getCheckHeader(partVersion, PartTypes.Elektrisk.id, 'branntillop')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_besvart', label: 'Ikke besvart' },
              ]}
            />
          </>
        )}

        <KonklusjonTiltakKostnader
          partType="Elektrisk"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Elektrisk.name}
      />
    </FormProvider>
  );
};

export const ElektriskSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  kontrollElTilsyn: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  sikringType: Joi.object({
    values: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.array().required().min(1),
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  typeAnlegg: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  samsvarserklaering: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.boolean().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),

  kursfortegnelse: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  kursfortegnelseMangler: Joi.object({
    value: Joi.when(Joi.ref('/kursfortegnelse.value'), {
      is: 'nei|Nei',
      then: Joi.boolean().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  termiskSkade: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.boolean().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  kablerTilstrekkeligFestet: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value === null &&
        state.kontrollElTilsyn.value === false &&
        state.typeAnlegg.value &&
        (state.typeAnlegg.value === 'apent|Åpent' ||
          state.typeAnlegg.value === 'delvis_skjult|Delvis skjult')
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  kabelinnforinger: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.boolean().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  utlosesSikringer: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  branntillop: Joi.object({
    value: Joi.when(Joi.ref('/kontrollElTilsyn.value'), {
      is: false,
      then: Joi.string().required(),
    }),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartElektriskFormData extends BasePartForm {
  kontrollElTilsyn: CheckSingle<boolean>;
  sikringType: MultiCheck<string>;
  typeAnlegg: CheckSingle<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  samsvarserklaering: CheckSingle<boolean>;
  kursfortegnelse: CheckSingle<string>;
  kursfortegnelseMangler: CheckSingle<string>;
  termiskSkade: CheckSingle<boolean>;
  kablerTilstrekkeligFestet: CheckSingle<boolean>;
  kabelinnforinger: CheckSingle<boolean>;
  utlosesSikringer: CheckSingle<string>;
  branntillop: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default ElektriskForm;
