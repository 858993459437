import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import SingleInput from '@components/common/SingleInput';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import CheckSelect from '@components/common/CheckSelect';

const VarmtvannsberederForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVarmtvannsberederFormData>) => {
  const [formMethods] = usePartForm<PartVarmtvannsberederFormData>(
    VarmtvannsberederSchema,
    defaultValues,
    PartTypes.Varmtvannsbereder.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const totalvurderingTg = watch('totalvurdering.tg');
  const over20Ar = watch('over20Ar.value');
  useEffect(() => {
    if (
      over20Ar === 'ja|Ja' &&
      (totalvurderingTg === null || totalvurderingTg === 1 || totalvurderingTg === 0)
    ) {
      setValue('totalvurdering.tg', 2);
      trigger('totalvurdering.tg');
      trigger('totalvurdering.value');
    }
  }, [over20Ar, totalvurderingTg, setValue]);

  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Varmtvannsbereder.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckSelect
          id="plassering"
          header={getCheckHeader(3, PartTypes.Varmtvannsbereder.id, 'plassering')}
          alternatives={[
            { value: 'vaskerom', label: 'Vaskerom' },
            { value: 'bad', label: 'Bad' },
            { value: 'teknisk_rom', label: 'Teknisk rom' },
            { value: 'bod', label: 'Bod' },
            { value: 'kjokkenbenk', label: 'Kjøkkenbenk' },
            { value: 'loft', label: 'Loft' },
          ]}
          allowFreetext
        />

        <CheckSelect
          id="fundament"
          header={getCheckHeader(3, PartTypes.Varmtvannsbereder.id, 'fundament')}
          alternatives={[
            { value: 'plassert_paa_gulv', label: 'Plassert på gulv' },
            { value: 'vegghengt', label: 'Vegghengt' },
            { value: 'kjokkenskap_uten_understottelse', label: 'Kjøkkenskap uten understøttelse' },
            { value: 'kjokkenskap_med_understottelse', label: 'Kjøkkenskap med understøttelse' },
            {
              value: 'kjokkenskap_med_ukjent_understottelse',
              label: 'Kjøkkenskap med ukjent understøttelse',
            },
          ]}
          allowFreetext
        />

        <SingleInput
          type="text"
          id="arstall"
          header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'arstall')}
        />

        <CheckSelect
          id="storrelse"
          header={getCheckHeader(3, PartTypes.Varmtvannsbereder.id, 'storrelse')}
          alternatives={[
            { value: '10_liter', label: '10 liter' },
            { value: '20_liter', label: '20 liter' },
            { value: '30_liter', label: '30 liter' },
            { value: '50_liter', label: '50 liter' },
            { value: '80_liter', label: '80 liter' },
            { value: '100_liter', label: '100 liter' },
            { value: '120_liter', label: '120 liter' },
            { value: '150_liter', label: '150 liter' },
            { value: '200_liter', label: '200 liter' },
            { value: '300_liter', label: '300 liter' },
          ]}
          allowFreetext
        />

        <CheckText
          id="lekkasjesikret"
          header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'lekkasjesikret')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollertbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="pluggBrunsvidd"
          header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'pluggBrunsvidd')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_relevant_fast_tilkobling', label: 'Ikke relevant, fast tilkobling' },
            { value: 'ikke_kontrollertbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="lekkasje"
          header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'lekkasje')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollertbart', label: 'Ikke kontrollerbart' },
          ]}
        />

        <CheckText
          id="over20Ar"
          header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'over20Ar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Varmtvannsbereder.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Varmtvannsbereder.name}
      />
    </FormProvider>
  );
};

export const VarmtvannsberederSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  plassering: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  fundament: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  arstall: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  storrelse: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasjesikret: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ikke_kontrollertbart|Ikke kontrollerbart',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  pluggBrunsvidd: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ikke_kontrollertbart|Ikke kontrollerbart',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  lekkasje: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), {
        is: 'ikke_kontrollertbart|Ikke kontrollerbart',
        then: Joi.string().required(),
      })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  over20Ar: Joi.object({
    value: Joi.string().required(),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVarmtvannsberederFormData extends BasePartForm {
  plassering: CheckSingle<string>;
  fundament: CheckSingle<string>;
  arstall: CheckSingle<string>;
  storrelse: CheckSingle<string>;
  lekkasjesikret: CheckSingle<string>;
  pluggBrunsvidd: CheckSingle<string>;
  lekkasje: CheckSingle<string>;
  over20Ar: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VarmtvannsberederForm;
