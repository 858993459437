import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import SingleTextarea from '@components/common/SingleTextarea/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const VarmesentralForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVarmesentralFormData>) => {
  const [formMethods] = usePartForm<PartVarmesentralFormData>(
    VarmesentralSchema,
    defaultValues,
    PartTypes.Varmesentral.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAnlegg = watch('typeAnlegg.values');
  useEffect(() => {
    if (
      !typeAnlegg ||
      (!typeAnlegg.includes('fyrkjele|Fyrkjele') && !typeAnlegg.includes('oljefyr|Oljefyr'))
    ) {
      setValue('fyrkjeleTilpassetBioolje.value', null, { shouldValidate: true });
      setValue('lukt.value', null, { shouldValidate: true });
    }
  }, [typeAnlegg, setValue]);

  const oljetank = watch('oljetank.value');
  useEffect(() => {
    if (oljetank !== 'ja|Ja') {
      setValue('oljetankPlassering.value', null, { shouldValidate: true });
      setValue('paleggSanering.value', null, { shouldValidate: true });
      setValue('oljetankLekkasjesikret.value', null, { shouldValidate: true });
    }
  }, [oljetank, setValue]);

  const oljetankPlassering = watch('oljetankPlassering.value');
  useEffect(() => {
    if (oljetankPlassering !== 'eget_tankrom|Eget tankrom') {
      setValue('oljetankLekkasjesikret.value', null, { shouldValidate: true });
    }
  }, [oljetankPlassering, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Varmesentral.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="typeAnlegg"
          header={getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'typeAnlegg')}
          alternatives={[
            { value: 'fyrkjele', label: 'Fyrkjele' },
            { value: 'oljefyr', label: 'Oljefyr' },
            { value: 'varmepumpe', label: 'Varmepumpe' },
            { value: 'pelletsanlegg', label: 'Pelletsanlegg' },
            { value: 'gasskjele', label: 'Gasskjele' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'typeAnlegg'),
            id: 'typeAnlegg.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidUtfortEtterByggear"
          header={getCheckHeader(
            partVersion,
            PartTypes.Varmesentral.id,
            'arbeidUtfortEtterByggear'
          )}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.Varmesentral.id,
              'arbeidUtfortEtterByggear'
            ),
            id: 'arbeidUtfortEtterByggear.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <SingleTextarea
          id="sistService"
          header={getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'sistService')}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'sistService'),
            id: 'sistService.value',
            tg: 'totalvurdering.tg',
          }}
        />

        <CheckText
          id="oljetank"
          header={getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'oljetank')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        {oljetank === 'ja|Ja' && (
          <>
            <CheckText
              id="oljetankPlassering"
              header={getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'oljetankPlassering')}
              alternatives={[
                { value: 'nedgravd', label: 'Nedgravd' },
                { value: 'eget_tankrom', label: 'Eget tankrom' },
              ]}
            />

            <CheckText
              id="paleggSanering"
              header={getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'paleggSanering')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            <CheckText
              id="oljetankLekkasjesikret"
              header={getCheckHeader(
                partVersion,
                PartTypes.Varmesentral.id,
                'oljetankLekkasjesikret'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />
          </>
        )}

        {typeAnlegg &&
          (typeAnlegg.includes('fyrkjele|Fyrkjele') || typeAnlegg.includes('oljefyr|Oljefyr')) && (
            <>
              <CheckBoolean
                id="fyrkjeleTilpassetBioolje"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.Varmesentral.id,
                  'fyrkjeleTilpassetBioolje'
                )}
              />

              <CheckBoolean
                id="lukt"
                header={getCheckHeader(partVersion, PartTypes.Varmesentral.id, 'lukt')}
              />
            </>
          )}

        <KonklusjonTiltakKostnader
          partType="Varmesentral"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Varmesentral.name}
      />
    </FormProvider>
  );
};

export const VarmesentralSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    values: Joi.array().min(1).required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidUtfortEtterByggear: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  sistService: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  oljetank: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  oljetankPlassering: Joi.object({
    value: Joi.when(Joi.ref('/oljetank.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  paleggSanering: Joi.object({
    value: Joi.when(Joi.ref('/oljetank.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  oljetankLekkasjesikret: Joi.object({
    value: Joi.when(Joi.ref('/oljetank.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  fyrkjeleTilpassetBioolje: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.typeAnlegg.values &&
        (state.typeAnlegg.values.includes('fyrkjele|Fyrkjele') ||
          state.typeAnlegg.values.includes('oljefyr|Oljefyr'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  lukt: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.typeAnlegg.values &&
        (state.typeAnlegg.values.includes('fyrkjele|Fyrkjele') ||
          state.typeAnlegg.values.includes('oljefyr|Oljefyr'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVarmesentralFormData extends BasePartForm {
  typeAnlegg: MultiCheck<string>;
  arbeidUtfortEtterByggear: CheckSingle<string>;
  sistService: CheckSingle<string>;
  oljetank: CheckSingle<string>;
  oljetankPlassering: CheckSingle<string>;
  paleggSanering: CheckSingle<string>;
  oljetankLekkasjesikret: CheckSingle<string>;
  fyrkjeleTilpassetBioolje: CheckSingle<boolean>;
  lukt: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VarmesentralForm;
