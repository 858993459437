import { default as Vannledninger, VannledningerSchema } from './Vannledninger/v4';
import { default as Varmtvannsbereder, VarmtvannsberederSchema } from './Varmtvannsbereder/v4';
import { default as Lovlighet, LovlighetSchema } from './Lovlighet/v4';

import { PartTypes } from '@supertakst/model-common';
import { withAvailabilitySchema } from '@utils/modelUtils';

import v3Parts from './v3';

const parts = {
  ...v3Parts,
  [PartTypes.Lovlighet.id]: {
    form: Lovlighet,
    schema: withAvailabilitySchema({ schema: LovlighetSchema }),
  },
  [PartTypes.Vannledninger.id]: {
    form: Vannledninger,
    schema: withAvailabilitySchema({ schema: VannledningerSchema }),
  },
  [PartTypes.Varmtvannsbereder.id]: {
    form: Varmtvannsbereder,
    schema: withAvailabilitySchema({ schema: VarmtvannsberederSchema }),
  },
};

export default parts;
