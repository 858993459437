import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v1';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v1';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const YtterveggerForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartYtterveggerFormData>) => {
  const [formMethods] = usePartForm<PartYtterveggerFormData>(
    YtterveggerSchema,
    defaultValues,
    PartTypes.Yttervegger.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const fasade = watch('fasade.values');
  useEffect(() => {
    if (
      !fasade ||
      (!fasade.includes('liggende|Liggende kledning') &&
        !fasade.includes('staaende|Stående kledning') &&
        !fasade.includes('bordkledning|Bordkledning') &&
        !fasade.includes('fasadeplater|Fasadeplater'))
    ) {
      setValue('musetetting.value', null, { shouldValidate: true });
      setValue('luftingAvKledning.value', null, { shouldValidate: true });
    }
  }, [fasade, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Yttervegger.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="fasade"
          header={getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'fasade')}
          alternatives={[
            { value: 'bordkledning', label: 'Bordkledning' },
            { value: 'liggende', label: 'Liggende kledning' },
            { value: 'staaende', label: 'Stående kledning' },
            { value: 'tommer', label: 'Tømmer' },
            { value: 'murpuss', label: 'Murpuss' },
            { value: 'leca', label: 'Leca' },
            { value: 'fasadeplater', label: 'Fasadeplater' },
            { value: 'eternitt', label: 'Eternitt' },
            { value: 'teglestein', label: 'Teglstein' },
            { value: 'betong', label: 'Betong' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'fasade'),
            id: 'fasade.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'arbeidEtterByggeaar'),
            id: 'arbeidEtterByggeaar.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="skjevheter"
          header={getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'skjevheter')}
        />

        <CheckBoolean
          id="fuktskader"
          header={getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'fuktskader')}
        />

        {fasade &&
          (fasade.includes('liggende|Liggende kledning') ||
            fasade.includes('staaende|Stående kledning') ||
            fasade.includes('bordkledning|Bordkledning') ||
            fasade.includes('fasadeplater|Fasadeplater')) && (
            <>
              <CheckText
                id="luftingAvKledning"
                header={getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'luftingAvKledning')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
                ]}
              />

              <CheckText
                id="musetetting"
                header={getCheckHeader(partVersion, PartTypes.Yttervegger.id, 'musetetting')}
                alternatives={[
                  { value: 'ja', label: 'Ja' },
                  { value: 'nei', label: 'Nei' },
                  { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
                ]}
              />
            </>
          )}

        <KonklusjonTiltakKostnader
          partType="Yttervegger"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Yttervegger.name}
      />
    </FormProvider>
  );
};

export const YtterveggerSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  fasade: Joi.object({
    values: Joi.array().min(1).required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  skjevheter: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  fuktskader: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  musetetting: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        state.fasade.values &&
        (state.fasade.values.includes('liggende|Liggende kledning') ||
          state.fasade.values.includes('staaende|Stående kledning') ||
          state.fasade.values.includes('bordkledning|Bordkledning') ||
          state.fasade.values.includes('fasadeplater|Fasadeplater'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  luftingAvKledning: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        (!value || value.length === 0) &&
        state.fasade.values &&
        (state.fasade.values.includes('liggende|Liggende kledning') ||
          state.fasade.values.includes('staaende|Stående kledning') ||
          state.fasade.values.includes('bordkledning|Bordkledning') ||
          state.fasade.values.includes('fasadeplater|Fasadeplater'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartYtterveggerFormData extends BasePartForm {
  arbeidEtterByggeaar: CheckSingle<string>;
  fasade: MultiCheck<string>;
  skjevheter: CheckSingle<boolean>;
  fuktskader: CheckSingle<boolean>;
  musetetting: CheckSingle<string>;
  luftingAvKledning: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default YtterveggerForm;
