import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const VarmesentralForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartVarmesentralFormData>) => {
  const [formMethods] = usePartForm<PartVarmesentralFormData>(
    VarmesentralSchema,
    defaultValues,
    PartTypes.Varmesentral.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const typeAnlegg = watch('typeAnlegg.values');
  useEffect(() => {
    if (
      !typeAnlegg ||
      (!typeAnlegg.includes('fyrkjele|Fyrkjele') && !typeAnlegg.includes('oljefyr|Oljefyr'))
    ) {
      setValue('fyrkjeleTilpassetBioolje.value', null);
      setValue('fyrkjeleTilpassetBioolje.comment', null);
      setValue('lukt.value', null);
      setValue('lukt.comment', null);
    }
  }, [typeAnlegg, setValue]);

  const oljetank = watch('oljetank.value');
  useEffect(() => {
    if (oljetank !== 'ja|Ja') {
      setValue('oljetankPlassering.value', null);
      setValue('oljetankPlassering.comment', null);
      setValue('paleggSanering.value', null);
      setValue('paleggSanering.comment', null);
      setValue('oljetankLekkasjesikret.value', null);
      setValue('oljetankLekkasjesikret.comment', null);
    }
  }, [oljetank, setValue]);

  const oljetankPlassering = watch('oljetankPlassering.value');
  useEffect(() => {
    if (oljetankPlassering !== 'eget_tankrom|Eget tankrom') {
      setValue('oljetankLekkasjesikret.value', null);
      setValue('oljetankLekkasjesikret.comment', null);
    }
  }, [oljetankPlassering, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Varmesentral.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="typeAnlegg"
          header={getCheckHeader(1, PartTypes.Varmesentral.id, 'typeAnlegg')}
          alternatives={[
            { value: 'fyrkjele', label: 'Fyrkjele' },
            { value: 'oljefyr', label: 'Oljefyr' },
            { value: 'varmepumpe', label: 'Varmepumpe' },
            { value: 'pelletsanlegg', label: 'Pelletsanlegg' },
            { value: 'gasskjele', label: 'Gasskjele' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckText
          id="arbeidUtfortEtterByggear"
          header={getCheckHeader(1, PartTypes.Varmesentral.id, 'arbeidUtfortEtterByggear')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <SingleTextarea
          id="sistService"
          header={getCheckHeader(1, PartTypes.Varmesentral.id, 'sistService')}
        />

        <CheckText
          id="oljetank"
          header={getCheckHeader(1, PartTypes.Varmesentral.id, 'oljetank')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        {oljetank === 'ja|Ja' && (
          <>
            <CheckText
              id="oljetankPlassering"
              header={getCheckHeader(1, PartTypes.Varmesentral.id, 'oljetankPlassering')}
              alternatives={[
                { value: 'nedgravd', label: 'Nedgravd' },
                { value: 'eget_tankrom', label: 'Eget tankrom' },
              ]}
            />

            <CheckText
              id="paleggSanering"
              header={getCheckHeader(1, PartTypes.Varmesentral.id, 'paleggSanering')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />

            <CheckText
              id="oljetankLekkasjesikret"
              header={getCheckHeader(1, PartTypes.Varmesentral.id, 'oljetankLekkasjesikret')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />
          </>
        )}

        {typeAnlegg &&
          (typeAnlegg.includes('fyrkjele|Fyrkjele') || typeAnlegg.includes('oljefyr|Oljefyr')) && (
            <>
              <CheckBoolean
                id="fyrkjeleTilpassetBioolje"
                header={getCheckHeader(1, PartTypes.Varmesentral.id, 'fyrkjeleTilpassetBioolje')}
              />

              <CheckBoolean
                id="lukt"
                header={getCheckHeader(1, PartTypes.Varmesentral.id, 'lukt')}
              />
            </>
          )}

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.Varmesentral.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.Varmesentral.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.Varmesentral.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Varmesentral.name}
      />
    </FormProvider>
  );
};

export const VarmesentralSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  typeAnlegg: Joi.object({
    values: Joi.array().min(1).required(),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidUtfortEtterByggear: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  sistService: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  oljetank: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), { is: 'ukjent|Ukjent', then: Joi.string().required() })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  oljetankPlassering: Joi.object({
    value: Joi.when(Joi.ref('/oljetank.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  paleggSanering: Joi.object({
    value: Joi.when(Joi.ref('/oljetank.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    comment: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  oljetankLekkasjesikret: Joi.object({
    value: Joi.when(Joi.ref('/oljetank.value'), {
      is: 'ja|Ja',
      then: Joi.string().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: 'nei|Nei', then: Joi.string().required() }).concat(
      Joi.when(Joi.ref('..value'), { is: 'ukjent|Ukjent', then: Joi.string().required() })
    ),
    touched: Joi.boolean().presence('optional'),
  }),
  fyrkjeleTilpassetBioolje: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.typeAnlegg.values &&
        (state.typeAnlegg.values.includes('fyrkjele|Fyrkjele') ||
          state.typeAnlegg.values.includes('oljefyr|Oljefyr'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  lukt: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state.typeAnlegg.values &&
        (state.typeAnlegg.values.includes('fyrkjele|Fyrkjele') ||
          state.typeAnlegg.values.includes('oljefyr|Oljefyr'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartVarmesentralFormData extends BasePartForm {
  typeAnlegg: MultiCheck<string>;
  arbeidUtfortEtterByggear: CheckSingle<string>;
  sistService: CheckSingle<string>;
  oljetank: CheckSingle<string>;
  oljetankPlassering: CheckSingle<string>;
  paleggSanering: CheckSingle<string>;
  oljetankLekkasjesikret: CheckSingle<string>;
  fyrkjeleTilpassetBioolje: CheckSingle<boolean>;
  lukt: CheckSingle<boolean>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default VarmesentralForm;
