import { default as Avlopsror, AvlopsrorSchema } from './Avlopsror/v1';
import {
  default as BalkongTerrassePlatting,
  BalkongTerrassePlattingSchema,
} from './BalkongTerrassePlatting/v1';
import { default as Drenering, DreneringSchema } from './Drenering/v1';
import { default as Elektrisk, ElektriskSchema } from './Elektrisk/v1';
import { default as Etasjeskille, EtasjeskilleSchema } from './Etasjeskille/v1';
import { default as GrunnFundament, GrunnFundamentSchema } from './GrunnFundament/v1';
import { default as IldstedSkorstein, IldstedSkorsteinSchema } from './IldstedSkorstein/v1';
import { default as Kjokken, KjokkenSchema } from './Kjokken/v1';
import { default as Krypkjeller, KrypkjellerSchema } from './Krypkjeller/v1';
import { default as Loft, LoftSchema } from './Loft/v1';
import { default as Lovlighet, LovlighetSchema } from './Lovlighet/v1';
import { default as Ovrig, OvrigSchema } from './Ovrig/v1';
import { default as RennerNedlop, RennerNedlopSchema } from './RennerNedlop/v1';
import { default as Stottemur, StottemurSchema } from './Stottemur/v1';
import { default as Takkonstruksjon, TakkonstruksjonSchema } from './Takkonstruksjon/v1';
import { default as Taktekking, TaktekkingSchema } from './Taktekking/v1';
import { default as Toalettrom, ToalettromSchema } from './Toalettrom/v1';
import { default as Trapp, TrappSchema } from './Trapp/v1';
import { default as UnderTerreng, UnderTerrengSchema } from './UnderTerreng/v1';
import { default as Vaatrom, VaatromSchema } from './Vaatrom/v1';
import { default as VannbarenVarme, VannbarenVarmeSchema } from './VannbarenVarme/v1';
import { default as Vannledninger, VannledningerSchema } from './Vannledninger/v1';
import { default as Varmesentral, VarmesentralSchema } from './Varmesentral/v1';
import { default as Varmtvannsbereder, VarmtvannsberederSchema } from './Varmtvannsbereder/v1';
import { default as Ventilasjon, VentilasjonSchema } from './Ventilasjon/v1';
import { default as VinduerDorer, VinduerDorerSchema } from './VinduerDorer/v1';
import { default as Yttervegger, YtterveggerSchema } from './Yttervegger/v1';
import { default as NotAvailable, NotAvailableSchema } from './NotAvailable/v1';
import { PartTypes } from '@supertakst/model-common';
import { withAvailabilitySchema } from '@utils/modelUtils';

const parts = {
  [PartTypes.Avlopsror.id]: {
    form: Avlopsror,
    schema: withAvailabilitySchema({ schema: AvlopsrorSchema }),
  },
  [PartTypes.BalkongTerrassePlatting.id]: {
    form: BalkongTerrassePlatting,
    schema: withAvailabilitySchema({ schema: BalkongTerrassePlattingSchema }),
  },
  [PartTypes.Drenering.id]: {
    form: Drenering,
    schema: withAvailabilitySchema({ schema: DreneringSchema }),
  },
  [PartTypes.Elektrisk.id]: {
    form: Elektrisk,
    schema: withAvailabilitySchema({ schema: ElektriskSchema }),
  },
  [PartTypes.Etasjeskille.id]: {
    form: Etasjeskille,
    schema: withAvailabilitySchema({ schema: EtasjeskilleSchema }),
  },
  [PartTypes.GrunnFundament.id]: {
    form: GrunnFundament,
    schema: withAvailabilitySchema({ schema: GrunnFundamentSchema }),
  },
  [PartTypes.IldstedSkorstein.id]: {
    form: IldstedSkorstein,
    schema: withAvailabilitySchema({ schema: IldstedSkorsteinSchema }),
  },
  [PartTypes.Kjokken.id]: {
    form: Kjokken,
    schema: withAvailabilitySchema({ schema: KjokkenSchema }),
  },
  [PartTypes.Krypkjeller.id]: {
    form: Krypkjeller,
    schema: withAvailabilitySchema({ schema: KrypkjellerSchema }),
  },
  [PartTypes.Loft.id]: {
    form: Loft,
    schema: withAvailabilitySchema({ schema: LoftSchema }),
  },
  [PartTypes.Lovlighet.id]: {
    form: Lovlighet,
    schema: LovlighetSchema,
  },
  [PartTypes.Ovrig.id]: {
    form: Ovrig,
    schema: OvrigSchema,
  },
  [PartTypes.RennerNedlop.id]: {
    form: RennerNedlop,
    schema: withAvailabilitySchema({ schema: RennerNedlopSchema }),
  },
  [PartTypes.Stottemur.id]: {
    form: Stottemur,
    schema: withAvailabilitySchema({ schema: StottemurSchema }),
  },
  [PartTypes.Takkonstruksjon.id]: {
    form: Takkonstruksjon,
    schema: withAvailabilitySchema({ schema: TakkonstruksjonSchema }),
  },
  [PartTypes.Taktekking.id]: {
    form: Taktekking,
    schema: withAvailabilitySchema({ schema: TaktekkingSchema }),
  },
  [PartTypes.Toalettrom.id]: {
    form: Toalettrom,
    schema: withAvailabilitySchema({ schema: ToalettromSchema }),
  },
  [PartTypes.Trapp.id]: {
    form: Trapp,
    schema: withAvailabilitySchema({ schema: TrappSchema }),
  },
  [PartTypes.UnderTerreng.id]: {
    form: UnderTerreng,
    schema: withAvailabilitySchema({ schema: UnderTerrengSchema }),
  },
  [PartTypes.Vaatrom.id]: {
    form: Vaatrom,
    schema: withAvailabilitySchema({ schema: VaatromSchema, concatAvailability: false }),
  },
  [PartTypes.VannbarenVarme.id]: {
    form: VannbarenVarme,
    schema: withAvailabilitySchema({ schema: VannbarenVarmeSchema }),
  },
  [PartTypes.Vannledninger.id]: {
    form: Vannledninger,
    schema: withAvailabilitySchema({ schema: VannledningerSchema }),
  },
  [PartTypes.Varmesentral.id]: {
    form: Varmesentral,
    schema: withAvailabilitySchema({ schema: VarmesentralSchema }),
  },
  [PartTypes.Varmtvannsbereder.id]: {
    form: Varmtvannsbereder,
    schema: withAvailabilitySchema({ schema: VarmtvannsberederSchema }),
  },
  [PartTypes.Ventilasjon.id]: {
    form: Ventilasjon,
    schema: withAvailabilitySchema({ schema: VentilasjonSchema }),
  },
  [PartTypes.VinduerDorer.id]: {
    form: VinduerDorer,
    schema: withAvailabilitySchema({ schema: VinduerDorerSchema }),
  },
  [PartTypes.Yttervegger.id]: {
    form: Yttervegger,
    schema: withAvailabilitySchema({ schema: YtterveggerSchema }),
  },
  NotAvailable: {
    form: NotAvailable,
    schema: NotAvailableSchema,
  },
};

export default parts;
