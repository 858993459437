import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import CheckMultiText from '@components/common/CheckMultiText/v2';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';
import {
  validateAnbefalteTiltak,
  validateTotalvurdering,
  validateUbedringskostnader,
} from '@components/common/validations/v2';
import { useTotalvurderingTgEffects } from '@components/hooks/formHooks';
import { useRouter } from 'next/router';
import KonklusjonTiltakKostnader from '@components/common/KonklusjonTiltakKostnader/v2';
import { showAnbefalteTiltak, showUtbedringskostnader } from '@utils/form';

const BalkongTerrassePlattingForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartBalkongTerrassePlattingFormData>) => {
  const [formMethods] = usePartForm<PartBalkongTerrassePlattingFormData>(
    BalkongTerrassePlattingSchema,
    defaultValues,
    PartTypes.BalkongTerrassePlatting.id,
    doOnSave
  );

  const {
    query: { version = '6' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  const kravTilRekkverk = watch('kravTilRekkverk.value');
  useEffect(() => {
    if (kravTilRekkverk !== true) {
      setValue('rekkverkForLavt.value', null, { shouldValidate: true });
    }
  }, [kravTilRekkverk, setValue]);

  const tekket = watch('tekket.value');
  useEffect(() => {
    if (!tekket) {
      setValue('manglendeTettesjikt.value', null, { shouldValidate: true });
    }
  }, [tekket, setValue]);

  const type = watch('_type.values');
  useEffect(() => {
    if (
      !type ||
      (!type.includes('takterrasse|Takterrasse') &&
        !type.includes('terrasse|Terrasse') &&
        !type.includes('balkong|Balkong'))
    ) {
      setValue('tekket.value', null, { shouldValidate: true });
      setValue('kravTilRekkverk.value', null, { shouldValidate: true });
    }
    if (tekket !== true || !type || !type.includes('takterrasse|Takterrasse')) {
      setValue('manglendeAvrenning.value', null, { shouldValidate: true });
      setValue('skaderTekking.value', null, { shouldValidate: true });
      setValue('alder.value', null, { shouldValidate: true });
    }
    if (!type || !type.includes('takterrasse|Takterrasse')) {
      setValue('ventilering.value', null, { shouldValidate: true });
    }
  }, [tekket, type, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  const alder = watch('alder.value');
  useEffect(() => {
    if (alder === 'ja|Ja' && (totalvurderingTg === undefined || totalvurderingTg === null)) {
      setValue('totalvurdering.tg', 2, { shouldValidate: true });
    }
  }, [alder, totalvurderingTg, setValue]);

  useTotalvurderingTgEffects({ totalvurderingTg, setValue });

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.BalkongTerrassePlatting.name} />

        <AvailabilityInput header={getCheckHeader(partVersion, 'common', 'availability')} />

        <CheckMultiText
          id="_type"
          header={getCheckHeader(partVersion, PartTypes.BalkongTerrassePlatting.id, '_type')}
          alternatives={[
            { value: 'balkong', label: 'Balkong' },
            { value: 'takterrasse', label: 'Takterrasse' },
            { value: 'terrasse', label: 'Terrasse' },
            { value: 'platting', label: 'Platting' },
            { value: 'annet', label: 'Annet' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.BalkongTerrassePlatting.id, '_type'),
            id: '_type.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(
            partVersion,
            PartTypes.BalkongTerrassePlatting.id,
            'arbeidEtterByggeaar'
          )}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.BalkongTerrassePlatting.id,
              'arbeidEtterByggeaar'
            ),
            id: 'arbeidEtterByggeaar.comment',
            tg: 'totalvurdering.tg',
          }}
          hideComment={false}
        />

        <CheckBoolean
          id="skjevheter"
          header={getCheckHeader(partVersion, PartTypes.BalkongTerrassePlatting.id, 'skjevheter')}
        />

        <CheckBoolean
          id="skader"
          header={getCheckHeader(partVersion, PartTypes.BalkongTerrassePlatting.id, 'skader')}
        />

        {type &&
          (type.includes('takterrasse|Takterrasse') ||
            type.includes('terrasse|Terrasse') ||
            type.includes('balkong|Balkong')) && (
            <>
              <CheckBoolean
                id="kravTilRekkverk"
                header={getCheckHeader(
                  partVersion,
                  PartTypes.BalkongTerrassePlatting.id,
                  'kravTilRekkverk'
                )}
              />

              {kravTilRekkverk && (
                <CheckBoolean
                  id="rekkverkForLavt"
                  header={getCheckHeader(
                    partVersion,
                    PartTypes.BalkongTerrassePlatting.id,
                    'rekkverkForLavt'
                  )}
                />
              )}

              <CheckBoolean
                id="tekket"
                header={getCheckHeader(partVersion, PartTypes.BalkongTerrassePlatting.id, 'tekket')}
              />
            </>
          )}

        {tekket && type && type.includes('takterrasse|Takterrasse') && (
          <>
            <CheckText
              id="manglendeAvrenning"
              header={getCheckHeader(
                partVersion,
                PartTypes.BalkongTerrassePlatting.id,
                'manglendeAvrenning'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="skaderTekking"
              header={getCheckHeader(
                partVersion,
                PartTypes.BalkongTerrassePlatting.id,
                'skaderTekking'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="alder"
              header={getCheckHeader(partVersion, PartTypes.BalkongTerrassePlatting.id, 'alder')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />
          </>
        )}

        {type && type.includes('takterrasse|Takterrasse') && (
          <>
            <CheckText
              id="ventilering"
              header={getCheckHeader(
                partVersion,
                PartTypes.BalkongTerrassePlatting.id,
                'ventilering'
              )}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        {tekket && (
          <CheckText
            id="manglendeTettesjikt"
            header={getCheckHeader(
              partVersion,
              PartTypes.BalkongTerrassePlatting.id,
              'manglendeTettesjikt'
            )}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <KonklusjonTiltakKostnader
          partType="BalkongTerrassePlatting"
          partVersion={partVersion}
          showUtbedringskostnader={showUtbedringskostnader(totalvurderingTg)}
          showAnbefalteTiltak={showAnbefalteTiltak(totalvurderingTg)}
        />
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.BalkongTerrassePlatting.name}
      />
    </FormProvider>
  );
};

export const BalkongTerrassePlattingSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  _type: Joi.object({
    values: Joi.array().min(1).required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    touched: Joi.boolean().presence('optional'),
    comment: Joi.string().allow('', null).presence('optional'),
  }),
  kravTilRekkverk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state._type.values &&
        (state._type.values.includes('takterrasse|Takterrasse') ||
          state._type.values.includes('terrasse|Terrasse') ||
          state._type.values.includes('balkong|Balkong'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  rekkverkForLavt: Joi.object({
    value: Joi.when(Joi.ref('/kravTilRekkverk.value'), {
      is: true,
      then: Joi.boolean().required(),
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  skjevheter: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  skader: Joi.object({
    value: Joi.boolean().required(),
    touched: Joi.boolean().presence('optional'),
  }),
  tekket: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state._type.values &&
        (state._type.values.includes('takterrasse|Takterrasse') ||
          state._type.values.includes('terrasse|Terrasse') ||
          state._type.values.includes('balkong|Balkong'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeAvrenning: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderTekking: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  ventilering: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (state._type.values && state._type.values.includes('takterrasse|Takterrasse') && !value) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeTettesjikt: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (state.tekket.value === true && !value) {
        return helpers.error('any.required');
      }
      return value;
    }),
  }),
  totalvurdering: validateTotalvurdering,
  utbedringskostnader: validateUbedringskostnader(),
  anbefalteTiltak: validateAnbefalteTiltak(),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartBalkongTerrassePlattingFormData extends BasePartForm {
  _type: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  kravTilRekkverk: CheckSingle<boolean>;
  rekkverkForLavt: CheckSingle<boolean>;
  skjevheter: CheckSingle<boolean>;
  skader: CheckSingle<boolean>;
  tekket: CheckSingle<boolean>;
  manglendeAvrenning: CheckSingle<string>;
  skaderTekking: CheckSingle<string>;
  ventilering: CheckSingle<string>;
  alder: CheckSingle<string>;
  manglendeTettesjikt: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default BalkongTerrassePlattingForm;
