import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v1';
import CheckText from '@components/common/CheckText/v1';
import CheckMultiText from '@components/common/CheckMultiText/v1';
import SingleTextarea from '@components/common/SingleTextarea/v1';
import { CheckSingle, MultiCheck, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import AvailabilityInput from '@components/common/AvailabilityInput';

const BalkongTerrassePlattingForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartBalkongTerrassePlattingFormData>) => {
  const [formMethods] = usePartForm<PartBalkongTerrassePlattingFormData>(
    BalkongTerrassePlattingSchema,
    defaultValues,
    PartTypes.BalkongTerrassePlatting.id,
    doOnSave
  );

  const { trigger, watch, setValue, getValues } = formMethods;

  const kravTilRekkverk = watch('kravTilRekkverk.value');
  useEffect(() => {
    if (kravTilRekkverk !== true) {
      setValue('rekkverkForLavt.value', null);
      setValue('rekkverkForLavt.comment', null);
    }
  }, [kravTilRekkverk, setValue]);

  const tekket = watch('tekket.value');
  useEffect(() => {
    if (!tekket) {
      setValue('manglendeTettesjikt.value', null);
      setValue('manglendeTettesjikt.comment', null);
    }
  }, [tekket, setValue]);

  const type = watch('_type.values');
  useEffect(() => {
    if (
      !type ||
      (!type.includes('takterrasse|Takterrasse') &&
        !type.includes('terrasse|Terrasse') &&
        !type.includes('balkong|Balkong'))
    ) {
      setValue('tekket.value', null);
      setValue('tekket.comment', null);
      setValue('kravTilRekkverk.value', null);
      setValue('kravTilRekkverk.comment', null);
    }
    if (tekket !== true || !type || !type.includes('takterrasse|Takterrasse')) {
      setValue('manglendeAvrenning.value', null);
      setValue('manglendeAvrenning.comment', null);
      setValue('skaderTekking.value', null);
      setValue('skaderTekking.comment', null);
      setValue('alder.value', null);
      setValue('alder.comment', null);
    }
    if (!type || !type.includes('takterrasse|Takterrasse')) {
      setValue('ventilering.value', null);
      setValue('ventilering.comment', null);
    }
  }, [tekket, type, setValue]);

  const totalvurderingTg = watch('totalvurdering.tg');
  const alder = watch('alder.value');
  useEffect(() => {
    if (alder === 'ja|Ja' && (totalvurderingTg === undefined || totalvurderingTg === null)) {
      setValue('totalvurdering.tg', 2);
    }
  }, [alder, totalvurderingTg, setValue]);

  useEffect(() => {
    if (totalvurderingTg !== 3) {
      setValue('utbedringskostnader.value', null);
      setValue('utbedringskostnader.comment', null);
    }
    if (totalvurderingTg === null || totalvurderingTg === 0 || totalvurderingTg === 1) {
      setValue('anbefalteTiltak.value', null);
      setValue('anbefalteTiltak.comment', null);
    }
  }, [totalvurderingTg, setValue]);

  const anbefalteTiltak = watch('anbefalteTiltak.value');
  useEffect(() => {
    if (anbefalteTiltak === false) {
      setValue('anbefalteTiltak.comment', null);
    }
  }, [setValue, anbefalteTiltak]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.BalkongTerrassePlatting.name} />

        <AvailabilityInput header={getCheckHeader(1, 'common', 'availability')} />

        <CheckMultiText
          id="_type"
          header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, '_type')}
          alternatives={[
            { value: 'balkong', label: 'Balkong' },
            { value: 'takterrasse', label: 'Takterrasse' },
            { value: 'terrasse', label: 'Terrasse' },
            { value: 'platting', label: 'Platting' },
            { value: 'annet', label: 'Annet' },
          ]}
        />

        <CheckText
          id="arbeidEtterByggeaar"
          header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'arbeidEtterByggeaar')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ukjent', label: 'Ukjent' },
          ]}
        />

        <CheckBoolean
          id="skjevheter"
          header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'skjevheter')}
        />

        <CheckBoolean
          id="skader"
          header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'skader')}
        />

        {type &&
          (type.includes('takterrasse|Takterrasse') ||
            type.includes('terrasse|Terrasse') ||
            type.includes('balkong|Balkong')) && (
            <>
              <CheckBoolean
                id="kravTilRekkverk"
                header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'kravTilRekkverk')}
              />

              {kravTilRekkverk && (
                <CheckBoolean
                  id="rekkverkForLavt"
                  header={getCheckHeader(
                    1,
                    PartTypes.BalkongTerrassePlatting.id,
                    'rekkverkForLavt'
                  )}
                />
              )}

              <CheckBoolean
                id="tekket"
                header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'tekket')}
              />
            </>
          )}

        {tekket && type && type.includes('takterrasse|Takterrasse') && (
          <>
            <CheckText
              id="manglendeAvrenning"
              header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'manglendeAvrenning')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="skaderTekking"
              header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'skaderTekking')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />

            <CheckText
              id="alder"
              header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'alder')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ukjent', label: 'Ukjent' },
              ]}
            />
          </>
        )}

        {type && type.includes('takterrasse|Takterrasse') && (
          <>
            <CheckText
              id="ventilering"
              header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'ventilering')}
              alternatives={[
                { value: 'ja', label: 'Ja' },
                { value: 'nei', label: 'Nei' },
                { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
              ]}
            />
          </>
        )}

        {tekket && (
          <CheckText
            id="manglendeTettesjikt"
            header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'manglendeTettesjikt')}
            alternatives={[
              { value: 'ja', label: 'Ja' },
              { value: 'nei', label: 'Nei' },
              { value: 'ikke_kontrollerbart', label: 'Ikke kontrollerbart' },
            ]}
          />
        )}

        <SingleTextarea
          id="totalvurdering"
          header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'totalvurdering')}
          hasTg
        />

        {totalvurderingTg === 3 && (
          <CheckText
            id="utbedringskostnader"
            header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'utbedringskostnader')}
            alternatives={[
              { value: '0', label: 'Ingen umiddelbar kostnad' },
              { value: '10000', label: 'Under 10 000' },
              { value: '10000-50000', label: '10 000 - 50 000' },
              { value: '50000-150000', label: '50 000 - 150 000' },
              { value: '150000-300000', label: '150 000 - 300 000' },
              { value: '300000', label: 'Over 300 000' },
            ]}
          />
        )}

        {totalvurderingTg !== undefined &&
          totalvurderingTg !== null &&
          totalvurderingTg !== 0 &&
          totalvurderingTg !== 1 && (
            <CheckBoolean
              id="anbefalteTiltak"
              header={getCheckHeader(1, PartTypes.BalkongTerrassePlatting.id, 'anbefalteTiltak')}
              hideComment={getValues('anbefalteTiltak.value') === false}
            />
          )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.BalkongTerrassePlatting.name}
      />
    </FormProvider>
  );
};

export const BalkongTerrassePlattingSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  _type: Joi.object({
    values: Joi.array().min(1).required(),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  arbeidEtterByggeaar: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  kravTilRekkverk: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state._type.values &&
        (state._type.values.includes('takterrasse|Takterrasse') ||
          state._type.values.includes('terrasse|Terrasse') ||
          state._type.values.includes('balkong|Balkong'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  rekkverkForLavt: Joi.object({
    value: Joi.when(Joi.ref('/kravTilRekkverk.value'), {
      is: true,
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  skjevheter: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  skader: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  tekket: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== true &&
        value !== false &&
        state._type.values &&
        (state._type.values.includes('takterrasse|Takterrasse') ||
          state._type.values.includes('terrasse|Terrasse') ||
          state._type.values.includes('balkong|Balkong'))
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeAvrenning: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        (state.manglendeAvrenning.value === 'ja|Ja' ||
          state.manglendeAvrenning.value === 'ikke_kontrollerbart|Ikke kontrollerbart') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  skaderTekking: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        (state.skaderTekking.value === 'ja|Ja' ||
          state.skaderTekking.value === 'ikke_kontrollerbart|Ikke kontrollerbart') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  alder: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),
  ventilering: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (state._type.values && state._type.values.includes('takterrasse|Takterrasse') && !value) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state._type.values &&
        state._type.values.includes('takterrasse|Takterrasse') &&
        (state.ventilering.value === 'ja|Ja' ||
          state.ventilering.value === 'ikke_kontrollerbart|Ikke kontrollerbart') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeTettesjikt: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (state.tekket.value === true && !value) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        state.tekket.value === true &&
        (state.manglendeTettesjikt.value === 'ja|Ja' ||
          state.manglendeTettesjikt.value === 'ikke_kontrollerbart|Ikke kontrollerbart') &&
        !value
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    touched: Joi.boolean().presence('optional'),
  }),
  totalvurdering: Joi.object({
    value: Joi.when('..tg', {
      is: 3,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    })
      .concat(
        Joi.when('..tg', {
          is: 2,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      )
      .concat(
        Joi.when('..tg', {
          is: -1,
          then: Joi.string().required(),
          otherwise: Joi.any(),
        })
      ),
    tg: Joi.number().integer().required(),
    touched: Joi.boolean().presence('optional'),
  }),

  utbedringskostnader: Joi.object({
    value: Joi.when(Joi.ref('/totalvurdering.tg'), { is: 3, then: Joi.string().required() }),
    comment: Joi.string().allow(null, ''),
    touched: Joi.boolean().presence('optional'),
  }),

  anbefalteTiltak: Joi.object({
    value: Joi.custom((value, helpers) => {
      const [state] = helpers.state.ancestors.slice(-1);
      if (
        value !== false &&
        value !== true &&
        (state.totalvurdering.tg === -1 ||
          state.totalvurdering.tg === 2 ||
          state.totalvurdering.tg === 3)
      ) {
        return helpers.error('any.required');
      }
      return value;
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartBalkongTerrassePlattingFormData extends BasePartForm {
  _type: MultiCheck<string>;
  arbeidEtterByggeaar: CheckSingle<string>;
  kravTilRekkverk: CheckSingle<boolean>;
  rekkverkForLavt: CheckSingle<boolean>;
  skjevheter: CheckSingle<boolean>;
  skader: CheckSingle<boolean>;
  tekket: CheckSingle<boolean>;
  manglendeAvrenning: CheckSingle<string>;
  skaderTekking: CheckSingle<string>;
  ventilering: CheckSingle<string>;
  alder: CheckSingle<string>;
  manglendeTettesjikt: CheckSingle<string>;
  totalvurdering: CheckSingle<string>;
  utbedringskostnader: CheckSingle<string>;
  anbefalteTiltak: CheckSingle<boolean>;
}

export default BalkongTerrassePlattingForm;
