import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import CheckBoolean from '@components/common/CheckBoolean/v2';
import CheckText from '@components/common/CheckText/v2';
import { CheckSingle, PartTypes } from '@supertakst/model-common';
import { BasePartComponentProps, BasePartForm } from '@components/Parts/BasePartForm';
import Joi from 'joi';
import usePartForm from '@components/hooks/usePartForm';
import { getCheckHeader } from '@utils/modelUtils';
import { PartImages } from '@components/common/Images';
import NameInput from '@components/common/NameInput';
import { useRouter } from 'next/router';

const LovlighetForm = ({
  defaultValues,
  doOnSave,
}: BasePartComponentProps<PartLovlighetFormData>) => {
  const [formMethods] = usePartForm<PartLovlighetFormData>(
    LovlighetSchema,
    defaultValues,
    PartTypes.Lovlighet.id,
    doOnSave
  );

  const {
    query: { version = '5' },
  } = useRouter();
  const partVersion = parseInt(version as string, 10);

  const { trigger, watch, setValue, getValues } = formMethods;

  useEffect(() => {
    (async () => {
      await trigger();
    })();
  }, [trigger]);

  const avvikFraByggetegninger = watch('avvikFraByggetegninger.value');
  useEffect(() => {
    if (avvikFraByggetegninger !== 'ikke_kontrollert|Ikke kontrollert') {
      setValue('ulovligheter.value', null, { shouldValidate: true });
      setValue('ulovligheter.comment', null, { shouldValidate: true });
    }
  }, [avvikFraByggetegninger, setValue]);

  const manglendeBrannslukkingUtstyr = watch('manglendeBrannslukkingUtstyr.value');
  useEffect(() => {
    if (manglendeBrannslukkingUtstyr === 'ja|Ja') {
      setValue('utstyrSkader.value', null, { shouldValidate: true });
      setValue('utstyrSkader.comment', null, { shouldValidate: true });
      setValue('brannslukkingUtstyrSkader.value', null, { shouldValidate: true });
      setValue('brannslukkingUtstyrSkader.comment', null, { shouldValidate: true });
      setValue('roykvarslerSkader.value', null, { shouldValidate: true });
      setValue('roykvarslerSkader.comment', null, { shouldValidate: true });
    }
    if (manglendeBrannslukkingUtstyr === 'nei|Nei') {
      setValue('brannslukkingUtstyrSkader.value', null, { shouldValidate: true });
      setValue('brannslukkingUtstyrSkader.comment', null, { shouldValidate: true });
      setValue('roykvarslerSkader.value', null, { shouldValidate: true });
      setValue('roykvarslerSkader.comment', null, { shouldValidate: true });
    }
    if (
      manglendeBrannslukkingUtstyr === 'manglende_brannslukkerutstyr|Manglende brannslukkerutstyr'
    ) {
      setValue('utstyrSkader.value', null, { shouldValidate: true });
      setValue('utstyrSkader.comment', null, { shouldValidate: true });
      setValue('brannslukkingUtstyrSkader.value', null, { shouldValidate: true });
      setValue('brannslukkingUtstyrSkader.comment', null, { shouldValidate: true });
    }
    if (manglendeBrannslukkingUtstyr === 'manglende_roykvarsler|Manglende røykvarsler') {
      setValue('utstyrSkader.value', null, { shouldValidate: true });
      setValue('utstyrSkader.comment', null, { shouldValidate: true });
      setValue('roykvarslerSkader.value', null, { shouldValidate: true });
      setValue('roykvarslerSkader.comment', null, { shouldValidate: true });
    }
  }, [manglendeBrannslukkingUtstyr, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form
        className="space-y-2.5"
        onChange={async () => {
          setTimeout(async () => {
            await trigger();
          }, 0);
        }}
      >
        <NameInput partName={PartTypes.Lovlighet.name} />

        <CheckText
          id="avvikFraByggetegninger"
          header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'avvikFraByggetegninger')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
          hideComment={false}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'avvikFraByggetegninger'),
            id: 'avvikFraByggetegninger.comment',
            tg: undefined,
          }}
        />

        {avvikFraByggetegninger === 'ikke_kontrollert|Ikke kontrollert' && (
          <>
            <CheckBoolean
              id="ulovligheter"
              header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'ulovligheter')}
              hideComment={false}
              phrasesTarget={{
                header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'ulovligheter'),
                id: 'ulovligheter.comment',
                tg: undefined,
              }}
            />
          </>
        )}

        <CheckBoolean
          id="branncelleAvvik"
          header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'branncelleAvvik')}
          hideComment={false}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'branncelleAvvik'),
            id: 'branncelleAvvik.comment',
            tg: undefined,
          }}
        />

        <CheckText
          id="manglendeFerdigattest"
          header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'manglendeFerdigattest')}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'ikke_kontrollert', label: 'Ikke kontrollert' },
          ]}
          hideComment={false}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'manglendeFerdigattest'),
            id: 'manglendeFerdigattest.comment',
            tg: undefined,
          }}
        />

        <CheckBoolean
          id="romningsveierAvvik"
          header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'romningsveierAvvik')}
          hideComment={false}
          phrasesTarget={{
            header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'romningsveierAvvik'),
            id: 'romningsveierAvvik.comment',
            tg: undefined,
          }}
        />

        <CheckText
          id="manglendeBrannslukkingUtstyr"
          header={getCheckHeader(
            partVersion,
            PartTypes.Lovlighet.id,
            'manglendeBrannslukkingUtstyr'
          )}
          hideComment={false}
          alternatives={[
            { value: 'ja', label: 'Ja' },
            { value: 'nei', label: 'Nei' },
            { value: 'manglende_brannslukkerutstyr', label: 'Manglende brannslukkerutstyr' },
            { value: 'manglende_roykvarsler', label: 'Manglende røykvarsler' },
          ]}
          phrasesTarget={{
            header: getCheckHeader(
              partVersion,
              PartTypes.Lovlighet.id,
              'manglendeBrannslukkingUtstyr'
            ),
            id: 'manglendeBrannslukkingUtstyr.comment',
            tg: undefined,
          }}
        />

        {manglendeBrannslukkingUtstyr === 'nei|Nei' && (
          <CheckBoolean
            id="utstyrSkader"
            hideComment={false}
            header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'utstyrSkader')}
            phrasesTarget={{
              header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'utstyrSkader'),
              id: 'utstyrSkader.comment',
              tg: undefined,
            }}
          />
        )}
        {manglendeBrannslukkingUtstyr ===
          'manglende_brannslukkerutstyr|Manglende brannslukkerutstyr' && (
          <CheckBoolean
            id="roykvarslerSkader"
            hideComment={false}
            header={getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'roykvarslerSkader')}
            phrasesTarget={{
              header: getCheckHeader(partVersion, PartTypes.Lovlighet.id, 'roykvarslerSkader'),
              id: 'roykvarslerSkader.comment',
              tg: undefined,
            }}
          />
        )}
        {manglendeBrannslukkingUtstyr === 'manglende_roykvarsler|Manglende røykvarsler' && (
          <CheckBoolean
            id="brannslukkingUtstyrSkader"
            header={getCheckHeader(
              partVersion,
              PartTypes.Lovlighet.id,
              'brannslukkingUtstyrSkader'
            )}
            hideComment={false}
            phrasesTarget={{
              header: getCheckHeader(
                partVersion,
                PartTypes.Lovlighet.id,
                'brannslukkingUtstyrSkader'
              ),
              id: 'brannslukkingUtstyrSkader.comment',
              tg: undefined,
            }}
          />
        )}
      </form>
      <PartImages
        existingImages={getValues('images')}
        setImageValues={setValue}
        heading={PartTypes.Lovlighet.name}
      />
    </FormProvider>
  );
};

export const LovlighetSchema = Joi.object({
  name: Joi.object({
    value: Joi.string().allow('', null),
    touched: Joi.boolean().presence('optional'),
  }),
  avvikFraByggetegninger: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeFerdigattest: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: 'ja|Ja', then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  ulovligheter: Joi.object({
    value: Joi.when(Joi.ref('/avvikFraByggetegninger.value'), {
      is: 'ikke_kontrollert|Ikke kontrollert',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  branncelleAvvik: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  romningsveierAvvik: Joi.object({
    value: Joi.boolean().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  manglendeBrannslukkingUtstyr: Joi.object({
    value: Joi.string().required(),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  utstyrSkader: Joi.object({
    value: Joi.when(Joi.ref('/manglendeBrannslukkingUtstyr.value'), {
      is: 'nei|Nei',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  brannslukkingUtstyrSkader: Joi.object({
    value: Joi.when(Joi.ref('/manglendeBrannslukkingUtstyr.value'), {
      is: 'manglende_roykvarsler|Manglende røykvarsler',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
  roykvarslerSkader: Joi.object({
    value: Joi.when(Joi.ref('/manglendeBrannslukkingUtstyr.value'), {
      is: 'manglende_brannslukkerutstyr|Manglende brannslukkerutstyr',
      then: Joi.boolean().required(),
    }),
    comment: Joi.when(Joi.ref('..value'), { is: true, then: Joi.string().required() }),
    touched: Joi.boolean().presence('optional'),
  }),
})
  .unknown()
  .messages({ '*': 'Dette feltet er påkrevet' });

interface PartLovlighetFormData extends BasePartForm {
  avvikFraByggetegninger: CheckSingle<string>;
  manglendeFerdigattest: CheckSingle<string>;
  ulovligheter: CheckSingle<boolean>;
  branncelleAvvik: CheckSingle<boolean>;
  romningsveierAvvik: CheckSingle<boolean>;
  manglendeBrannslukkingUtstyr: CheckSingle<string>;
  utstyrSkader: CheckSingle<boolean>;
  brannslukkingUtstyrSkader: CheckSingle<boolean>;
  roykvarslerSkader: CheckSingle<boolean>;
}

export default LovlighetForm;
